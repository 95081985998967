define("da-vinci/components/invoice-excel-export-modal/success/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal
    @isOpen={{true}}
    @dataTestModalName="success"
    @wrapperClassNames={{styleNamespace}}
    as |modal|
  >
    <modal.header>
      <h2>{{t "invoiceExcelExportModal.success.title"}}</h2>
    </modal.header>
    <modal.body @class="modal-body-wrapper">
      <div class="badge-container">
        <PBadgeIcon
          @icon="fa-file-excel-o"
          @shape="circle"
          @overlayIcon="fa fa-check"
        />
      </div>
      <p data-test-body-info>{{t "invoiceExcelExportModal.success.info"}}</p>
    </modal.body>
    <modal.footer>
      <PButton
        @type="outline"
        @size="small"
        @onClick={{onClose}}
        @data-test-close-button={{true}}
      >
        {{t "invoiceExcelExportModal.success.close"}}
      </PButton>
      <PButton
        @href={{downloadLink}}
        @tagName="a"
        @target="_blank"
        @rel="noopener noreferrer"
        @size="small"
        @data-test-download-link={{downloadLink}}
        @data-test-download-button={{true}}
      >
        {{t "invoiceExcelExportModal.success.download"}}
      </PButton>
    </modal.footer>
  </PModal>
  
  */
  {
    "id": "TtFcndEQ",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@dataTestModalName\",\"@wrapperClassNames\"],[true,\"success\",[22,\"styleNamespace\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"invoiceExcelExportModal.success.title\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[\"@class\"],[\"modal-body-wrapper\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"badge-container\"],[8],[0,\"\\n      \"],[5,\"p-badge-icon\",[],[[\"@icon\",\"@shape\",\"@overlayIcon\"],[\"fa-file-excel-o\",\"circle\",\"fa fa-check\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"invoiceExcelExportModal.success.info\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-button\",[],[[\"@type\",\"@size\",\"@onClick\"],[\"outline\",\"small\",[22,\"onClose\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"invoiceExcelExportModal.success.close\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"p-button\",[],[[\"@href\",\"@tagName\",\"@target\",\"@rel\",\"@size\"],[[22,\"downloadLink\"],\"a\",\"_blank\",\"noopener noreferrer\",\"small\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"invoiceExcelExportModal.success.download\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invoice-excel-export-modal/success/template.hbs"
    }
  });

  _exports.default = _default;
});