define("da-vinci/components/error-list/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-decorators/object", "ember-prop-types"], function (_exports, _applyDecoratedDescriptor2, _object, _emberPropTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj, _init;

  /**
   * TODO:
   * refactor set(errors) to be an action
   * refactor out get default props
   * then convert to native class
   */
  var _default = Ember.Component.extend((_obj = {
    'data-test-error-list': true,
    propTypes: {
      isInline: _emberPropTypes.PropTypes.bool,
      // TODO: Syntax error - this errors property should have isRequired not required
      errors: _emberPropTypes.PropTypes.any
    },
    getDefaultProps: function getDefaultProps() {
      return {
        isInline: false
      };
    },
    classNameBindings: ['noError', 'isInline'],
    errorMessages: [],
    noError: Ember.computed.empty('errorMessages'),
    errors: {
      set: function set(errors) {
        this.set('errorMessages', errors.map(function (error) {
          return Ember.String.htmlSafe(error);
        }));
      }
    },
    resetErrors: function resetErrors() {
      this.set('errorMessages', []);
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "errors", [_object.computed], (_init = Object.getOwnPropertyDescriptor(_obj, "errors"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "resetErrors", [_object.action], Object.getOwnPropertyDescriptor(_obj, "resetErrors"), _obj)), _obj));

  _exports.default = _default;
});