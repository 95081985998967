define("da-vinci/components/file-upload-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div>
    {{yield}}
  </div>
  
  */
  {
    "id": "POHMAlIQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/file-upload-button/template.hbs"
    }
  });

  _exports.default = _default;
});