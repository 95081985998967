define("da-vinci/locales/tr/p-one-way-exchange-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    buying: 'ALIŞ',
    selling: 'SATIŞ',
    other: 'DİĞER'
  };
  _exports.default = _default;
});