define("da-vinci/utils/request-param-builders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseDateRangeParam = parseDateRangeParam;

  function parseDateRangeParam(dateRange) {
    if (dateRange) {
      var dateArray = dateRange.split(',');
      return {
        gteq: dateArray[0],
        lteq: dateArray[1]
      };
    }
  }
});