define("da-vinci/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{!--
  Add content you wish automatically added to the documents head
  here. The 'model' available in this template can be populated by
  setting values on the 'head-data' service.
  --}}
  <title>{{model.title}}</title>
  
  <meta name={{model.meta.name}} content={{model.meta.content}}>
  
  */
  {
    "id": "i4KUmE3v",
    "block": "{\"symbols\":[],\"statements\":[[7,\"title\",true],[8],[1,[24,[\"model\",\"title\"]],false],[9],[0,\"\\n\\n\"],[7,\"meta\",true],[11,\"name\",[24,[\"model\",\"meta\",\"name\"]]],[11,\"content\",[24,[\"model\",\"meta\",\"content\"]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/templates/head.hbs"
    }
  });

  _exports.default = _default;
});