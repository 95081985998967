define("da-vinci/application/adapter", ["exports", "@ember-data/adapter/json-api", "da-vinci/config/environment", "ember-inflector", "ember-simple-auth-oidc/mixins/oidc-adapter-mixin"], function (_exports, _jsonApi, _environment, _emberInflector, _oidcAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_oidcAdapterMixin.default, {
    session: Ember.inject.service(),
    host: _environment.default.apiHost,
    pathForType: function pathForType(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));
    }
  });

  _exports.default = _default;
});