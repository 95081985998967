define("da-vinci/templates/components/pl-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{yield queue dropzone}}
  
  */
  {
    "id": "cl6GcqEo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[24,[\"queue\"]],[24,[\"dropzone\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/templates/components/pl-uploader.hbs"
    }
  });

  _exports.default = _default;
});