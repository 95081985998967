define("da-vinci/helpers/luca/luca-fetch-info-intervals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LUCA_FETCH_INTERVALS = void 0;
  var LUCA_FETCH_INTERVALS = {
    existinglucaCompanyDetails: {
      name: 'existing_luca_company_details',
      intervalDaily: 15
    },
    newLucaCompanyDetails: {
      name: 'new_luca_company_details',
      intervalDaily: 1
    },
    lucaAccountants: {
      name: 'luca_accountants',
      intervalDaily: 3
    },
    lucaCompanyDeclarations: {
      name: 'luca_company_declarations',
      intervalDaily: 1
    }
  };
  _exports.LUCA_FETCH_INTERVALS = LUCA_FETCH_INTERVALS;
});