define("da-vinci/components/product-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <POneWayAutocomplete
    @items={{products}}
    @placeholder={{placeholder}}
    @searchTask={{searchProduct}}
    @inputValue={{inputValue}}
    @onItemSelected={{action "productSelected"}}
    @onInputValueChanged={{action "updateInputValue"}}
    @onFocus={{onFocus}}
    @onBlur={{action "onBlur"}}
    as |product|
  >
    {{#if product.code}}
      {{product.name}}({{product.code}})
    {{else}}
      {{product.name}}
    {{/if}}
  </POneWayAutocomplete>
  
  */
  {
    "id": "tGLZpxrQ",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[5,\"p-one-way-autocomplete\",[],[[\"@items\",\"@placeholder\",\"@searchTask\",\"@inputValue\",\"@onItemSelected\",\"@onInputValueChanged\",\"@onFocus\",\"@onBlur\"],[[22,\"products\"],[22,\"placeholder\"],[22,\"searchProduct\"],[22,\"inputValue\"],[28,\"action\",[[23,0,[]],\"productSelected\"],null],[28,\"action\",[[23,0,[]],\"updateInputValue\"],null],[22,\"onFocus\"],[28,\"action\",[[23,0,[]],\"onBlur\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"code\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,1,[\"name\"]],false],[0,\"(\"],[1,[23,1,[\"code\"]],false],[0,\")\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/product-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});