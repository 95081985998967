define("da-vinci/components/company-matching/product-account-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PTable @items={{productAccounts}} as |table|>
    <table.header as |header|>
      <header.column @data-test-product-code-column={{true}}>
        {{t "companyMatchingProductAccountList.column.productCode"}}
      </header.column>
      <header.column @data-test-account-code-column={{true}}>
        {{t "companyMatchingProductAccountList.column.accountCode"}}
      </header.column>
      {{header.column}}
    </table.header>
    <table.body as |productAccount|>
      <CompanyMatching::ProductAccountList::ListRow
        @productAccount={{productAccount}}
        @onProductChanged={{onProductChanged}}
        @onAccountChanged={{onAccountChanged}}
        @removeProductAccount={{removeProductAccount}}
        @data-test-product-account-list-row={{true}}
      />
    </table.body>
    <table.footer>
      <PButton
        @type="outline"
        @size="small"
        @onClick={{addProductAccount}}
        @data-test-product-account-add-button={{true}}
      >
        <i class="fa fa-plus"></i>
        {{t "companyMatchingProductAccountList.button.addNew"}}
      </PButton>
    </table.footer>
  </PTable>
  
  */
  {
    "id": "dvgK9WtB",
    "block": "{\"symbols\":[\"table\",\"productAccount\",\"header\"],\"statements\":[[5,\"p-table\",[],[[\"@items\"],[[22,\"productAccounts\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"companyMatchingProductAccountList.column.productCode\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"companyMatchingProductAccountList.column.accountCode\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[1,[23,3,[\"column\"]],false],[0,\"\\n  \"]],\"parameters\":[3]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"company-matching/product-account-list/list-row\",[],[[\"@productAccount\",\"@onProductChanged\",\"@onAccountChanged\",\"@removeProductAccount\"],[[23,2,[]],[22,\"onProductChanged\"],[22,\"onAccountChanged\"],[22,\"removeProductAccount\"]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-button\",[],[[\"@type\",\"@size\",\"@onClick\"],[\"outline\",\"small\",[22,\"addProductAccount\"]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\"\\n      \"],[1,[28,\"t\",[\"companyMatchingProductAccountList.button.addNew\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/company-matching/product-account-list/template.hbs"
    }
  });

  _exports.default = _default;
});