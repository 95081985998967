define("da-vinci/mixins/meta/meta-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    meta: {
      waiting: 0,
      ready: 0,
      done: 0
    },
    updateMetaTask: null // this is set on meta-route mixin

  });

  _exports.default = _default;
});