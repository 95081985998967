define("da-vinci/application/serializer", ["exports", "@ember-data/serializer/json-api", "ember-inflector", "da-vinci/utils/jsonapi/relationship-serializer", "da-vinci/utils/camelize-keys"], function (_exports, _jsonApi, _emberInflector, _relationshipSerializer, _camelizeKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    keyForRelationship: function keyForRelationship(key) {
      return Ember.String.underscore(key);
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));
    },
    serializeHasMany: function serializeHasMany() {
      this._super.apply(this, arguments);

      _relationshipSerializer.default.apply(void 0, [this].concat(Array.prototype.slice.call(arguments)));
    },
    serializeBelongsTo: function serializeBelongsTo() {
      this._super.apply(this, arguments);

      _relationshipSerializer.default.apply(void 0, [this].concat(Array.prototype.slice.call(arguments)));
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'meta')) {
        payload.meta = (0, _camelizeKeys.default)(payload.meta);
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});