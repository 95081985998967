define("da-vinci/components/download-e-documents-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showError}}
    <DownloadEDocumentsModal::Error
      @onCancel={{onCancel}}
      @errorDetailKey={{errorDetailKey}}
    />
  {{else if showTimeout}}
    <DownloadEDocumentsModal::Timeout @onCancel={{onCancel}} />
  {{else if (or showSuccessMergePdf showSuccessZipPdf)}}
    <DownloadEDocumentsModal::Success
      @onCancel={{onCancel}}
      @downloadLink={{downloadLink}}
      @isPdfMerge={{isPdfMerge}}
      @missingDocumentNo={{missingDocumentNo}}
      @fileName={{fileName}}
      @pdfMergeCount={{pdfMergeCount}}
    />
  {{else}}
    <DownloadEDocumentsModal::InProgress
      @onCancel={{onCancel}}
      @pdfCount={{pdfCount}}
      @pdfMergeCount={{pdfMergeCount}}
      @isPdfMerge={{isPdfMerge}}
      @progressFraction={{progressFraction}}
      @missingDocumentNo={{missingDocumentNo}}
      @fileName={{fileName}}
     />
  {{/if}}
  
  */
  {
    "id": "B3Uaqv4E",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showError\"]]],null,{\"statements\":[[0,\"  \"],[5,\"download-e-documents-modal/error\",[],[[\"@onCancel\",\"@errorDetailKey\"],[[22,\"onCancel\"],[22,\"errorDetailKey\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showTimeout\"]]],null,{\"statements\":[[0,\"  \"],[5,\"download-e-documents-modal/timeout\",[],[[\"@onCancel\"],[[22,\"onCancel\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"showSuccessMergePdf\"]],[24,[\"showSuccessZipPdf\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"download-e-documents-modal/success\",[],[[\"@onCancel\",\"@downloadLink\",\"@isPdfMerge\",\"@missingDocumentNo\",\"@fileName\",\"@pdfMergeCount\"],[[22,\"onCancel\"],[22,\"downloadLink\"],[22,\"isPdfMerge\"],[22,\"missingDocumentNo\"],[22,\"fileName\"],[22,\"pdfMergeCount\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"download-e-documents-modal/in-progress\",[],[[\"@onCancel\",\"@pdfCount\",\"@pdfMergeCount\",\"@isPdfMerge\",\"@progressFraction\",\"@missingDocumentNo\",\"@fileName\"],[[22,\"onCancel\"],[22,\"pdfCount\"],[22,\"pdfMergeCount\"],[22,\"isPdfMerge\"],[22,\"progressFraction\"],[22,\"missingDocumentNo\"],[22,\"fileName\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/download-e-documents-modal/template.hbs"
    }
  });

  _exports.default = _default;
});