define("da-vinci/authenticators/oidc", ["exports", "ember-simple-auth-oidc/authenticators/oidc"], function (_exports, _oidc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _oidc.default;
    }
  });
});