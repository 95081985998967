define("da-vinci/components/user-invitations/user-invitations-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if userInvitations.length}}
    <PTable
      @items={{userInvitations}}
      @data-test-invitations-table={{true}}
      as |table|
    >
      <table.header as |header|>
        <header.column
          @data-test-user-invitation-table-header-email-column={{true}}
        >
          {{t "userInvitations.table.header.email"}}
        </header.column>
      </table.header>
      <div class="table-container">
        <table.body as |userInvitation|>
          <UserInvitations::UserInvitationsTableRow
            @userInvitation={{userInvitation}}
          />
        </table.body>
      </div>
    </PTable>
  {{else}}
    <TableEmptyState>
      {{t "userInvitations.table.emptyText"}}
    </TableEmptyState>
  {{/if}}
  
  */
  {
    "id": "Sev4jhqn",
    "block": "{\"symbols\":[\"table\",\"userInvitation\",\"header\"],\"statements\":[[4,\"if\",[[24,[\"userInvitations\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[5,\"p-table\",[],[[\"@items\"],[[22,\"userInvitations\"]]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"userInvitations.table.header.email\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-container\"],[8],[0,\"\\n      \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"user-invitations/user-invitations-table-row\",[],[[\"@userInvitation\"],[[23,2,[]]]]],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"table-empty-state\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"userInvitations.table.emptyText\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/user-invitations/user-invitations-table/template.hbs"
    }
  });

  _exports.default = _default;
});