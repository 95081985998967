define("da-vinci/components/invoice-tab-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="nav-tab">
    <ul>
      <li class={{waitingTabClass}} data-test-waiting-tab>
        <LinkTo
          @route={{concat "tax-period.show." invoiceType ".waiting"}}
          data-test-waiting-button={{true}}
        >
          {{t "invoiceTabSelector.tabs.waiting" count=waitingCount htmlSafe=true}}
        </LinkTo>
      </li>
      <li class={{readyTabClass}} data-test-ready-tab>
        <LinkTo
          @route={{concat "tax-period.show." invoiceType ".ready"}}
          data-test-ready-button={{true}}
        >
          {{t "invoiceTabSelector.tabs.ready" count=readyCount htmlSafe=true}}
        </LinkTo>
      </li>
      <li class={{doneTabClass}} data-test-done-tab>
        <LinkTo
          @route={{concat "tax-period.show." invoiceType ".receipt-export-group.index"}}
          data-test-done-button={{true}}
        >
          {{t "invoiceTabSelector.tabs.done" count=doneCount htmlSafe=true}}
        </LinkTo>
      </li>
  
      {{#if isYieldTabActive}}
        <li class="yielded {{activeTabClass}}" data-test-yielded-tab>
          <i class="fa fa-angle-right fa-15x"></i>
          {{yield}}
        </li>
      {{/if}}
    </ul>
  </div>
  
  */
  {
    "id": "fMv3NLke",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"nav-tab\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[11,\"class\",[22,\"waitingTabClass\"]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[[28,\"concat\",[\"tax-period.show.\",[24,[\"invoiceType\"]],\".waiting\"],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"invoiceTabSelector.tabs.waiting\"],[[\"count\",\"htmlSafe\"],[[24,[\"waitingCount\"]],true]]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[11,\"class\",[22,\"readyTabClass\"]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[[28,\"concat\",[\"tax-period.show.\",[24,[\"invoiceType\"]],\".ready\"],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"invoiceTabSelector.tabs.ready\"],[[\"count\",\"htmlSafe\"],[[24,[\"readyCount\"]],true]]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[11,\"class\",[22,\"doneTabClass\"]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[[28,\"concat\",[\"tax-period.show.\",[24,[\"invoiceType\"]],\".receipt-export-group.index\"],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"invoiceTabSelector.tabs.done\"],[[\"count\",\"htmlSafe\"],[[24,[\"doneCount\"]],true]]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isYieldTabActive\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[11,\"class\",[29,[\"yielded \",[22,\"activeTabClass\"]]]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-angle-right fa-15x\"],[8],[9],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invoice-tab-selector/template.hbs"
    }
  });

  _exports.default = _default;
});