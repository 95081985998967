define("da-vinci/components/company-matching/product-account-list/list-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <tr>
    <td>
      <ProductAutocomplete
        @value={{productAccount.product}}
        @onProductChanged={{action "setProduct"}}
        @data-test-product-account-product-autocomplete={{true}}
      />
    </td>
    <td>
      <AccountAutocomplete
        @value={{productAccount.account}}
        @onAccountChanged={{action "setAccount"}}
        @data-test-product-account-account-autocomplete={{true}}
      />
    </td>
    <td>
      <PButton
        @type="outline"
        @size="small"
        @shape="square"
        @onClick={{action removeProductAccount productAccount}}
        @data-test-product-account-remove-button={{true}}
      >
        <i class="fa fa-times"></i>
      </PButton>
    </td>
  </tr>
  
  */
  {
    "id": "BS/CINrX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[5,\"product-autocomplete\",[],[[\"@value\",\"@onProductChanged\"],[[24,[\"productAccount\",\"product\"]],[28,\"action\",[[23,0,[]],\"setProduct\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[5,\"account-autocomplete\",[],[[\"@value\",\"@onAccountChanged\"],[[24,[\"productAccount\",\"account\"]],[28,\"action\",[[23,0,[]],\"setAccount\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[5,\"p-button\",[],[[\"@type\",\"@size\",\"@shape\",\"@onClick\"],[\"outline\",\"small\",\"square\",[28,\"action\",[[23,0,[]],[24,[\"removeProductAccount\"]],[24,[\"productAccount\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/company-matching/product-account-list/list-row/template.hbs"
    }
  });

  _exports.default = _default;
});