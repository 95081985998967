define("da-vinci/components/staff-members/list-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <td data-test-name>
    <p data-recording-sensitive data-test-email>{{staffMember.email}}</p>
    <p data-test-user-type>{{t userTypeTranslationKey}}</p>
  </td>
  <td data-test-parasut-companies>{{truncatedCompanyNames}}</td>
  
  */
  {
    "id": "ypLpK6Rc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"data-recording-sensitive\",\"\"],[8],[1,[24,[\"staffMember\",\"email\"]],false],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[28,\"t\",[[24,[\"userTypeTranslationKey\"]]],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[22,\"truncatedCompanyNames\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/staff-members/list-row/template.hbs"
    }
  });

  _exports.default = _default;
});