define("da-vinci/initializers/setup-pikaday-i18n", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    var i18n = Ember.Object.extend({
      previousMonth: 'Önceki Ay',
      nextMonth: 'Sonraki Ay',
      months: _moment.default.localeData()._months,
      weekdays: _moment.default.localeData()._weekdays,
      weekdaysShort: _moment.default.localeData()._weekdaysShort
    });
    application.register('pikaday-i18n:main', i18n, {
      singleton: true
    });
    application.inject('component:pikaday-inputless', 'i18n', 'pikaday-i18n:main');
    application.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});