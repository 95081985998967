define("da-vinci/tax-period/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{page-title (t "taxPeriodShow.pageTitles.show" taxPeriodSlug=taxPeriodSlug)}}
  {{outlet}}
  
  */
  {
    "id": "pbxCUcwT",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"taxPeriodShow.pageTitles.show\"],[[\"taxPeriodSlug\"],[[24,[\"taxPeriodSlug\"]]]]]],null],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/tax-period/show/template.hbs"
    }
  });

  _exports.default = _default;
});