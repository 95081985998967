define("da-vinci/components/tax-period/past-list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <LinkTo
    @route={{redirectToInvoices}}
    @model={{taxPeriod.slug}}
    @color="primary"
    data-test-redirect-to-invoices-link={{true}}
    data-test-route={{redirectToInvoices}}
  >
    {{#if isBankAccountExist}}
      <h2>{{t (concat "taxPeriodPastListItem.invoiceType." invoiceType)}}</h2>
    {{else}}
      <h2>{{taxPeriod.month}} {{t (concat "taxPeriodPastListItem.invoiceType." invoiceType)}}</h2>
      <span class="year">{{taxPeriod.year}}</span>
    {{/if}}
  </LinkTo>
  
  */
  {
    "id": "uogOfTrN",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[],[[\"@route\",\"@model\",\"@color\"],[[22,\"redirectToInvoices\"],[24,[\"taxPeriod\",\"slug\"]],\"primary\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"isBankAccountExist\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h2\",true],[8],[1,[28,\"t\",[[28,\"concat\",[\"taxPeriodPastListItem.invoiceType.\",[24,[\"invoiceType\"]]],null]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h2\",true],[8],[1,[24,[\"taxPeriod\",\"month\"]],false],[0,\" \"],[1,[28,\"t\",[[28,\"concat\",[\"taxPeriodPastListItem.invoiceType.\",[24,[\"invoiceType\"]]],null]],null],false],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"year\"],[8],[1,[24,[\"taxPeriod\",\"year\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/tax-period/past-list-item/template.hbs"
    }
  });

  _exports.default = _default;
});