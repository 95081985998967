define("da-vinci/utils/boot-bugsnag", ["exports", "bugsnag", "da-vinci/config/environment"], function (_exports, _bugsnag, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = bootBugsnag;

  function bootBugsnag(user) {
    var environment = _environment.default.environment;

    if (environment !== 'production') {
      return;
    }

    var bugsnagClient = (0, _bugsnag.default)({
      apiKey: _environment.default.bugsnagApiKey,
      appVersion: _environment.default.currentRevision,
      releaseStage: environment,
      user: {
        id: user.get('id'),
        email: user.get('email')
      }
    });

    Ember.onerror = function (error) {
      bugsnagClient.notify(error);
    };
  }
});