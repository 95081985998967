define("da-vinci/tax-period/show/purchase-bill/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FILTER_DEFINITIONS = void 0;
  var FILTER_DEFINITIONS = {
    documentDate: {
      componentName: 'filter/document-date-filter',
      displayNameKey: 'documentDateFilter.title',
      displayName: ''
    },
    documentType: {
      componentName: 'filter/document-type-filter',
      displayNameKey: 'documentTypeFilter.title',
      displayName: ''
    },
    vatWithholdingRate: {
      componentName: 'filter/vat-withholding-filter',
      displayNameKey: 'vatWithholdingFilter.title',
      displayName: ''
    },
    accommodationTaxRate: {
      componentName: 'filter/accommodation-tax-filter',
      displayNameKey: 'accommodationTaxFilter.title',
      displayName: ''
    }
  };
  _exports.FILTER_DEFINITIONS = FILTER_DEFINITIONS;
});