define("da-vinci/components/invoice-type-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PBadgeIcon @icon={{icon}} @backgroundColor="white" />
  
  */
  {
    "id": "o+5YavBZ",
    "block": "{\"symbols\":[],\"statements\":[[5,\"p-badge-icon\",[],[[\"@icon\",\"@backgroundColor\"],[[22,\"icon\"],\"white\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invoice-type-icon/template.hbs"
    }
  });

  _exports.default = _default;
});