define("da-vinci/components/company-onboard-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <CompanyMatchingModal
    @parasutCompany={{parasutCompany}}
    @companyMatching={{parasutCompany.companyMatching}}
    @openingMode="wizard"
    @currentState="showCreateCompanyMatching"
    @matchCompaniesTask={{matchCompaniesTask}}
    @onCancel={{onCancel}}
    @onSuccess={{onSuccess}}
    @onDestroyInProgress={{onCompanyMatchingModalDestroy}}
    @data-test-company-matching-modal={{true}}
  />
  
  */
  {
    "id": "Rfj6GJVc",
    "block": "{\"symbols\":[],\"statements\":[[5,\"company-matching-modal\",[],[[\"@parasutCompany\",\"@companyMatching\",\"@openingMode\",\"@currentState\",\"@matchCompaniesTask\",\"@onCancel\",\"@onSuccess\",\"@onDestroyInProgress\"],[[22,\"parasutCompany\"],[24,[\"parasutCompany\",\"companyMatching\"]],\"wizard\",\"showCreateCompanyMatching\",[22,\"matchCompaniesTask\"],[22,\"onCancel\"],[22,\"onSuccess\"],[22,\"onCompanyMatchingModalDestroy\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/company-onboard-modal/template.hbs"
    }
  });

  _exports.default = _default;
});