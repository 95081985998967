define("da-vinci/user-invitation/accept-by-key-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{title (t "userInvitation.acceptByKey.errorPage.pageTitle")}}
  <p data-test-error-info>
    {{#if userInvitationNotFound}}
      {{t "userInvitation.acceptByKey.errorPage.notFoundError"}}
    {{else}}
      {{t "userInvitation.acceptByKey.errorPage.unknownError"}}
    {{/if}}
  </p>
  
  */
  {
    "id": "qxSNMQhV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"userInvitation.acceptByKey.errorPage.pageTitle\"],null]],[[\"_deprecate\"],[null]]],false],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"userInvitationNotFound\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"t\",[\"userInvitation.acceptByKey.errorPage.notFoundError\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"t\",[\"userInvitation.acceptByKey.errorPage.unknownError\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/user-invitation/accept-by-key-error/template.hbs"
    }
  });

  _exports.default = _default;
});