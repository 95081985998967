define("da-vinci/components/create-new-company-modal/email-failed-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal
    @isOpen={{true}}
    @dataTestModalName="email-failed-step"
    @containerClassNames={{styleNamespace}}
    as |modal|
  >
    <modal.header>
      <h2 data-test-modal-header-title>{{t "createNewCompanyModal.emailFailedStep.title"}}</h2>
    </modal.header>
  
    <modal.body>
      {{t
        "createNewCompanyModal.emailFailedStep.bodyMessage"
        email=email
        htmlSafe=true
      }}
    </modal.body>
  
    <modal.footer>
      <PButton
        @type="outline"
        @onClick={{onCancel}}
        @data-test-done-button={{true}}
      >
        {{t "createNewCompanyModal.buttons.done"}}
      </PButton>
    </modal.footer>
  </PModal>
  
  */
  {
    "id": "OaeNkuAO",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@dataTestModalName\",\"@containerClassNames\"],[true,\"email-failed-step\",[22,\"styleNamespace\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"createNewCompanyModal.emailFailedStep.title\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"createNewCompanyModal.emailFailedStep.bodyMessage\"],[[\"email\",\"htmlSafe\"],[[24,[\"email\"]],true]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-button\",[],[[\"@type\",\"@onClick\"],[\"outline\",[22,\"onCancel\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"createNewCompanyModal.buttons.done\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/create-new-company-modal/email-failed-step/template.hbs"
    }
  });

  _exports.default = _default;
});