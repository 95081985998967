define("da-vinci/components/portal-integration-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div data-test-portal-integration-modal>
    {{#if showPortalRegisterModal}}
      <PortalIntegrationModal::RegisterModal
        @popUpOpen={{true}}
        @onCancel={{onCancel}}
        @goBackToMainPage={{action goBackToMainPage}}
      />
    {{else if showMainPageModal}}
      <TaxpayerIntegrationModal
        @popUpOpen={{true}}
        @onCancel={{onCancel}}
      />
    {{/if}}
  </div>
  
  */
  {
    "id": "IyZO+62R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showPortalRegisterModal\"]]],null,{\"statements\":[[0,\"    \"],[5,\"portal-integration-modal/register-modal\",[],[[\"@popUpOpen\",\"@onCancel\",\"@goBackToMainPage\"],[true,[22,\"onCancel\"],[28,\"action\",[[23,0,[]],[24,[\"goBackToMainPage\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showMainPageModal\"]]],null,{\"statements\":[[0,\"    \"],[5,\"taxpayer-integration-modal\",[],[[\"@popUpOpen\",\"@onCancel\"],[true,[22,\"onCancel\"]]]],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/portal-integration-modal/template.hbs"
    }
  });

  _exports.default = _default;
});