define("da-vinci/components/user-invitations/accept/not-registered-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showProfileInfoStep}}
    <SignUpModal::ProfileInfoStep
      @name={{name}}
      @phone={{phone}}
      @agreement={{agreement}}
      @kvkk={{kvkk}}
      @teik={{teik}}
      @updateName={{action (mut name)}}
      @updatePhone={{action (mut phone)}}
      @updateAgreement={{action (mut agreement)}}
      @updateKvkk={{action (mut kvkk)}}
      @updateTeik={{action (mut teik)}}
      @proceedToNextStep={{action proceedToNextStep}}
      @stepIndicators={{hash steps=2 activeStep=1}}
    />
  {{else if showPasswordStep}}
    <SignUpModal::PasswordStep
      @password={{password}}
      @passwordConfirmation={{passwordConfirmation}}
      @updatePassword={{action (mut password)}}
      @updatePasswordConfirmation={{action (mut passwordConfirmation)}}
      @userCreateTask={{userCreateTask}}
      @stepIndicators={{hash steps=2 activeStep=2}}
    />
  {{/if}}
  
  */
  {
    "id": "VQ/i8uA4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showProfileInfoStep\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sign-up-modal/profile-info-step\",[],[[\"@name\",\"@phone\",\"@agreement\",\"@kvkk\",\"@teik\",\"@updateName\",\"@updatePhone\",\"@updateAgreement\",\"@updateKvkk\",\"@updateTeik\",\"@proceedToNextStep\",\"@stepIndicators\"],[[22,\"name\"],[22,\"phone\"],[22,\"agreement\"],[22,\"kvkk\"],[22,\"teik\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"name\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"phone\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"agreement\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"kvkk\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"teik\"]]],null]],null],[28,\"action\",[[23,0,[]],[24,[\"proceedToNextStep\"]]],null],[28,\"hash\",null,[[\"steps\",\"activeStep\"],[2,1]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showPasswordStep\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sign-up-modal/password-step\",[],[[\"@password\",\"@passwordConfirmation\",\"@updatePassword\",\"@updatePasswordConfirmation\",\"@userCreateTask\",\"@stepIndicators\"],[[22,\"password\"],[22,\"passwordConfirmation\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"password\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"passwordConfirmation\"]]],null]],null],[22,\"userCreateTask\"],[28,\"hash\",null,[[\"steps\",\"activeStep\"],[2,2]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/user-invitations/accept/not-registered-modal/template.hbs"
    }
  });

  _exports.default = _default;
});