define("da-vinci/components/receipt-export-group-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if isBankTransaction}}
    <div
      data-test-name
      class="bank-export-group"
    >
      {{receiptExportGroup.name}}
    </div>
    <div data-test-created-at class="bank-export-group-col">
      {{moment-format
        receiptExportGroup.createdAt
        "DD.MM.YYYY"
      }}
    </div>
    <div
      data-test-bank-tx-count
      class="bank-export-group-col"
    >
      {{receiptExportGroup.bankTransactionsCount}}
    </div>
  {{else}}
    <div data-test-name>{{receiptExportGroup.name}}</div>
    <div data-test-created-at>
      {{moment-format
        receiptExportGroup.createdAt
        "DD.MM.YYYY"
      }}
    </div>
    <div data-test-invoices-count>{{receiptExportGroup.invoicesCount}}</div>
  {{/if}}
  
  {{#unless isBankTransaction}}
    <div>
      <PAmountDisplay
        @value={{receiptExportGroup.netTotal}}
        @data-test-net-total={{true}}
      />
    </div>
    <div>
      <PAmountDisplay
        @value={{receiptExportGroup.netTotalWithoutVat}}
        @data-test-net-total-without-vat={{true}}
      />
    </div>
    <div>
      <PAmountDisplay
        @value={{receiptExportGroup.totalVat}}
        @data-test-total-vat={{true}}
      />
    </div>
  {{/unless}}
  
  */
  {
    "id": "jjIWwVqr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isBankTransaction\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"bank-export-group\"],[8],[0,\"\\n    \"],[1,[24,[\"receiptExportGroup\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"bank-export-group-col\"],[8],[0,\"\\n    \"],[1,[28,\"moment-format\",[[24,[\"receiptExportGroup\",\"createdAt\"]],\"DD.MM.YYYY\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"bank-export-group-col\"],[8],[0,\"\\n    \"],[1,[24,[\"receiptExportGroup\",\"bankTransactionsCount\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[1,[24,[\"receiptExportGroup\",\"name\"]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[28,\"moment-format\",[[24,[\"receiptExportGroup\",\"createdAt\"]],\"DD.MM.YYYY\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[1,[24,[\"receiptExportGroup\",\"invoicesCount\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"unless\",[[24,[\"isBankTransaction\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[5,\"p-amount-display\",[],[[\"@value\"],[[24,[\"receiptExportGroup\",\"netTotal\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[5,\"p-amount-display\",[],[[\"@value\"],[[24,[\"receiptExportGroup\",\"netTotalWithoutVat\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[5,\"p-amount-display\",[],[[\"@value\"],[[24,[\"receiptExportGroup\",\"totalVat\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/receipt-export-group-row/template.hbs"
    }
  });

  _exports.default = _default;
});