define("da-vinci/components/atlas-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{!-- Since the wrapper element itself is a label, we need to disable this rule for this file --}}
  {{! template-lint-disable require-input-label }}
  
  {{#if busy}}
    <i
      class="fa fa-spinner fa-pulse loading-spinner"
      aria-label={{t "aria.label.loading"}}
      data-test-checkbox-busy-spinner
    >
    </i>
  {{else}}
    <input
      data-test-atlas-checkbox-input
      type="checkbox"
      name={{name}}
      checked={{checked}}
      onclick={{onClick}}
      required={{required}}
      disabled={{disabled}}
    >
  {{/if}}
  
  {{yield}}
  
  */
  {
    "id": "fbopuAYy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"busy\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-pulse loading-spinner\"],[11,\"aria-label\",[28,\"t\",[\"aria.label.loading\"],null]],[8],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"name\",[22,\"name\"]],[11,\"checked\",[22,\"checked\"]],[11,\"onclick\",[22,\"onClick\"]],[11,\"required\",[22,\"required\"]],[11,\"disabled\",[22,\"disabled\"]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/atlas-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});