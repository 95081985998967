define("da-vinci/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <HeadLayout />
  {{page-title "Paraşüt Atlas" separator=" ▸ "}}
  {{outlet}}
  <PFlashMsg />
  
  */
  {
    "id": "P5OMx/3M",
    "block": "{\"symbols\":[],\"statements\":[[5,\"head-layout\",[],[[],[]]],[0,\"\\n\"],[1,[28,\"page-title\",[\"Paraşüt Atlas\"],[[\"separator\"],[\" ▸ \"]]],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[5,\"p-flash-msg\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/application/template.hbs"
    }
  });

  _exports.default = _default;
});