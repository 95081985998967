define("da-vinci/staff-member/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{title model.name}}
  
  <StaffMembers::ShowHeader
    @breadcrumb={{component "p-breadcrumb"}}
    @companyCount={{staffMember.parasutCompanies.length}}
  >
    <AccountantOfficeTabSelector @tab="staffMember" />
  </StaffMembers::ShowHeader>
  
  <ParasutCompanyList
    @parasutCompanies={{staffMember.parasutCompanies}}
    @meta={{meta}}
    @showCreateCompanyButton={{false}}
    @showAssignedMembers={{false}}
  />
  
  */
  {
    "id": "UjZYYqCi",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"model\",\"name\"]]],[[\"_deprecate\"],[null]]],false],[0,\"\\n\\n\"],[5,\"staff-members/show-header\",[],[[\"@breadcrumb\",\"@companyCount\"],[[28,\"component\",[\"p-breadcrumb\"],null],[24,[\"staffMember\",\"parasutCompanies\",\"length\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"accountant-office-tab-selector\",[],[[\"@tab\"],[\"staffMember\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"parasut-company-list\",[],[[\"@parasutCompanies\",\"@meta\",\"@showCreateCompanyButton\",\"@showAssignedMembers\"],[[24,[\"staffMember\",\"parasutCompanies\"]],[22,\"meta\"],false,false]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/staff-member/show/template.hbs"
    }
  });

  _exports.default = _default;
});