define("da-vinci/components/announcement-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal @isOpen={{isOpen}}
    @containerClassNames="announcement-modal-container" as |modal|>
    <modal.header>
      <div style="display: flex; justify-content:space-between; align-items:center">
        <h2>
          {{t "announcementModal.header.title"}}
        </h2>
        <PButton style="color: #5f5755; border-color:#5f5755;" @type="outline" @onClick={{action (mut isOpen) false}}>
          <i class="fa fa-times"></i>
        </PButton>
      </div>
    </modal.header>
  
    <modal.body>
      {{t "announcementModal.body.announcementMessage" htmlSafe=true}}
    </modal.body>
  
    <modal.footer>
      <PButton @color="secondary" @type="outline" @onClick={{action doNotShowAnnouncementPermanently}}>
        {{t "announcementModal.footer.doNotShowAnnouncementPermanently"}}
      </PButton>
      <PButton @color="collection" @tagName="a" @href="https://form.jotform.com/221312876241955" style="text-transform: uppercase;">
        {{t "announcementModal.footer.companyInfoForm"}}
      </PButton>
    </modal.footer>
  </PModal>
  
  */
  {
    "id": "XQNWiAkZ",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@containerClassNames\"],[[22,\"isOpen\"],\"announcement-modal-container\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"style\",\"display: flex; justify-content:space-between; align-items:center\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"announcementModal.header.title\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[5,\"p-button\",[[12,\"style\",\"color: #5f5755; border-color:#5f5755;\"]],[[\"@type\",\"@onClick\"],[\"outline\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isOpen\"]]],null],false],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"announcementModal.body.announcementMessage\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-button\",[],[[\"@color\",\"@type\",\"@onClick\"],[\"secondary\",\"outline\",[28,\"action\",[[23,0,[]],[24,[\"doNotShowAnnouncementPermanently\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"announcementModal.footer.doNotShowAnnouncementPermanently\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"p-button\",[[12,\"style\",\"text-transform: uppercase;\"]],[[\"@color\",\"@tagName\",\"@href\"],[\"collection\",\"a\",\"https://form.jotform.com/221312876241955\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"announcementModal.footer.companyInfoForm\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/announcement-modal/template.hbs"
    }
  });

  _exports.default = _default;
});