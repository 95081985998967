define("da-vinci/components/company-matching-modal/sales-accounts-step/product-account-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#each productAccounts as |productAccount|}}
    <CompanyMatchingModal::SalesAccountsStep::ProductAccountList::ListRow
      @productAccount={{productAccount}}
      @onProductChanged={{onProductChanged}}
      @onAccountChanged={{onAccountChanged}}
      @removeProductAccount={{removeProductAccount}}
      @data-test-product-account-list-row={{true}}
    />
  {{/each}}
  
  <PButton
    @type="outline"
    @size="small"
    @onClick={{addProductAccount}}
    @data-test-product-account-add-button={{true}}
    @class="add-product"
  >
    <i class="fa fa-plus"></i>
    {{t "companyMatchingModalSalesAccountsStepProductAccountList.button.addNew"}}
  </PButton>
  
  */
  {
    "id": "uZv2D8lA",
    "block": "{\"symbols\":[\"productAccount\"],\"statements\":[[4,\"each\",[[24,[\"productAccounts\"]]],null,{\"statements\":[[0,\"  \"],[5,\"company-matching-modal/sales-accounts-step/product-account-list/list-row\",[],[[\"@productAccount\",\"@onProductChanged\",\"@onAccountChanged\",\"@removeProductAccount\"],[[23,1,[]],[22,\"onProductChanged\"],[22,\"onAccountChanged\"],[22,\"removeProductAccount\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[5,\"p-button\",[],[[\"@type\",\"@size\",\"@onClick\",\"@class\"],[\"outline\",\"small\",[22,\"addProductAccount\"],\"add-product\"]],{\"statements\":[[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\"\\n  \"],[1,[28,\"t\",[\"companyMatchingModalSalesAccountsStepProductAccountList.button.addNew\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/company-matching-modal/sales-accounts-step/product-account-list/template.hbs"
    }
  });

  _exports.default = _default;
});