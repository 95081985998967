define("da-vinci/components/staff-info-modal-edit-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PButton
    @onClick={{onClick}}
    @type="outline"
    @size="tiny"
    @data-test-staff-info-modal-edit-button={{true}}
  >
    <i
      class="fa fa-pencil"
      aria-label={{t "staffMembers.button.editStaffInfoModal"}}
    >
    </i>
    {{t "staffMembers.button.editStaffInfoModal"}}
  </PButton>
  
  */
  {
    "id": "/TkiFuL1",
    "block": "{\"symbols\":[],\"statements\":[[5,\"p-button\",[],[[\"@onClick\",\"@type\",\"@size\"],[[22,\"onClick\"],\"outline\",\"tiny\"]],{\"statements\":[[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-pencil\"],[11,\"aria-label\",[28,\"t\",[\"staffMembers.button.editStaffInfoModal\"],null]],[8],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"t\",[\"staffMembers.button.editStaffInfoModal\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/staff-info-modal-edit-button/template.hbs"
    }
  });

  _exports.default = _default;
});