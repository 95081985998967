define("da-vinci/components/atlas-step-indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#each stepClassnames as |stepClassname|}}
    <li
      data-test-atlas-step-indicator-item
      class="step-indicator-item {{stepClassname}}"
    >
    </li>
  {{/each}}
  
  */
  {
    "id": "ZLUAi0yc",
    "block": "{\"symbols\":[\"stepClassname\"],\"statements\":[[4,\"each\",[[24,[\"stepClassnames\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[11,\"class\",[29,[\"step-indicator-item \",[23,1,[]]]]],[8],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/atlas-step-indicators/template.hbs"
    }
  });

  _exports.default = _default;
});