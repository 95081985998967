define("da-vinci/components/taxpayer-integration-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showMainPageModal}}
    <TaxpayerIntegrationModal::MainPageModal
      @popUpOpen={{false}}
      @onCancel={{onCancel}}
      @portal={{false}}
      @parasut={{false}}
      @bizmu={{false}}
      @openedAt={{openedAt}}
      @proceedToStep={{action proceedToStep}}
    />
  {{else if showPortalIntegrationModal}}
    <PortalIntegrationModal
      @popUpOpen={{false}}
      @onCancel={{onCancel}}
      @proceedToStep={{action proceedToStep}}
    />
  {{else if showLeadForm}}
    <LeadAccountantModal
      @onCancel={{onCancel}}
      @popUpOpen={{false}}
      @openedAt={{openedAt}}
    ></LeadAccountantModal>
  {{/if}}
  
  */
  {
    "id": "zUNBab8R",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showMainPageModal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"taxpayer-integration-modal/main-page-modal\",[],[[\"@popUpOpen\",\"@onCancel\",\"@portal\",\"@parasut\",\"@bizmu\",\"@openedAt\",\"@proceedToStep\"],[false,[22,\"onCancel\"],false,false,false,[22,\"openedAt\"],[28,\"action\",[[23,0,[]],[24,[\"proceedToStep\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showPortalIntegrationModal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"portal-integration-modal\",[],[[\"@popUpOpen\",\"@onCancel\",\"@proceedToStep\"],[false,[22,\"onCancel\"],[28,\"action\",[[23,0,[]],[24,[\"proceedToStep\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showLeadForm\"]]],null,{\"statements\":[[0,\"  \"],[5,\"lead-accountant-modal\",[],[[\"@onCancel\",\"@popUpOpen\",\"@openedAt\"],[[22,\"onCancel\"],false,[22,\"openedAt\"]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/taxpayer-integration-modal/template.hbs"
    }
  });

  _exports.default = _default;
});