define("da-vinci/components/purchase-bill-accommodation-tax-row/basic-accounting-type/list-row-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <tr>
    <td class="bg-set"></td>
    <td data-test-quantity>{{detail.quantity}}</td>
    <td data-test-product-name>{{detail.productName}}</td>
    <td data-test-accommodation-tax-rate>%{{round-vat-rate-int detail.accommodationTaxRate}}</td>
    <td><PAmountDisplay @value={{detail.accommodationTaxInTrl}} @data-test-accommodation-tax={{true}}/></td>
    <td>
      <PAmountDisplay
        @value={{accommodationTaxBase}}
        @data-test-accommodation-tax-base={{true}}
      />
    </td>
    <td>
      <PAmountDisplay
        @value={{detail.netTotalInTrl}}
        @data-test-net-total={{true}}
      />
    </td>
  </tr>
  
  */
  {
    "id": "3GUyPIP4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"bg-set\"],[8],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[1,[24,[\"detail\",\"quantity\"]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[1,[24,[\"detail\",\"productName\"]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"%\"],[1,[28,\"round-vat-rate-int\",[[24,[\"detail\",\"accommodationTaxRate\"]]],null],false],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[5,\"p-amount-display\",[],[[\"@value\"],[[24,[\"detail\",\"accommodationTaxInTrl\"]]]]],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[5,\"p-amount-display\",[],[[\"@value\"],[[22,\"accommodationTaxBase\"]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[5,\"p-amount-display\",[],[[\"@value\"],[[24,[\"detail\",\"netTotalInTrl\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/purchase-bill-accommodation-tax-row/basic-accounting-type/list-row-detail/template.hbs"
    }
  });

  _exports.default = _default;
});