define("da-vinci/helpers/array-join", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayJoin = arrayJoin;
  _exports.default = void 0;

  function arrayJoin(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        array = _ref2[0],
        seperator = _ref2[1];

    return array.join(seperator);
  }

  var _default = Ember.Helper.helper(arrayJoin);

  _exports.default = _default;
});