define("da-vinci/components/create-new-company-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showEmailStep}}
    <CreateNewCompanyModal::EmailStep
      @onCancel={{onCancel}}
      @email={{email}}
      @updateEmail={{action (mut email)}}
      @proceedToNextStep={{action proceedToNextStep}}
    />
  {{else if showEmailFailedStep}}
    <CreateNewCompanyModal::EmailFailedStep
      @onCancel={{onCancel}}
      @email={{email}}
    />
  {{else if showAccountStep}}
    <CreateNewCompanyModal::AccountStep
      @onCancel={{onCancel}}
      @email={{email}}
      @companyName={{companyName}}
      @contactName={{contactName}}
      @phoneNumber={{phoneNumber}}
      @updateCompanyName={{action (mut companyName)}}
      @updateContactName={{action (mut contactName)}}
      @updatePhoneNumber={{action (mut phoneNumber)}}
      @proceedToNextStep={{action proceedToNextStep}}
    />
  {{else if showAccountCreatedStep}}
    <CreateNewCompanyModal::AccountCreatedStep
      @onCancel={{action "closeModal"}}
      @email={{email}}
      @companyName={{companyName}}
    />
  {{/if}}
  
  */
  {
    "id": "YGo4FeRh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showEmailStep\"]]],null,{\"statements\":[[0,\"  \"],[5,\"create-new-company-modal/email-step\",[],[[\"@onCancel\",\"@email\",\"@updateEmail\",\"@proceedToNextStep\"],[[22,\"onCancel\"],[22,\"email\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"email\"]]],null]],null],[28,\"action\",[[23,0,[]],[24,[\"proceedToNextStep\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showEmailFailedStep\"]]],null,{\"statements\":[[0,\"  \"],[5,\"create-new-company-modal/email-failed-step\",[],[[\"@onCancel\",\"@email\"],[[22,\"onCancel\"],[22,\"email\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showAccountStep\"]]],null,{\"statements\":[[0,\"  \"],[5,\"create-new-company-modal/account-step\",[],[[\"@onCancel\",\"@email\",\"@companyName\",\"@contactName\",\"@phoneNumber\",\"@updateCompanyName\",\"@updateContactName\",\"@updatePhoneNumber\",\"@proceedToNextStep\"],[[22,\"onCancel\"],[22,\"email\"],[22,\"companyName\"],[22,\"contactName\"],[22,\"phoneNumber\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"companyName\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contactName\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"phoneNumber\"]]],null]],null],[28,\"action\",[[23,0,[]],[24,[\"proceedToNextStep\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showAccountCreatedStep\"]]],null,{\"statements\":[[0,\"  \"],[5,\"create-new-company-modal/account-created-step\",[],[[\"@onCancel\",\"@email\",\"@companyName\"],[[28,\"action\",[[23,0,[]],\"closeModal\"],null],[22,\"email\"],[22,\"companyName\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/create-new-company-modal/template.hbs"
    }
  });

  _exports.default = _default;
});