define("da-vinci/user-invitation/accept-by-key/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{title (t "userInvitation.acceptByKey.pageTitle")}}
  
  {{#if inviteeIsAlreadyEmployee}}
    <UserInvitations::Accept::AlreadyEmployeeModal
      @invitedBy={{userInvitation.invitedBy}}
      @accountantOffice={{userInvitation.accountantOffice}}
      @acceptInvitationTask={{acceptInvitationTask}}
    />
  {{else if inviteeIsAlreadyOwner}}
    <UserInvitations::Accept::AlreadyOwnerModal
      @invitedBy={{userInvitation.invitedBy}}
      @accountantOffice={{userInvitation.accountantOffice}}
    />
  {{else if inviteeIsNotRegistered}}
    <UserInvitations::Accept::NotRegisteredModal
      @acceptInvitationTask={{acceptInvitationTask}}
      @email={{userInvitation.email}}
    />
  {{/if}}
  
  */
  {
    "id": "KWKhNbVs",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"userInvitation.acceptByKey.pageTitle\"],null]],[[\"_deprecate\"],[null]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"inviteeIsAlreadyEmployee\"]]],null,{\"statements\":[[0,\"  \"],[5,\"user-invitations/accept/already-employee-modal\",[],[[\"@invitedBy\",\"@accountantOffice\",\"@acceptInvitationTask\"],[[24,[\"userInvitation\",\"invitedBy\"]],[24,[\"userInvitation\",\"accountantOffice\"]],[22,\"acceptInvitationTask\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"inviteeIsAlreadyOwner\"]]],null,{\"statements\":[[0,\"  \"],[5,\"user-invitations/accept/already-owner-modal\",[],[[\"@invitedBy\",\"@accountantOffice\"],[[24,[\"userInvitation\",\"invitedBy\"]],[24,[\"userInvitation\",\"accountantOffice\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"inviteeIsNotRegistered\"]]],null,{\"statements\":[[0,\"  \"],[5,\"user-invitations/accept/not-registered-modal\",[],[[\"@acceptInvitationTask\",\"@email\"],[[22,\"acceptInvitationTask\"],[24,[\"userInvitation\",\"email\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/user-invitation/accept-by-key/template.hbs"
    }
  });

  _exports.default = _default;
});