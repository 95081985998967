define("da-vinci/helpers/text-or-dash", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.textOrDash = textOrDash;

  function textOrDash(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        text = _ref2[0];

    return text || Ember.String.htmlSafe('&mdash;');
  }

  var _default = Ember.Helper.helper(textOrDash);

  _exports.default = _default;
});