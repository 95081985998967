define("da-vinci/components/accountant-office-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="header-container">
    <div class="header-row-bc">
      <div class="bread-crumb" data-test-accountant-office-title>
        {{breadcrumb}}
      </div>
    </div>
    <div class="header-row-dc" data-test-accountant-office-body-text>
    </div>
    <div class="header-row-tb">
      <div class="nav-tab">
        <ul>
          {{yield}}
        </ul>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "CSyZ/qm5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-row-bc\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"bread-crumb\"],[8],[0,\"\\n      \"],[1,[22,\"breadcrumb\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-row-dc\"],[8],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-row-tb\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"nav-tab\"],[8],[0,\"\\n      \"],[7,\"ul\",true],[8],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/accountant-office-header/template.hbs"
    }
  });

  _exports.default = _default;
});