define("da-vinci/components/bank-transactions/bulk-approve-modal/transfer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal
    @isOpen={{true}}
    @containerClassNames={{styleNamespace}}
    @dataTestModalName="bank-transactions-bulk-approve-modal-transfer"
    as |modal|
  >
    <modal.header>
      <h2>{{t "bankTransactionsBulkApproveModalTransfer.title"}}</h2>
    </modal.header>
  
    <modal.body>
      <PProgressBar @step={{1}} @count={{1}} @displayProgressValue={{false}} />
      <p>{{t "bankTransactionsBulkApproveModalTransfer.description"}}</p>
    </modal.body>
  </PModal>
  
  */
  {
    "id": "MpU/BQz4",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@containerClassNames\",\"@dataTestModalName\"],[true,[22,\"styleNamespace\"],\"bank-transactions-bulk-approve-modal-transfer\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"bankTransactionsBulkApproveModalTransfer.title\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-progress-bar\",[],[[\"@step\",\"@count\",\"@displayProgressValue\"],[1,1,false]]],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"bankTransactionsBulkApproveModalTransfer.description\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/bank-transactions/bulk-approve-modal/transfer/template.hbs"
    }
  });

  _exports.default = _default;
});