define("da-vinci/components/invoice-xml-download-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showError}}
    <InvoiceXmlDownloadModal::Error
      @onClose={{onClose}}
      @errorDetailKey={{errorDetailKey}}
    />
  {{else if showTimeout}}
    <InvoiceXmlDownloadModal::Timeout @onClose={{onClose}} />
  {{else if showInProgress}}
    <InvoiceXmlDownloadModal::InProgress
      @onClose={{onClose}}
      @missingDocumentNo={{missingDocumentNo}}
      @xmlCount={{xmlCount}}
      @notFoundCount={{notFoundCount}}
    />
  {{/if}}
  
  */
  {
    "id": "/47F/G7C",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showError\"]]],null,{\"statements\":[[0,\"  \"],[5,\"invoice-xml-download-modal/error\",[],[[\"@onClose\",\"@errorDetailKey\"],[[22,\"onClose\"],[22,\"errorDetailKey\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showTimeout\"]]],null,{\"statements\":[[0,\"  \"],[5,\"invoice-xml-download-modal/timeout\",[],[[\"@onClose\"],[[22,\"onClose\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showInProgress\"]]],null,{\"statements\":[[0,\"  \"],[5,\"invoice-xml-download-modal/in-progress\",[],[[\"@onClose\",\"@missingDocumentNo\",\"@xmlCount\",\"@notFoundCount\"],[[22,\"onClose\"],[22,\"missingDocumentNo\"],[22,\"xmlCount\"],[22,\"notFoundCount\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invoice-xml-download-modal/template.hbs"
    }
  });

  _exports.default = _default;
});