define("da-vinci/components/user-info-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showInfo}}
    <UserInfoModal::Show
      @closeModal={{closeUserInfoModal}}
      @onEdit={{action showEditModal}}
    />
  {{else if showEdit}}
    <UserInfoModal::Edit @showInfoModal={{action showInfoModal}} />
  {{/if}}
  
  */
  {
    "id": "4ne+Mnlh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showInfo\"]]],null,{\"statements\":[[0,\"  \"],[5,\"user-info-modal/show\",[],[[\"@closeModal\",\"@onEdit\"],[[22,\"closeUserInfoModal\"],[28,\"action\",[[23,0,[]],[24,[\"showEditModal\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showEdit\"]]],null,{\"statements\":[[0,\"  \"],[5,\"user-info-modal/edit\",[],[[\"@showInfoModal\"],[[28,\"action\",[[23,0,[]],[24,[\"showInfoModal\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/user-info-modal/template.hbs"
    }
  });

  _exports.default = _default;
});