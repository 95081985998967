define("da-vinci/utils/build-url-from-query-params", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "da-vinci/config/environment"], function (_exports, _slicedToArray2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function buildURLFromQueryParams(parasutCompanyId, queryParams) {
    var url = new URL("".concat(_environment.default.emberHost, "/luca/veri-aktarma"));
    url.searchParams.append('parasutCompanyId', parasutCompanyId);
    Object.entries(queryParams.filter).forEach(function (filter) {
      var _filter = (0, _slicedToArray2.default)(filter, 2),
          key = _filter[0],
          value = _filter[1];

      if (Ember.isPresent(value)) {
        url.searchParams.append(key, value);
      }
    });
    return url;
  }

  var _default = buildURLFromQueryParams;
  _exports.default = _default;
});