define("da-vinci/unauthenticated-accountant-office-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{outlet}}
  
  */
  {
    "id": "LrLVnwKg",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/unauthenticated-accountant-office-container/template.hbs"
    }
  });

  _exports.default = _default;
});