define("da-vinci/import-invoice/invalid-invoices-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal
    @isOpen={{true}}
    @dataTestModalName="invalid-invoices-modal"
    @containerClassNames="show-modal-container"
    @wrapperClassNames={{styleNamespace}}
    as |modal|
  >
    <modal.header>
      <h2 data-test-invalid-invoices-modal-header-title>
        {{t "importInvoices.invalidInvoicesModal.header"}}
      </h2>
      <PButton @type="outline" @onClick={{onCancel}}>
        <i class="fa fa-times"></i>
      </PButton>
    </modal.header>
  
    <modal.body>
      <p data-test-invalid-invoices-body-description>
        {{t
          "importInvoices.invalidInvoicesModal.description"
          validInvoiceCount=validInvoiceCount
          invalidInvoiceCount=invalidInvoiceCount
        }}
      </p>
    </modal.body>
  
    <modal.footer>
      <PButton
        @color="secondary"
        @onClick={{uploadButtonTrigger}}
        @data-test-invalid-invoices-modal-reupload-button={{true}}
      >
        {{t "importInvoices.invalidInvoicesModal.reupload"}}
      </PButton>
  
      <PButton
        @onClick={{createButtonTrigger}}
        @data-test-invalid-invoices-modal-create-button={{true}}
      >
        {{t "importInvoices.invalidInvoicesModal.confirm"}}
      </PButton>
    </modal.footer>
  </PModal>
  
  */
  {
    "id": "Ods3Hybg",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@dataTestModalName\",\"@containerClassNames\",\"@wrapperClassNames\"],[true,\"invalid-invoices-modal\",\"show-modal-container\",[22,\"styleNamespace\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"importInvoices.invalidInvoicesModal.header\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"p-button\",[],[[\"@type\",\"@onClick\"],[\"outline\",[22,\"onCancel\"]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"importInvoices.invalidInvoicesModal.description\"],[[\"validInvoiceCount\",\"invalidInvoiceCount\"],[[24,[\"validInvoiceCount\"]],[24,[\"invalidInvoiceCount\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-button\",[],[[\"@color\",\"@onClick\"],[\"secondary\",[22,\"uploadButtonTrigger\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"importInvoices.invalidInvoicesModal.reupload\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"p-button\",[],[[\"@onClick\"],[[22,\"createButtonTrigger\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"importInvoices.invalidInvoicesModal.confirm\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/import-invoice/invalid-invoices-modal/template.hbs"
    }
  });

  _exports.default = _default;
});