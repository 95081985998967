define("da-vinci/tax-period/show/bank-account/transaction/receipt-export-group/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{page-title (t "taxPeriodShow.pageTitles.receiptExportGroup.index")}}
  <AppHeader
    @pageDescription=""
    @previousPage={{action "previousPage"}}
    @breadcrumb={{component "p-breadcrumb"}}
    @taxPeriodSlug={{taxPeriodSlug}}
    @accountingType={{accountingType}}
  >
    <ProcessStatusTabSelector
      @tab="done"
      @documentType={{documentType}}
      @meta={{meta}}
    />
  </AppHeader>
  
  {{#if receiptExportGroups.length}}
    <ReceiptExportGroupTable
      @items={{receiptExportGroups}}
      @invoiceType={{documentType}}
    />
  {{else}}
    <TableEmptyState>
      {{t "receiptExportGroupTable.emptyText" htmlSafe=true}}
    </TableEmptyState>
  {{/if}}
  
  */
  {
    "id": "Fb89CxL0",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"taxPeriodShow.pageTitles.receiptExportGroup.index\"],null]],null],false],[0,\"\\n\"],[5,\"app-header\",[],[[\"@pageDescription\",\"@previousPage\",\"@breadcrumb\",\"@taxPeriodSlug\",\"@accountingType\"],[\"\",[28,\"action\",[[23,0,[]],\"previousPage\"],null],[28,\"component\",[\"p-breadcrumb\"],null],[22,\"taxPeriodSlug\"],[22,\"accountingType\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"process-status-tab-selector\",[],[[\"@tab\",\"@documentType\",\"@meta\"],[\"done\",[22,\"documentType\"],[22,\"meta\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"receiptExportGroups\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[5,\"receipt-export-group-table\",[],[[\"@items\",\"@invoiceType\"],[[22,\"receiptExportGroups\"],[22,\"documentType\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"table-empty-state\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"receiptExportGroupTable.emptyText\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/tax-period/show/bank-account/transaction/receipt-export-group/index/template.hbs"
    }
  });

  _exports.default = _default;
});