define("da-vinci/components/tax-period/bank-accounts/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <LinkTo
    @route="tax-period.show.bank-account.transaction.waiting"
    @models={{array taxPeriod.slug bankAccount.id}}
    data-test-transaction-show-link={{true}}
  >
    <td>
      <PBadgeIcon @icon={{bankIcon}} />
      <div data-test-bank-name>
        <span>{{bankAccount.name}}</span>
      </div>
      <div class="iban">
        <span data-test-iban>{{bankAccount.iban}}</span>
        -
        <span data-test-currency>{{bankAccount.currency}}</span>
      </div>
    </td>
  </LinkTo>
  
  */
  {
    "id": "l4PDpfiF",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[],[[\"@route\",\"@models\"],[\"tax-period.show.bank-account.transaction.waiting\",[28,\"array\",[[24,[\"taxPeriod\",\"slug\"]],[24,[\"bankAccount\",\"id\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[5,\"p-badge-icon\",[],[[\"@icon\"],[[22,\"bankIcon\"]]]],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[1,[24,[\"bankAccount\",\"name\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"iban\"],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[1,[24,[\"bankAccount\",\"iban\"]],false],[9],[0,\"\\n      -\\n      \"],[7,\"span\",true],[8],[1,[24,[\"bankAccount\",\"currency\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/tax-period/bank-accounts/row/template.hbs"
    }
  });

  _exports.default = _default;
});