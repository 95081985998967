define("da-vinci/locales/tr/p-filter-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    doFilter: 'FİLTRELE',
    doFilterPhoneix: 'Filtrele',
    removeAll: 'TÜMÜNÜ KALDIR'
  };
  _exports.default = _default;
});