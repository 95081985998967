define("da-vinci/components/office-create-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PWarningBar @class="warning-bar" @data-test-office-create-warning={{true}}>
    <button
      class="show-create-office-modal-button"
      onclick={{onClick}}
      type="button"
      data-test-office-create-warning-button
    >
      {{t "createOfficeWarning.title"}}
    </button>
  </PWarningBar>
  
  */
  {
    "id": "A0XzNVet",
    "block": "{\"symbols\":[],\"statements\":[[5,\"p-warning-bar\",[],[[\"@class\"],[\"warning-bar\"]],{\"statements\":[[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"show-create-office-modal-button\"],[11,\"onclick\",[22,\"onClick\"]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"createOfficeWarning.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/office-create-warning/template.hbs"
    }
  });

  _exports.default = _default;
});