define("da-vinci/helpers/service-error-list-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.apiErrorHandler = apiErrorHandler;
  _exports.serviceErrorKeyListHandler = serviceErrorKeyListHandler;
  _exports.serviceErrorListHandler = serviceErrorListHandler;

  function serviceErrorListHandler(serviceErrors) {
    var errorList = '';
    serviceErrors.forEach(function (error) {
      if (error.translations instanceof Array) {
        error.translations.forEach(function (translation) {
          errorList += "".concat(translation, "<br/>");
        });
      } else {
        errorList += error.translations;
        errorList += '<br>';
      }
    });
    return errorList;
  }

  function serviceErrorKeyListHandler(serviceErrors) {
    var keyList = '';
    serviceErrors.forEach(function (error) {
      keyList += error.key;
    });
    return keyList;
  }

  function apiErrorHandler(error) {
    return error.errors ? serviceErrorListHandler(error.errors) : false;
  }
});