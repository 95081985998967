define("da-vinci/components/accountant-office-tab-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="nav-tab">
    <ul>
      <li class={{staffMemberTabClass}} data-test-staff-member-tab>
        <LinkTo @route="staff-member" data-test-staff-member-button={{true}}>
          {{t "accountantOffice.tabSelector.staffMember"}}
        </LinkTo>
      </li>
  
      <li class={{userInvitationTabClass}} data-test-user-invitation-tab>
        <LinkTo
          @route="user-invitations"
          data-test-user-invitation-button={{true}}
        >
          {{t "accountantOffice.tabSelector.userInvitation"}}
        </LinkTo>
      </li>
    </ul>
  </div>
  
  */
  {
    "id": "iFHxw+Q5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"nav-tab\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[11,\"class\",[22,\"staffMemberTabClass\"]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"staff-member\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"accountantOffice.tabSelector.staffMember\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"li\",true],[11,\"class\",[22,\"userInvitationTabClass\"]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"user-invitations\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"accountantOffice.tabSelector.userInvitation\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/accountant-office-tab-selector/template.hbs"
    }
  });

  _exports.default = _default;
});