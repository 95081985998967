define("da-vinci/components/company-matching-modal/destroy-company-matching-step/progress-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal
    @isOpen={{true}}
    @containerClassNames={{styleNamespace}}
    @dataTestModalName="data-test-destroy-company-matching-step-progress-modal"
    as |modal|
  >
    <modal.header>
      <h2 data-test-header>{{t "companyMatchingModalDestroyCompanyMatchingStep.progressModal.title"}}</h2>
    </modal.header>
  
    <modal.body>
      <p data-test-body-info>{{t "companyMatchingModalDestroyCompanyMatchingStep.progressModal.info"}}</p>
      <i
        class="fa fa-spinner fa-pulse loading-spinner"
        aria-label={{t "aria.label.loading"}}
        data-test-body-destroy-in-progress-spinner
      >
      </i>
      <p data-test-body-percent-complete>{{t "companyMatchingModalDestroyCompanyMatchingStep.progressModal.percentComplete" percent=percentAt htmlSafe=true}}</p>
    </modal.body>
  </PModal>
  
  */
  {
    "id": "Oz3LPBli",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@containerClassNames\",\"@dataTestModalName\"],[true,[22,\"styleNamespace\"],\"data-test-destroy-company-matching-step-progress-modal\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"companyMatchingModalDestroyCompanyMatchingStep.progressModal.title\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"companyMatchingModalDestroyCompanyMatchingStep.progressModal.info\"],null],false],[9],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-pulse loading-spinner\"],[11,\"aria-label\",[28,\"t\",[\"aria.label.loading\"],null]],[8],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"companyMatchingModalDestroyCompanyMatchingStep.progressModal.percentComplete\"],[[\"percent\",\"htmlSafe\"],[[24,[\"percentAt\"]],true]]],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/company-matching-modal/destroy-company-matching-step/progress-modal/template.hbs"
    }
  });

  _exports.default = _default;
});