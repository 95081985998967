define("da-vinci/components/tax-period/navigation-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="nav-tab">
    <ul>
      <li class={{recentActiveClass}}>
        <LinkTo
          @route="tax-period.index"
          @query={{hash taxPeriodFilter="recent"}}
          data-test-recent-tax-periods-button={{true}}
        >
          {{t "taxPeriodNavigationBar.recentPeriods"}}
        </LinkTo>
      </li>
      <li class={{pastActiveClass}}>
        <LinkTo
          @route="tax-period.index"
          @query={{hash taxPeriodFilter="past"}}
          data-test-past-tax-periods-button={{true}}
        >
          {{t "taxPeriodNavigationBar.pastPeriods"}}
        </LinkTo>
      </li>
    </ul>
  </div>
  
  */
  {
    "id": "tlvlb34C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"nav-tab\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[11,\"class\",[22,\"recentActiveClass\"]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"tax-period.index\",[28,\"hash\",null,[[\"taxPeriodFilter\"],[\"recent\"]]]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"taxPeriodNavigationBar.recentPeriods\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[11,\"class\",[22,\"pastActiveClass\"]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"tax-period.index\",[28,\"hash\",null,[[\"taxPeriodFilter\"],[\"past\"]]]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"taxPeriodNavigationBar.pastPeriods\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/tax-period/navigation-bar/template.hbs"
    }
  });

  _exports.default = _default;
});