define("da-vinci/helpers/currency-name", ["exports", "ui-library/helpers/currency-name"], function (_exports, _currencyName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "currencyName", {
    enumerable: true,
    get: function get() {
      return _currencyName.currencyName;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _currencyName.default;
    }
  });
});