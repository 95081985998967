define("da-vinci/components/process-status-tab-selector/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-prop-types", "ember-decorators/object"], function (_exports, _applyDecoratedDescriptor2, _emberPropTypes, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  var ACTIVE_TAB_CLASS = 'active';

  var _default = Ember.Component.extend((_dec = (0, _object.computed)('tab'), (_obj = {
    'data-test-process-status-tab-selector': true,
    propTypes: {
      tab: _emberPropTypes.PropTypes.string.isRequired,
      documentType: _emberPropTypes.PropTypes.string.isRequired,
      meta: _emberPropTypes.PropTypes.object.isRequired
    },
    tabClasses: function tabClasses(tab) {
      return {
        waiting: tab === 'waiting' ? ACTIVE_TAB_CLASS : '',
        ready: tab === 'ready' ? ACTIVE_TAB_CLASS : '',
        done: tab === 'done' ? ACTIVE_TAB_CLASS : '',
        yield: tab === 'yield' ? ACTIVE_TAB_CLASS : ''
      };
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "tabClasses", [_dec], Object.getOwnPropertyDescriptor(_obj, "tabClasses"), _obj)), _obj)));

  _exports.default = _default;
});