define("da-vinci/instance-initializers/gtm", ["exports", "ember-cli-google-tag-manager/instance-initializers/gtm"], function (_exports, _gtm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _gtm.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _gtm.initialize;
    }
  });
});