define("da-vinci/utils/attachment", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-decorators/object"], function (_exports, _applyDecoratedDescriptor2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  var _default = Ember.Object.extend(Ember.Evented, (_dec = (0, _object.computed)('tempFileUrl', 'currentUrl'), _dec2 = (0, _object.computed)('fileUrl', 'isRemoved'), (_obj = {
    currentUrl: null,
    newFileName: null,
    tempFileUrl: null,
    isRemoved: false,
    isProcessing: false,
    isUploading: false,
    uploadProgress: 0,
    hasUploadError: false,
    showProgress: Ember.computed.or('isUploading', 'hasUploadError'),
    fileUrl: function fileUrl(tempFileUrl, currentUrl) {
      // if the user has uploaded a file always display that
      // so that if an existing attachment is changed, we point to the new file
      return tempFileUrl || currentUrl;
    },
    hasFile: function hasFile(fileUrl, isRemoved) {
      return !Ember.isEmpty(fileUrl) && !isRemoved;
    },
    changeFile: function changeFile(newFileName, tempFileUrl) {
      this.setProperties({
        newFileName: newFileName,
        tempFileUrl: tempFileUrl,
        isRemoved: false
      });
    },
    remove: function remove() {
      this.setProperties({
        newFileName: null,
        tempFileUrl: null,
        isRemoved: true
      });
    },
    cancelUpload: function cancelUpload() {
      this.trigger('cancelUploadRequested');
    },
    rollback: function rollback() {
      // weirdly when called inside willTransition hook of a route
      // instantly triggers the transition so should be the last call
      // made in the hook to prevent unreachable blocks
      this.setProperties({
        tempFileUrl: null,
        newFileName: null,
        isRemoved: Ember.isBlank(this.currentUrl)
      });
      this.cancelUpload();
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "fileUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "fileUrl"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "hasFile", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hasFile"), _obj)), _obj)));

  _exports.default = _default;
});