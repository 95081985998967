define("da-vinci/utils/boot-full-story", ["exports", "da-vinci/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = bootFullStory;

  function bootFullStory(user) {
    if (_environment.default.environment !== 'production' || !window.FS) {
      return;
    } // FS comes from google tag manager


    window.FS.identify(user.get('id'), {
      email: user.get('email')
    });
  }
});