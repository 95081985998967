define("da-vinci/components/import-invoice/create-invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PIndexHeader>
    <div class="index-header-wrapper">
      {{#if hasError}}
        <div data-test-error-while-creating-invoices="true">
          <p class="index-header-title">{{t "importInvoices.createInvoices.errorWhileCreating"}}</p>
        </div>
        <PButton
          @onClick={{uploadButtonTrigger}}
          @data-test-fix-reupload-button={{true}}>
          {{t "importInvoices.fixAndReuploadButton"}}
        </PButton>
      {{else}}
        <div>
          <p class="index-header-title">{{t "importInvoices.createInvoices.invoicesCreated"}}</p>
        </div>
        <PButton
          @onClick={{uploadButtonTrigger}}
          @data-test-upload-new-button={{true}}>
          {{t "importInvoices.uploadNewButton"}}
        </PButton>
      {{/if}}
    </div>
  </PIndexHeader>
  <InvoiceStatusCounts
        @invoiceStatuses={{invoiceStatuses}}
        @isCreated={{true}}
        @data-test-invoice-status-counts={{true}} />
  
  <ImportInvoice::ImportedInvoicesTable
      @invoices={{invoices}}
      @hasError={{hasError}}
      @data-test-imported-invoices-table={{true}} />
  
  */
  {
    "id": "daATsO+i",
    "block": "{\"symbols\":[],\"statements\":[[5,\"p-index-header\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"index-header-wrapper\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"index-header-title\"],[8],[1,[28,\"t\",[\"importInvoices.createInvoices.errorWhileCreating\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[5,\"p-button\",[],[[\"@onClick\"],[[22,\"uploadButtonTrigger\"]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"importInvoices.fixAndReuploadButton\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"index-header-title\"],[8],[1,[28,\"t\",[\"importInvoices.createInvoices.invoicesCreated\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[5,\"p-button\",[],[[\"@onClick\"],[[22,\"uploadButtonTrigger\"]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"importInvoices.uploadNewButton\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"invoice-status-counts\",[],[[\"@invoiceStatuses\",\"@isCreated\"],[[22,\"invoiceStatuses\"],true]]],[0,\"\\n\\n\"],[5,\"import-invoice/imported-invoices-table\",[],[[\"@invoices\",\"@hasError\"],[[22,\"invoices\"],[22,\"hasError\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/import-invoice/create-invoices/template.hbs"
    }
  });

  _exports.default = _default;
});