define("da-vinci/utils/boot-intercom", ["exports", "da-vinci/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = bootIntercom;

  function bootIntercom(user, parasutCompanies) {
    if (_environment.default.environment !== 'production' || !window.Intercom) {
      return;
    }

    window.Intercom('boot', {
      app_id: _environment.default.intercomApiKey,
      email: user.get('email'),
      companies: companies(parasutCompanies)
    });
  }

  function companies(parasutCompanies) {
    parasutCompanies.map(function (parasutCompany) {
      return {
        name: parasutCompany.name,
        atlas_company_id: parasutCompany.id,
        id: parasutCompany.prstId
      };
    });
  }
});