define("da-vinci/tax-period/show/bank-account/transaction/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FILTER_DEFINITIONS = void 0;
  var FILTER_DEFINITIONS = {
    txDate: {
      componentName: 'filter/document-date-filter',
      displayNameKey: 'documentDateFilter.title',
      displayName: ''
    }
  };
  _exports.FILTER_DEFINITIONS = FILTER_DEFINITIONS;
});