define("da-vinci/locales/tr/currency-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    trl: 'Türk Lirası',
    usd: 'Amerikan Doları',
    eur: 'Euro',
    gbp: 'İngiliz Sterlini'
  };
  _exports.default = _default;
});