define("da-vinci/components/invoice-excel-export-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showError}}
    <InvoiceExcelExportModal::Error
      @onClose={{onClose}}
      @errorDetailKey={{errorDetailKey}}
    />
  {{else if showTimeout}}
    <InvoiceExcelExportModal::Timeout @onClose={{onClose}} />
  {{else if showSuccess}}
    <InvoiceExcelExportModal::Success
      @onClose={{onClose}}
      @downloadLink={{downloadLink}}
    />
  {{else}}
    <InvoiceExcelExportModal::InProgress @onClose={{onClose}} />
  {{/if}}
  
  */
  {
    "id": "8nnlo6/Z",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showError\"]]],null,{\"statements\":[[0,\"  \"],[5,\"invoice-excel-export-modal/error\",[],[[\"@onClose\",\"@errorDetailKey\"],[[22,\"onClose\"],[22,\"errorDetailKey\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showTimeout\"]]],null,{\"statements\":[[0,\"  \"],[5,\"invoice-excel-export-modal/timeout\",[],[[\"@onClose\"],[[22,\"onClose\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showSuccess\"]]],null,{\"statements\":[[0,\"  \"],[5,\"invoice-excel-export-modal/success\",[],[[\"@onClose\",\"@downloadLink\"],[[22,\"onClose\"],[22,\"downloadLink\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"invoice-excel-export-modal/in-progress\",[],[[\"@onClose\"],[[22,\"onClose\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invoice-excel-export-modal/template.hbs"
    }
  });

  _exports.default = _default;
});