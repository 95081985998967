define("da-vinci/utils/boot-salesforce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = bootSalesForce;

  /* eslint-disable max-len */
  function bootSalesForce(setFirstTime, userEmail, ParasutCompanyId, UserId, service) {
    // set salesforce test enviroments as a defaults
    var initESW = function initESW(gslbBaseURL) {
      window.embedded_svc.settings.displayHelpButton = false; //Veya yanlış

      window.embedded_svc.settings.language = 'tr'; //Örneğin 'en' veya 'en-US’ girin

      window.embedded_svc.settings.defaultMinimizedText = 'Canlı Destek'; //(Bir Uzmanla Sohbet Varsayılanına Döner)

      window.embedded_svc.settings.disabledMinimizedText = 'Çevrimdışı'; //(Temsilci Çevrim Dışı Varsayılanına Döner)

      window.embedded_svc.settings.loadingText = 'Yükleniyor'; //(Yüklüyor Varsayılanına Döner)
      //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Ziyaretçilerin sohbet oturumu sırasında alt alanlarda dolaşabilmesi için dağıtımınızın alanını ayarlar)
      // Sohbet için Ayarlar
      //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
      // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
      // Returns a valid button ID.
      //};
      //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sohbet öncesi form alanlarının otomatik doldurulmasını ayarlar
      //embedded_svc.settings.fallbackRouting = []; //Düğme kimlikleri, kullanıcı kimlikleri veya userId_buttonId’den oluşan bir dizi

      window.embedded_svc.settings.offlineSupportMinimizedText = 'DESTEK'; //(Varsayılan olarak Bize Ulaşın’a Geçer)

      window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      window.embedded_svc.settings.entryFeature = 'LiveAgent';
      window.embedded_svc.init('https://parasut.my.salesforce.com', 'https://parasut.my.salesforce-sites.com/chat', gslbBaseURL, '00D0O000000qYJM', 'Atlas_Chat', {
        baseLiveAgentContentURL: 'https://c.la2-c2-fra.salesforceliveagent.com/content',
        deploymentId: '5721r000000H16l',
        buttonId: '5737T000000XZB0',
        baseLiveAgentURL: 'https://d.la2-c2-fra.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7T000000PAtLUAW_1870d61728e',
        isOfflineSupportEnabled: true
      });
    };

    if (!window.embedded_svc && setFirstTime) {
      var s = document.createElement('script');
      s.setAttribute('src', 'https://parasut.my.salesforce.com/embeddedservice/5.0/esw.min.js');

      s.onload = function () {
        initESW(null);
      };

      document.body.appendChild(s);
    } else if (setFirstTime) {
      initESW('https://service.force.com');
    }

    window.embedded_svc.settings.extraPrechatInfo = [{
      'entityName': 'Contact',
      'showOnCreate': true,
      'linkToEntityName': 'Case',
      'linkToEntityField': 'ContactId',
      'saveToTranscript': 'ContactId',
      'entityFieldMaps': [{
        'isExactMatch': true,
        'fieldName': 'Email',
        'doCreate': false,
        'doFind': true,
        'label': 'Email'
      }, {
        'isExactMatch': true,
        'fieldName': 'UsedApp__c',
        'doCreate': false,
        'doFind': true,
        'label': 'UsedApp'
      }, {
        'isExactMatch': true,
        'fieldName': 'Atlas_User_Id__c',
        'doCreate': false,
        'doFind': true,
        'label': 'Atlas_User_Id'
      }, {
        'isExactMatch': true,
        'fieldName': 'Company_Atlas_Id__c',
        'doCreate': false,
        'doFind': true,
        'label': 'CompanyParasutId'
      }]
    }, {
      'entityName': 'Account',
      'showOnCreate': true,
      'saveToTranscript': 'AccountId',
      'entityFieldMaps': [{
        'isExactMatch': true,
        'fieldName': 'Company_Atlas_Id__c',
        'doCreate': false,
        'doFind': true,
        'label': 'CompanyParasutId'
      }]
    }, {
      'entityName': 'Case',
      'showOnCreate': true,
      'saveToTranscript': 'CaseId',
      'entityFieldMaps': [{
        'isExactMatch': false,
        'fieldName': 'Subject',
        'doCreate': true,
        'doFind': false,
        'label': 'Sub'
      }, {
        'isExactMatch': false,
        'fieldName': 'RecordTypeId',
        'doCreate': true,
        'doFind': false,
        'label': 'RecordTypeId'
      }, {
        'isExactMatch': false,
        'fieldName': 'Priority',
        'doCreate': true,
        'doFind': false,
        'label': 'Priority'
      }, {
        'isExactMatch': false,
        'fieldName': 'Origin',
        'doCreate': true,
        'doFind': false,
        'label': 'ori'
      }]
    }];
    window.embedded_svc.settings.extraPrechatFormDetails = [{
      'label': 'Email',
      'value': userEmail,
      'displayToAgent': true
    }, {
      'label': 'CompanyParasutId',
      'value': ParasutCompanyId,
      'displayToAgent': true
    }, {
      'label': 'Atlas_User_Id',
      'value': UserId,
      'displayToAgent': true
    }, {
      'label': 'UsedApp',
      'value': 'AtlasUser',
      'displayToAgent': true
    }, {
      'label': 'Sub',
      'value': '-',
      'displayToAgent': true
    }, {
      'label': 'ori',
      'value': 'Chat',
      'displayToAgent': true
    }, {
      'label': 'RecordTypeId',
      'value': '0121r000000eI7tAAE',
      'displayToAgent': true
    }, {
      'label': 'Priority',
      'value': 'Medium',
      'displayToAgent': true
    }];
    window.embedded_svc.addEventHandler('onHelpButtonClick', function () {
      service.set('isOpenChat', true);
    });
    window.embedded_svc.addEventHandler('afterDestroy', function () {
      service.set('isOpenChat', false);
    });
  }
});