define("da-vinci/components/invoice/basic-accounting-type/invoice-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PTable @items={{items}} @columnWidths={{columnWidths}} as |table|>
    <table.header as |header|>
      <header.column>
      </header.column>
      <header.column>
      </header.column>
      <header.column>
        {{t "basicAccountingTypeInvoiceTable.header.description"}}
      </header.column>
  
      <header.column>
        {{t "basicAccountingTypeInvoiceTable.header.totalTax"}}
      </header.column>
  
      <header.column>
        {{t "basicAccountingTypeInvoiceTable.header.netTotal"}}
      </header.column>
  
      {{!-- empty header for buttons --}}
      <header.column>
      </header.column>
    </table.header>
    <div class="table-container">
      <table.body as |item|>
        <Invoice::BasicAccountingType::ListRow
          @invoice={{item}}
          @onCancelRowTask={{onCancelRowTask}}
          @pageProcessStatus={{pageProcessStatus}}
          @onApproveRowTask={{onApproveRowTask}}
          @data-test-invoice-basic-accounting-type-list-row={{true}}
        />
      </table.body>
    </div>
  </PTable>
  
  */
  {
    "id": "KSG5Mrii",
    "block": "{\"symbols\":[\"table\",\"item\",\"header\"],\"statements\":[[5,\"p-table\",[],[[\"@items\",\"@columnWidths\"],[[22,\"items\"],[22,\"columnWidths\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"basicAccountingTypeInvoiceTable.header.description\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"basicAccountingTypeInvoiceTable.header.totalTax\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"basicAccountingTypeInvoiceTable.header.netTotal\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[0,\"    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[3]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"table-container\"],[8],[0,\"\\n    \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"invoice/basic-accounting-type/list-row\",[],[[\"@invoice\",\"@onCancelRowTask\",\"@pageProcessStatus\",\"@onApproveRowTask\"],[[23,2,[]],[22,\"onCancelRowTask\"],[22,\"pageProcessStatus\"],[22,\"onApproveRowTask\"]]]],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invoice/basic-accounting-type/invoice-table/template.hbs"
    }
  });

  _exports.default = _default;
});