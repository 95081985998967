define("da-vinci/components/company-matching-modal/completion-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal
    @isOpen={{true}}
    @dataTestModalName="completion-step"
    @wrapperClassNames={{styleNamespace}}
    as |modal|
  >
    <modal.header>
      <div class="modal-header">
        <div>
          <small>{{parasutCompany.name}}</small>
          <h1>{{t "companyMatchingModalCompletionStep.title"}}</h1>
        </div>
        <div></div>
      </div>
    </modal.header>
  
    <modal.body @class="modal-body-wrapper">
      <div class="modal-body">
        <h2 data-test-body-title>{{t "companyMatchingModalCompletionStep.bodyTitle"}}</h2>
        <p data-test-body-info>{{t "companyMatchingModalCompletionStep.bodyInfo"}}</p>
        {{!-- {{#p-content-info data-test-body-warning=true class="content-info"}}
          {{t 'companyMatchingModalCompletionStep.bodyWarning'}}
        {{/p-content-info}} --}}
      </div>
    </modal.body>
  
    <modal.footer>
      <PButton
        @type="outline"
        @size="small"
        @onClick={{onCancel}}
        @data-test-close-button={{true}}
      >
        {{t "companyMatchingModalCompletionStep.buttons.close"}}
      </PButton>
      <PButton
        @size="small"
        @color="primary"
        @onClick={{onSuccess}}
        @data-test-refresh-button={{true}}
      >
        {{t "companyMatchingModalCompletionStep.buttons.refreshPage"}}
      </PButton>
    </modal.footer>
  </PModal>
  
  */
  {
    "id": "Cye+D7ZC",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@dataTestModalName\",\"@wrapperClassNames\"],[true,\"completion-step\",[22,\"styleNamespace\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"small\",true],[8],[1,[24,[\"parasutCompany\",\"name\"]],false],[9],[0,\"\\n        \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"companyMatchingModalCompletionStep.title\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[\"@class\"],[\"modal-body-wrapper\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"companyMatchingModalCompletionStep.bodyTitle\"],null],false],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"companyMatchingModalCompletionStep.bodyInfo\"],null],false],[9],[0,\"\\n\"],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-button\",[],[[\"@type\",\"@size\",\"@onClick\"],[\"outline\",\"small\",[22,\"onCancel\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"companyMatchingModalCompletionStep.buttons.close\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"p-button\",[],[[\"@size\",\"@color\",\"@onClick\"],[\"small\",\"primary\",[22,\"onSuccess\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"companyMatchingModalCompletionStep.buttons.refreshPage\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/company-matching-modal/completion-step/template.hbs"
    }
  });

  _exports.default = _default;
});