define("da-vinci/locales/tr/p-date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rangeStart: 'BAŞLANGIÇ TARİHİ',
    rangeEnd: 'BİTİŞ TARİHİ',
    quickChoose: 'HIZLI SEÇİM',
    thisMonth: 'BU AY',
    previousMonth: 'GEÇEN AY',
    thisQuarter: 'BU ÇEYREK',
    previousQuarter: 'GEÇEN ÇEYREK',
    thisYear: 'BU YIL',
    previousYear: 'GEÇEN YIL',
    apply: 'UYGULA',
    cancel: 'VAZGEÇ'
  };
  _exports.default = _default;
});