define("da-vinci/locales/tr/ui-library-translations", ["exports", "da-vinci/locales/tr/currency-name", "da-vinci/locales/tr/date-util", "da-vinci/locales/tr/p-date-picker", "da-vinci/locales/tr/p-date-picker-with-presets", "da-vinci/locales/tr/p-date-range-picker", "da-vinci/locales/tr/p-filter-collection-with-checkboxes", "da-vinci/locales/tr/p-filter-collection-with-date", "da-vinci/locales/tr/p-filter-container", "da-vinci/locales/tr/p-list-body", "da-vinci/locales/tr/p-list-aggregates", "da-vinci/locales/tr/p-list-toggle", "da-vinci/locales/tr/p-month-picker", "da-vinci/locales/tr/p-one-way-autocomplete", "da-vinci/locales/tr/p-one-way-exchange-rate", "da-vinci/locales/tr/p-pagination", "da-vinci/locales/tr/p-pagination-info", "da-vinci/locales/tr/p-search-box", "da-vinci/locales/tr/p-transaction"], function (_exports, _currencyName, _dateUtil, _pDatePicker, _pDatePickerWithPresets, _pDateRangePicker, _pFilterCollectionWithCheckboxes, _pFilterCollectionWithDate, _pFilterContainer, _pListBody, _pListAggregates, _pListToggle, _pMonthPicker, _pOneWayAutocomplete, _pOneWayExchangeRate, _pPagination, _pPaginationInfo, _pSearchBox, _pTransaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    currencyName: _currencyName.default,
    dateUtil: _dateUtil.default,
    pDatePicker: _pDatePicker.default,
    pDatePickerWithPresets: _pDatePickerWithPresets.default,
    pDateRangePicker: _pDateRangePicker.default,
    pFilterCollectionWithCheckboxes: _pFilterCollectionWithCheckboxes.default,
    pFilterCollectionWithDate: _pFilterCollectionWithDate.default,
    pFilterContainer: _pFilterContainer.default,
    pListBody: _pListBody.default,
    pListAggregates: _pListAggregates.default,
    pListToggle: _pListToggle.default,
    pMonthPicker: _pMonthPicker.default,
    pPagination: _pPagination.default,
    pPaginationInfo: _pPaginationInfo.default,
    pOneWayExchangeRate: _pOneWayExchangeRate.default,
    pOneWayAutoComplete: _pOneWayAutocomplete.default,
    pSearchBox: _pSearchBox.default,
    pTransaction: _pTransaction.default
  };
  _exports.default = _default;
});