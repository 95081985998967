define("da-vinci/components/luca-accounting-type-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <POneWaySelect
    @selected={{selectedOption}}
    @options={{options}}
    @placeholder={{t "lucaAccountingTypeSelect.placeholder"}}
    @onchange={{action "select"}}
    as |option|
  >
    {{option.label}}
  </POneWaySelect>
  
  */
  {
    "id": "DCd058qp",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[5,\"p-one-way-select\",[],[[\"@selected\",\"@options\",\"@placeholder\",\"@onchange\"],[[22,\"selectedOption\"],[22,\"options\"],[28,\"t\",[\"lucaAccountingTypeSelect.placeholder\"],null],[28,\"action\",[[23,0,[]],\"select\"],null]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/luca-accounting-type-select/template.hbs"
    }
  });

  _exports.default = _default;
});