define("da-vinci/components/filter/accommodation-tax-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PFilterItem
    @filter={{filter}}
    @onCloseFilter={{action "cancel"}}
    @isOpen={{isOpen}}
    as |dropdown|
  >
    {{!-- We were getting 'calling set on destroyed object' error when we try to remove filter
    via close button without opening dropdown. That's because when try to close it trigger
    also dropdown and try to open it after destroying it. We put 'eventType="click"' to
    dropdown.trigger and get rid of 'href="#"' inside <a> tag and change action to onclick.
    This changes resolves our error problem --}}
    <dropdown.trigger @eventType="click" @class={{filter.triggerClass}}>
      {{triggerText}}
      {{#if filter.isActive}}
        <a
          href="javascript:"
          role="button"
          onclick={{action "removeFilter"}}
          data-test-remove-filter
        >
          <i class="fa fa-times"></i>
        </a>
      {{/if}}
    </dropdown.trigger>
    <dropdown.content>
      <PFilterCollectionWithCheckboxes
        @filter={{filter}}
        @showApplyButton={{filter.hasChanged}}
        @onApplyFilters={{action "apply"}}
        @onCancel={{action "closeAndCancel" dropdown}}
        @title={{t "accommodationTaxFilter.title"}}
        as |collection|
      >
        <collection.item @value="0">{{t "accommodationTaxFilter.zero"}}</collection.item>
        <collection.item @value="2">{{t "accommodationTaxFilter.two"}}</collection.item>
      </PFilterCollectionWithCheckboxes>
    </dropdown.content>
  </PFilterItem>
  
  */
  {
    "id": "to4LS0gI",
    "block": "{\"symbols\":[\"dropdown\",\"collection\"],\"statements\":[[5,\"p-filter-item\",[],[[\"@filter\",\"@onCloseFilter\",\"@isOpen\"],[[22,\"filter\"],[28,\"action\",[[23,0,[]],\"cancel\"],null],[22,\"isOpen\"]]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[6,[23,1,[\"trigger\"]],[],[[\"@eventType\",\"@class\"],[\"click\",[24,[\"filter\",\"triggerClass\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"triggerText\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"filter\",\"isActive\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",true],[10,\"href\",\"javascript:\"],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"removeFilter\"],null]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-filter-collection-with-checkboxes\",[],[[\"@filter\",\"@showApplyButton\",\"@onApplyFilters\",\"@onCancel\",\"@title\"],[[22,\"filter\"],[24,[\"filter\",\"hasChanged\"]],[28,\"action\",[[23,0,[]],\"apply\"],null],[28,\"action\",[[23,0,[]],\"closeAndCancel\",[23,1,[]]],null],[28,\"t\",[\"accommodationTaxFilter.title\"],null]]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"item\"]],[],[[\"@value\"],[\"0\"]],{\"statements\":[[1,[28,\"t\",[\"accommodationTaxFilter.zero\"],null],false]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,2,[\"item\"]],[],[[\"@value\"],[\"2\"]],{\"statements\":[[1,[28,\"t\",[\"accommodationTaxFilter.two\"],null],false]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/filter/accommodation-tax-filter/template.hbs"
    }
  });

  _exports.default = _default;
});