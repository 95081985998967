define("da-vinci/utils/decamelize-keys", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function decamelizeKeys(input) {
    if (Ember.isArray(input)) {
      return decamelizeArray(input);
    } else if ((0, _typeof2.default)(input) === 'object') {
      return decamelizeProperties(input);
    }

    return input;
  }

  function decamelizeArray(array) {
    return array.map(function (item) {
      return decamelizeKeys(item);
    });
  }

  function decamelizeProperties(input) {
    var decamelized = {}; // below disabled added because of an eslint bug https://github.com/eslint/eslint/issues/12117
    // eslint-disable-next-line no-unused-vars

    for (var key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        var decamelizedName = key.decamelize();
        var value = input[key];

        if (value === null) {
          decamelized[decamelizedName] = null;
        } else if ((0, _typeof2.default)(value) === 'object') {
          decamelized[decamelizedName] = decamelizeKeys(value);
        } else {
          decamelized[decamelizedName] = value;
        }
      }
    }

    return decamelized;
  }

  var _default = decamelizeKeys;
  _exports.default = _default;
});