define("da-vinci/components/invoice-excel-export-modal/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal
    @isOpen={{true}}
    @dataTestModalName="error"
    @wrapperClassNames={{styleNamespace}}
    as |modal|
  >
    <modal.header>
      <h2>{{t "invoiceExcelExportModal.error.title"}}</h2>
    </modal.header>
    <modal.body @class="modal-body-wrapper">
      <div class="badge-container">
        <PBadgeIcon
          @icon="fa-file-excel-o"
          @shape="circle"
          @overlayIcon="fa fa-times"
        />
      </div>
      <p data-test-body-info>
        {{#if errorDetailKey}}
          {{t (concat "invoiceExcelExportModal.error.errors." errorDetailKey)}}
        {{else}}
          {{t "invoiceExcelExportModal.error.errors.unknown"}}
        {{/if}}
      </p>
    </modal.body>
    <modal.footer>
      <PButton
        @type="outline"
        @size="small"
        @onClick={{onClose}}
        @data-test-close-button={{true}}
      >
        {{t "invoiceExcelExportModal.error.close"}}
      </PButton>
    </modal.footer>
  </PModal>
  
  */
  {
    "id": "faY3xzKd",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@dataTestModalName\",\"@wrapperClassNames\"],[true,\"error\",[22,\"styleNamespace\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"invoiceExcelExportModal.error.title\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[\"@class\"],[\"modal-body-wrapper\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"badge-container\"],[8],[0,\"\\n      \"],[5,\"p-badge-icon\",[],[[\"@icon\",\"@shape\",\"@overlayIcon\"],[\"fa-file-excel-o\",\"circle\",\"fa fa-times\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"errorDetailKey\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[[28,\"concat\",[\"invoiceExcelExportModal.error.errors.\",[24,[\"errorDetailKey\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"invoiceExcelExportModal.error.errors.unknown\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-button\",[],[[\"@type\",\"@size\",\"@onClick\"],[\"outline\",\"small\",[22,\"onClose\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"invoiceExcelExportModal.error.close\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invoice-excel-export-modal/error/template.hbs"
    }
  });

  _exports.default = _default;
});