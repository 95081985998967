define("da-vinci/components/staff-members/staff-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PTable @items={{staffMembers}} as |table|>
    <table.header as |header|>
      <header.column>
        {{t "staffMembers.staffTable.username"}}
      </header.column>
      <header.column>
        {{t "staffMembers.staffTable.assignedTaxpayers"}}
      </header.column>
    </table.header>
    <div class="table-container">
      <table.body as |staffMember|>
        <StaffMembers::ListRow
          @staffMember={{staffMember}}
          @data-test-staff-members-list-row={{true}}
        />
      </table.body>
    </div>
  </PTable>
  
  */
  {
    "id": "zvXxBMbA",
    "block": "{\"symbols\":[\"table\",\"staffMember\",\"header\"],\"statements\":[[5,\"p-table\",[],[[\"@items\"],[[22,\"staffMembers\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"staffMembers.staffTable.username\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,3,[\"column\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"staffMembers.staffTable.assignedTaxpayers\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[3]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"table-container\"],[8],[0,\"\\n    \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"staff-members/list-row\",[],[[\"@staffMember\"],[[23,2,[]]]]],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/staff-members/staff-table/template.hbs"
    }
  });

  _exports.default = _default;
});