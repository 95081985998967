define("da-vinci/locales/tr/date-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    thisMonth: 'BU AY'
  };
  _exports.default = _default;
});