define("da-vinci/tax-period/show/purchase-bill/receipt-export-group/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{page-title (t "taxPeriodShow.pageTitles.receiptExportGroup.index")}}
  <AppHeader
    @pageDescription=""
    @previousPage={{action "previousPage"}}
    @breadcrumb={{component "p-breadcrumb"}}
    @taxPeriodSlug={{taxPeriodSlug}}
    @invoiceType={{invoiceType}}
    @accountingType={{accountingType}}
  >
    <PurchaseBillTabSelector
      @tab={{tab}}
      @waitingCount={{meta.waiting}}
      @readyCount={{meta.ready}}
      @doneCount={{meta.done}}
    />
  </AppHeader>
  
  {{#if receiptExportGroups.length}}
    <ReceiptExportGroupTable
      @items={{receiptExportGroups}}
      @invoiceType={{invoiceType}}
    />
  {{else}}
    <TableEmptyState>
      {{t "receiptExportGroupTable.emptyText" htmlSafe=true}}
    </TableEmptyState>
  {{/if}}
  
  */
  {
    "id": "D6B4XNc5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"taxPeriodShow.pageTitles.receiptExportGroup.index\"],null]],null],false],[0,\"\\n\"],[5,\"app-header\",[],[[\"@pageDescription\",\"@previousPage\",\"@breadcrumb\",\"@taxPeriodSlug\",\"@invoiceType\",\"@accountingType\"],[\"\",[28,\"action\",[[23,0,[]],\"previousPage\"],null],[28,\"component\",[\"p-breadcrumb\"],null],[22,\"taxPeriodSlug\"],[22,\"invoiceType\"],[22,\"accountingType\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"purchase-bill-tab-selector\",[],[[\"@tab\",\"@waitingCount\",\"@readyCount\",\"@doneCount\"],[[22,\"tab\"],[24,[\"meta\",\"waiting\"]],[24,[\"meta\",\"ready\"]],[24,[\"meta\",\"done\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"receiptExportGroups\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[5,\"receipt-export-group-table\",[],[[\"@items\",\"@invoiceType\"],[[22,\"receiptExportGroups\"],[22,\"invoiceType\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"table-empty-state\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"receiptExportGroupTable.emptyText\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/tax-period/show/purchase-bill/receipt-export-group/index/template.hbs"
    }
  });

  _exports.default = _default;
});