define("da-vinci/helpers/company-matching-modal-navbar-item-class", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.companyMatchingModalNavbarItemClass = companyMatchingModalNavbarItemClass;
  _exports.default = void 0;

  function companyMatchingModalNavbarItemClass(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 4),
        STATES = _ref2[0],
        currentState = _ref2[1],
        itemState = _ref2[2],
        latestStep = _ref2[3];

    if (STATES[currentState] === STATES[itemState]) {
      return 'is-active';
    } else if (STATES[latestStep] > STATES[itemState]) {
      return 'is-done';
    } else if (STATES[currentState] > STATES[itemState]) {
      return 'is-done';
    } else {
      return 'is-disabled';
    }
  }

  var _default = Ember.Helper.helper(companyMatchingModalNavbarItemClass);

  _exports.default = _default;
});