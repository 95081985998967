define("da-vinci/utils/get-filters-from-query-params", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getFiltersFromQueryParams = getFiltersFromQueryParams;

  function _parseValue(value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  function getFiltersFromQueryParams(queryParams) {
    var filters = {};
    Object.entries(queryParams.filter).forEach(function (filter) {
      var _filter = (0, _slicedToArray2.default)(filter, 2),
          key = _filter[0],
          value = _filter[1];

      if (value) {
        filters[Ember.String.underscore(key)] = _parseValue(value);
      }
    });
    return filters;
  }
});