define("da-vinci/components/file-upload-button/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-decorators/object", "ui-library/components/p-button/component", "ember-component-css/pod-names", "da-vinci/components/file-upload-button/template"], function (_exports, _applyDecoratedDescriptor2, _object, _component, _podNames, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  var AllowedMimeTypes = [{
    key: 'img',
    title: 'İmaj dosyaları',
    extensions: 'jpg,jpeg,gif,png'
  }, {
    key: 'xls',
    title: 'Excel dosyaları',
    extensions: 'xls,xlsx'
  }, {
    key: 'doc',
    title: 'Metin dosyaları',
    extensions: 'doc,docx,rtf,txt'
  }, {
    key: 'ppt',
    title: 'PowerPoint dosyaları',
    extensions: 'ppt,pptx'
  }, {
    key: 'pdf',
    title: 'Pdf dosyaları',
    extensions: 'pdf'
  }];

  var _default = _component.default.extend((_dec = (0, _object.observes)('file'), _dec2 = (0, _object.computed)('allowTypes'), (_obj = {
    layout: _template.default,
    file: null,
    allowTypes: null,
    uploader: null,
    attachmentUploader: Ember.inject.service(),
    'data-test-upload-button': true,
    classNames: [_podNames.default['p-button']],
    attachmentType: null,
    // actions
    onHandleImportWorker: null,
    onHandleUploadError: null,
    onHandleIsUploading: null,
    didInsertElement: function didInsertElement() {
      var mimeTypes = this.mimeTypes;
      var file = this.file;
      (false && !(mimeTypes.length) && Ember.assert('allowTypes property not assigned or invalid', mimeTypes.length));
      (false && !(file) && Ember.assert('file property not assigned', file));
      this.set('uploader', this.attachmentUploader.start({
        attachment: this.file,
        trigger: this.element,
        mimeTypes: mimeTypes,
        attachmentType: this.attachmentType,
        handleImportWorker: this.onHandleImportWorker,
        handleUploadError: this.onHandleUploadError,
        handleIsUploading: this.onHandleIsUploading
      }));
    },
    updateUploaderAttachment: function updateUploaderAttachment() {
      var _this = this;

      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.once(function () {
        if (_this.uploader) {
          _this.set('uploader.attachment', _this.file);
        }
      });
    },
    mimeTypes: function mimeTypes(allowTypes) {
      var options = allowTypes || '';
      return AllowedMimeTypes.filter(function (item) {
        return options.indexOf(item.key) >= 0;
      }).map(function (item) {
        return {
          title: item.title,
          extensions: item.extensions
        };
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.uploader.makeOrphan();
    },
    click: function click() {
      if (this.hasEdgeFix && /Edge\/\d+/.test(navigator.userAgent)) {
        this.element.querySelector('input[type="file"]').eq(0).trigger('click');
      }
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "updateUploaderAttachment", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateUploaderAttachment"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "mimeTypes", [_dec2], Object.getOwnPropertyDescriptor(_obj, "mimeTypes"), _obj)), _obj)));

  _exports.default = _default;
});