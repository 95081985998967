define("da-vinci/locales/tr/p-search-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    activePlaceholder: 'İçerisinde ara...',
    blankPlaceholder: 'Ara...'
  };
  _exports.default = _default;
});