define("da-vinci/locales/tr/p-filter-collection-with-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    apply: 'UYGULA',
    cancel: 'VAZGEÇ'
  };
  _exports.default = _default;
});