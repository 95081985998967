define("da-vinci/locales/tr/p-list-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    noResults: 'ARADIĞINIZ KRİTERLERE <br>UYGUN KAYIT BULUNMUYOR',
    noResultsPhoenix: 'Herhangi bir kayıt bulunamadı.',
    removeFilters: 'KRİTERLERİ KALDIR'
  };
  _exports.default = _default;
});