define("da-vinci/attachment-uploader/service", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-get-config", "ember-decorators/object", "da-vinci/utils/camelize-keys"], function (_exports, _applyDecoratedDescriptor2, _emberGetConfig, _object, _camelizeKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AttachmentUploaderUnit = void 0;

  var _dec, _obj;

  function createplUploadInstance(trigger, mimeTypes) {
    var instance = new plupload.Uploader({
      // url option is required by plupload, otherwise it doesn't work.
      // it's replaced before upload in attachment-file.js with the url generated by the server
      url: 'dummy.url',
      browse_button: trigger,
      runtimes: 'html5,html4',
      multipart: true,
      drop_element: 'drop-target',
      filters: {
        mime_types: mimeTypes
      }
    });

    if (_emberGetConfig.default.environment !== 'test' && instance) {
      instance.init();
    }

    return instance;
  }

  function bindplUploadInstance(uploader, instance) {
    instance.bind('FilesAdded', Ember.run.bind(uploader, 'upload'));
    instance.bind('UploadProgress', Ember.run.bind(uploader, 'progressDidChange'));
    instance.bind('FileUploaded', Ember.run.bind(uploader, 'fileUploaded'));
    instance.bind('Error', Ember.run.bind(uploader, 'onError'));
  }

  var AttachmentUploaderUnit = Ember.Object.extend(Ember.Evented, (_dec = (0, _object.computed)('store'), (_obj = {
    attachment: null,
    pluploadInstance: null,
    selectedFileName: null,
    provisionPath: 'attachments/provision',
    remoteFileUrl: null,
    attachmentType: null,
    handleImportWorker: null,
    handleUploadError: null,
    handleIsUploading: null,
    isOrphaned: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.attachment.on('cancelUploadRequested', this, this.cancelUpload);
      var instance = createplUploadInstance(this.trigger, this.mimeTypes);

      if (instance) {
        bindplUploadInstance(this, instance);
      }

      this.setProperties({
        pluploadInstance: instance,
        isOrphaned: false
      });
    },
    makeOrphan: function makeOrphan() {
      if (!this.get('attachment.isUploading')) {
        this.destroy();
      } else {
        this.set('isOrphaned', true);

        if (this.pluploadInstance) {
          this.pluploadInstance.disableBrowse();
        }
      }
    },
    upload: function upload(_uploader, files) {
      this.selectedFileName = files[0].name;
      this.attachment.setProperties({
        isUploading: true,
        hasUploadError: false,
        uploadProgress: 0
      });

      if (this.handleIsUploading) {
        this.handleIsUploading();
      }

      var self = this;
      this.provision().then(function (uploadInfo) {
        self.sendToAws(uploadInfo);
      }, function () {
        self.onError();
      });
    },
    provision: function provision() {
      var url = "".concat(this.get('adapter.host'), "/").concat(this.provisionPath);
      var data = {
        filename: this.selectedFileName,
        type: this.attachmentType
      };
      return this.adapter.ajax(url, 'POST', {
        data: data
      });
    },
    adapter: function adapter(store) {
      return store.adapterFor('application');
    },
    sendToAws: function sendToAws(uploadInfo) {
      var attrs = (0, _camelizeKeys.default)(uploadInfo.data.attributes);
      this.set('remoteFileUrl', attrs.remoteFileUrl);
      var instance = this.pluploadInstance;

      if (instance) {
        // if cancelled during provision we won't have an instance
        instance.setOption('url', attrs.path);
        instance.setOption('multipart_params', JSON.parse(attrs.paramsRaw));
        instance.start();
      }
    },
    // event
    progressDidChange: function progressDidChange(_uploader, file) {
      this.set('attachment.uploadProgress', file.percent);
    },
    // event
    fileUploaded: function fileUploaded() {
      this.attachment.changeFile(this.selectedFileName, this.remoteFileUrl);

      if (this.handleImportWorker) {
        this.handleImportWorker();
      }

      this.destroyIfOrphaned();
    },
    // event
    onError: function onError() {
      this.set('attachment.hasUploadError', true);

      if (this.handleUploadError) {
        this.handleUploadError();
      }

      this.destroyIfOrphaned();
    },
    // event bound to cancelUploadRequested event of attachment (see initAndBind)
    cancelUpload: function cancelUpload() {
      var instance = this.pluploadInstance;
      var filename = this.selectedFileName;
      instance.stop();

      if (!Ember.isBlank(filename)) {
        instance.removeFile(filename);
      }

      this.destroyIfOrphaned();
    },
    destroyIfOrphaned: function destroyIfOrphaned() {
      this.attachment.setProperties({
        isUploading: false,
        uploadProgress: 0
      });

      if (this.isOrphaned) {
        this.destroy();
      }
    },
    destroy: function destroy() {
      this.attachment.off('cancelUploadRequested', this, this.cancelUpload);
      var instance = this.pluploadInstance;

      if (instance) {
        instance.stop();
        instance.destroy();
      }

      this.set('pluploadInstance', null);
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "adapter", [_dec], Object.getOwnPropertyDescriptor(_obj, "adapter"), _obj)), _obj)));
  _exports.AttachmentUploaderUnit = AttachmentUploaderUnit;

  var _default = Ember.Service.extend({
    me: Ember.inject.service('me'),
    store: Ember.inject.service('store'),
    start: function start(args) {
      var params = {};
      Object.assign(params, args, {
        store: this.store,
        me: this.me
      });
      return AttachmentUploaderUnit.create(params);
    }
  });

  _exports.default = _default;
});