define("da-vinci/utils/bank-name-to-lower-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bankNameToLowerCase = bankNameToLowerCase;

  function bankNameToLowerCase(bankName) {
    if (bankName) {
      return bankName.toLowerCase();
    }
  }
});