define("da-vinci/components/company-matching-modal/bank-accounts-step/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="row" data-test-bank-account-list-row>
    <div class="col-md-6">
      <div data-test-bank-account-name>{{bankAccount.name}}</div>
    </div>
    <div class="col-md-6">
      <AccountAutocomplete
        @value={{bankAccount.bankAccountCode.account}}
        @placeholder={{t
          "companyMatchingModalBankAccountsStep.fields.placeHolder"
        }}
        @onAccountChanged={{action "updateBankAccount"}}
        @data-test-bank-account-account-autocomplete={{true}}
      />
    </div>
  </div>
  
  */
  {
    "id": "AQZdmWd9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[1,[24,[\"bankAccount\",\"name\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n    \"],[5,\"account-autocomplete\",[],[[\"@value\",\"@placeholder\",\"@onAccountChanged\"],[[24,[\"bankAccount\",\"bankAccountCode\",\"account\"]],[28,\"t\",[\"companyMatchingModalBankAccountsStep.fields.placeHolder\"],null],[28,\"action\",[[23,0,[]],\"updateBankAccount\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/company-matching-modal/bank-accounts-step/row/template.hbs"
    }
  });

  _exports.default = _default;
});