define("da-vinci/components/contact-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <AccountAutocomplete
    @value={{value}}
    @onAccountChanged={{onAccountChanged}}
    @newAccountItem={{newAccountItem}}
    @data-test-contact-account-autocomplete={{true}}
  />
  
  */
  {
    "id": "dvRqNGB/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"account-autocomplete\",[],[[\"@value\",\"@onAccountChanged\",\"@newAccountItem\"],[[22,\"value\"],[22,\"onAccountChanged\"],[22,\"newAccountItem\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/contact-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});