define("da-vinci/utils/jsonapi/relationship-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function serializeRelatedRecord(relationSnapshot, tree) {
    relationSnapshot.adapterOptions = relationSnapshot.adapterOptions || {};
    relationSnapshot.adapterOptions.tree = tree;
    var serialized = relationSnapshot.serialize();

    if (relationSnapshot.id) {
      serialized.data.id = relationSnapshot.id;
    } else {
      if (!serialized.data.attributes) {
        serialized.data.attributes = {};
      }
    }

    if (tree && Object.keys(tree).length === 0) {
      delete serialized.data.relationships;
    }

    return serialized.data;
  }

  function serializeRelationship(serializer, snapshot, data, rel) {
    var relKind = rel.kind;
    var relKey = rel.key;
    var tree = snapshot.adapterOptions ? snapshot.adapterOptions.tree : null;

    if (data && tree && tree[relKey]) {
      data.relationships = data.relationships || {};
      var key = serializer.keyForRelationship(relKey, relKind, 'serialize');
      data.relationships[key] = data.relationships[key] || {};

      if (relKind === 'belongsTo') {
        var relatedRecord = snapshot.belongsTo(relKey);

        if (relatedRecord) {
          data.relationships[key].data = serializeRelatedRecord(relatedRecord, tree[relKey]);
        }
      } else if (relKind === 'hasMany') {
        var relatedRecords = snapshot.hasMany(relKey);

        if (relatedRecords) {
          data.relationships[key].data = relatedRecords.map(function (obj) {
            return serializeRelatedRecord(obj, tree[relKey]);
          });
        }
      }
    }
  }

  var _default = serializeRelationship;
  _exports.default = _default;
});