define("da-vinci/components/invoices/bulk-approve-modal/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal
    @isOpen={{true}}
    @containerClassNames={{styleNamespace}}
    @dataTestModalName="invoices-bulk-approve-modal-info"
    as |modal|
  >
    <modal.header>
      <h2>{{t "invoicesBulkApproveModalInfo.title"}}</h2>
    </modal.header>
  
    <modal.body>
      <p>
        {{t
          "invoicesBulkApproveModalInfo.info"
          invoiceCount=invoiceCount
          htmlSafe=true
        }}
      </p>
      <div>
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        {{t "invoicesBulkApproveModalInfo.warning" htmlSafe=true}}
      </div>
    </modal.body>
  
    <modal.footer>
      <PButton
        @type="outline"
        @size="small"
        @onClick={{onCancel}}
        @data-test-cancel-button={{true}}
      >
        {{t "invoicesBulkApproveModalInfo.button.cancel"}}
      </PButton>
      <PButton
        @size="small"
        @onClick={{onContinue}}
        @data-test-next-button={{true}}
      >
        {{t "invoicesBulkApproveModalInfo.button.next"}}
      </PButton>
    </modal.footer>
  </PModal>
  
  */
  {
    "id": "nsF7D36+",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@containerClassNames\",\"@dataTestModalName\"],[true,[22,\"styleNamespace\"],\"invoices-bulk-approve-modal-info\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"invoicesBulkApproveModalInfo.title\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"invoicesBulkApproveModalInfo.info\"],[[\"invoiceCount\",\"htmlSafe\"],[[24,[\"invoiceCount\"]],true]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-info-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n      \"],[1,[28,\"t\",[\"invoicesBulkApproveModalInfo.warning\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-button\",[],[[\"@type\",\"@size\",\"@onClick\"],[\"outline\",\"small\",[22,\"onCancel\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"invoicesBulkApproveModalInfo.button.cancel\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"p-button\",[],[[\"@size\",\"@onClick\"],[\"small\",[22,\"onContinue\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"invoicesBulkApproveModalInfo.button.next\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invoices/bulk-approve-modal/info/template.hbs"
    }
  });

  _exports.default = _default;
});