define("da-vinci/locales/tr/p-pagination-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    text: '{{totalCount}} kayıttan {{rangeStart}}-{{rangeEnd}} arası gösteriliyor.'
  };
  _exports.default = _default;
});