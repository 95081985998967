define("da-vinci/components/account-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <POneWayAutocomplete
    @items={{accounts}}
    @placeholder={{placeholder}}
    @searchTask={{searchAccount}}
    @inputValue={{inputValue}}
    @onItemSelected={{action "accountSelected"}}
    @onInputValueChanged={{action "updateInputValue"}}
    @onFocus={{onFocus}}
    @onBlur={{action "onBlur"}}
    as |account|
  >
    {{account.code}} - {{account.name}}
  </POneWayAutocomplete>
  
  */
  {
    "id": "+5czTYYk",
    "block": "{\"symbols\":[\"account\"],\"statements\":[[5,\"p-one-way-autocomplete\",[],[[\"@items\",\"@placeholder\",\"@searchTask\",\"@inputValue\",\"@onItemSelected\",\"@onInputValueChanged\",\"@onFocus\",\"@onBlur\"],[[22,\"accounts\"],[22,\"placeholder\"],[22,\"searchAccount\"],[22,\"inputValue\"],[28,\"action\",[[23,0,[]],\"accountSelected\"],null],[28,\"action\",[[23,0,[]],\"updateInputValue\"],null],[22,\"onFocus\"],[28,\"action\",[[23,0,[]],\"onBlur\"],null]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"code\"]],false],[0,\" - \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/account-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});