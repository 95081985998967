define("da-vinci/translations/tr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accommodationTaxFilter": {
      "0": "KONAKlAMA VERGİSİ ORANI: %0",
      "2": "KONAKlAMA VERGİSİ ORANI: %2",
      "buttons": {
        "apply": "UYGULA",
        "cancel": "VAZGEÇ"
      },
      "multiple": "{filterCount} KONAKLAMA VERGİSİ ORANI",
      "title": "KONAKlAMA VERGİSİ DURUMU",
      "two": "%2",
      "zero": "%0"
    },
    "accountAutocomplete": {
      "defaultPlaceholder": "Hesap adını ya da kodunu giriniz"
    },
    "accountantOffice": {
      "pageTitle": "Personeller",
      "tabSelector": {
        "staffMember": "Tüm Personeller",
        "userInvitation": "Onay Bekleyenler"
      }
    },
    "announcementModal": {
      "body": {
        "announcementMessage": "<p>Sizin aracılığınız ve Paraşüt güvencesiyle e-belgeye geçiş yapacak mükelleflere,<ul style='list-style-type: disc; margin-block-start: 1em; margin-block-end: 1em; padding-inline-start: 40px;'><li>1 yıllık Paraşüt aboneliği</li><li>Ücretsiz Anahtar Teslim e-Belge hizmeti</li><li>600 e-kontör ve +3 ay abonelik süresi hediye ediyoruz!</li></ul>Kampanyadan yararlanmak için <strong>Mükellef Bilgi Formu</strong> üzerinden e-belge kullanıcısı olacak mükelleflerinize ait bilgileri girebilir; sizin için zahmetsiz, mükellefler için ise avantajlarla dolu yeni bir dönemi vakit kaybetmeden başlatabilirsiniz.</p>"
      },
      "footer": {
        "companyInfoForm": "Mükellef Bilgi Formu",
        "doNotShowAnnouncementPermanently": "Bir daha gösterme",
        "goToDetails": "Detaylara git"
      },
      "header": {
        "title": "e-Belge Kampanyamızda son gün, 31 Temmuz!"
      }
    },
    "appHeader": {
      "accountantOfficePersonnel": "SORUMLU PERSONEL",
      "accountingType": {
        "basic": "İşletme Defteri",
        "standard": "Bilanço Defteri"
      },
      "bizmu": "Bizmu",
      "companion": "Atlas Asist",
      "copied": "Kopyalandı",
      "parasut": "Paraşüt",
      "portal": "Portal",
      "tabs": {
        "waiting": "Aktarıma Hazır <span class=badge>{totalInvoiceCount}</span>"
      },
      "taxPayerSettings": "Mükellef ayarları"
    },
    "aria": {
      "label": {
        "loading": "loading"
      }
    },
    "assignAuthorizedUsersModal": {
      "assignedUsersTitle": "ATANMIŞ PERSONELLER",
      "bodyTitle": "PERSONEL ATA",
      "buttons": {
        "close": "Daha sonra",
        "save": "Kaydet"
      },
      "errors": {
        "saveFailed": "Kaydetme işlemi başarısız oldu"
      },
      "flash": {
        "success": "Sorumlu personel bilgisi güncellendi."
      },
      "info": "Mükellefinize sorumlu personel atayın.",
      "loading": "Kullanıcılar çekiliyor.",
      "noSelectedUsers": {
        "message": "Henüz atanmış personel yok."
      },
      "placeholder": "Personel adı girin",
      "title": "Sorumlu personel"
    },
    "bankAccounts": {
      "bankTransactions": "Banka Hesap Hareketleri",
      "emptyInfo": "Mükellefiniz banka hesaplarını Paraşüt ile entegre etsin, Atlas ile kolayca muhasebeleştirin.",
      "loading": "Banka hareketleriniz yükleniyor.",
      "ready": "Aktarıma Hazır",
      "total": "Toplam",
      "waiting": "Onay Bekleyen"
    },
    "bankTransactionTable": {
      "accountCode": "HESAP KODU",
      "amount": "MEBLAĞ",
      "description": "AÇIKLAMA",
      "emptyText": "Aktarılacak banka hareketiniz bulunmamaktadır.<br>Mükellefinizin yeni banka hareketleri burada yerini alacak.",
      "flash": {
        "notApproved": "Banka hareketi aktarıma hazır durumuna getirilemedi.",
        "notCancelled": "Banka hareketi onay bekliyor durumuna getirilemedi.",
        "notSaved": "Hesap kodlarınız kaydedilemedi."
      },
      "transactionType": {
        "account_credit": "Para Çıkışı",
        "account_debit": "Para Girişi",
        "balance_adjustment": "Bakiye Sabitleme",
        "bank_fee_payment": "Banka Ödemesi",
        "check_cash_in": "Çek Tahsilatı",
        "check_cash_out": "Çek Ödemesi",
        "contact_credit": "Müşteriden Tahsilat",
        "contact_debit": "Tedarikçiye Ödeme",
        "employee_debit": "Çalışana Ödeme",
        "initial_account_balance": "Açılış bakiyesi",
        "money_transfer": "Para Transferi",
        "purchase_bill_payment": "Fiş / Fatura Ödemesi",
        "tax_payment": "Vergi Ödemesi"
      },
      "txType": "İŞLEM TÜRÜ"
    },
    "bankTransactionTagList": {
      "isInitialAccountBalance": "Açılış bakiyesi"
    },
    "bankTransactionsBulkApproveModal": {
      "buttons": {
        "bulkApproveAll": "TÜMÜNÜ ONAYLA",
        "bulkApproveFiltered": "SEÇİMİ ONAYLA({bankTransactionCount})"
      }
    },
    "bankTransactionsBulkApproveModalInfo": {
      "button": {
        "cancel": "VAZGEÇ",
        "next": "DEVAM ET"
      },
      "info": "<strong>{bankTransactionCount}</strong> adet banka hareketiniz onaylanarak aktarıma hazır hale getirilecek.",
      "title": "Toplu Onaylama",
      "warning": "<strong>BİLGİ:</strong> Banka hareketlerindeki gerekli alanları doldurduğunuzdan emin olun. Eksik bilgisi bulunan banka hareketleri aktarılamaz."
    },
    "bankTransactionsBulkApproveModalSummary": {
      "button": {
        "ok": "TAMAM"
      },
      "reasons": {
        "bankAccountCodeKeyErrors": "{count} adet banka hareketiniz banka hesap kodunun eksik olması",
        "contactAccountKeyErrors": "{count} adet banka hareketiniz hesap kodunun eksik olması",
        "finish": "nedeniyle onaylanamadı.",
        "hasNetworkError": "Banka hareketleriniz aktarılamadı. Lütfen tekrar deneyiniz.",
        "unknownError": "{count} adet banka hareketiniz teknik bir hata"
      },
      "successCount": "{count} adet banka hareketiniz onaylanarak aktarıma hazır hale getirildi.",
      "title": "İşlem tamamlandı!"
    },
    "bankTransactionsBulkApproveModalTransfer": {
      "description": "Lütfen banka hareketleriniz onaylanırken bekleyin...",
      "title": "Banka hareketleriniz onaylanıyor..."
    },
    "basicAccountingTypeInvoiceListRow": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxRate": "KV ORANI",
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentLinkNotReady": "PDF hazır değil",
        "documentNo": "EVRAK NO",
        "documentShowInvoice": "Faturayı göster"
      },
      "deductibleVatWithholding": "HESAPLANAN KDV",
      "netTotal": "TOPLAM",
      "netTotalWithoutVat": "KDV'SİZ TUTAR",
      "product": "ÜRÜN/HİZMET",
      "quantity": "MİKTAR",
      "vat": "KDV TUTARI",
      "vatRate": "KDV",
      "vatTaxBase": "MATRAH",
      "vatWithholding": "HESAPLANAN KDV",
      "vatWithholdingRate": "TEVK. ORANI"
    },
    "basicAccountingTypeInvoiceListRowDetail": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccountCode": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "communicationsTax": "ÖİV TUTARI",
      "communicationsTaxAccountCode": "ÖİV HESAP KODU",
      "communicationsTaxRate": "ÖİV ORANI",
      "detailVatWithholdingRate": "{detailVatWithholdingRate}/10",
      "exciseDuty": "ÖTV TUTARI",
      "exciseDutyRate": "ÖTV ORANI",
      "exciseDutyTaxAccountCode": "ÖTV HESAP KODU"
    },
    "basicAccountingTypeInvoiceTable": {
      "emptyText": "Aktarıma hazır faturanız bulunmamaktadır.<br>Bekleyen faturalarınızı onaylayarak Luca'ya aktarıma hazır hale getirebilirsiniz.",
      "header": {
        "description": "AÇIKLAMA (CARİ ÜNVANI)",
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO",
        "netTotal": "ÖDENECEK TUTAR",
        "netTotalWithoutVat": "KDV HARİÇ TOPLAM",
        "totalTax": "VERGİLER TOPLAMI",
        "totalVat": "TOPLAM KDV",
        "vat1": "KDV1",
        "vat18": "KDV18",
        "vat8": "KDV8"
      }
    },
    "basicAccountingTypePurchaseBillBasicListRow": {
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO",
        "documentPurchaseBillImage": "Fiş / Fatura görseli",
        "documentShowInAsist": "Asist'te göster",
        "documentShowInBizmu": "Bizmu'da göster",
        "documentShowInParasut": "Paraşüt'te göster",
        "documentShowInPortal": "Portal'da göster"
      },
      "netTotal": "KDV'Lİ TUTAR"
    },
    "basicAccountingTypePurchaseBillListRow": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxRate": "KV ORANI",
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentLinkNotReady": "PDF hazır değil",
        "documentNo": "EVRAK NO",
        "documentPurchaseBillImage": "Fiş / Fatura görseli",
        "documentShowInAsist": "Asist'te göster",
        "documentShowInBizmu": "Bizmu'da göster",
        "documentShowInParasut": "Paraşüt'te göster",
        "documentShowInPortal": "Portal'da göster",
        "documentShowInvoice": "Faturayı göster"
      },
      "deductibleVatWithholding": "İNDİRİLECEK KDV",
      "netTotal": "TOPLAM",
      "netTotalWithoutVat": "KDV'SİZ TUTAR",
      "product": "ÜRÜN/HİZMET",
      "quantity": "MİKTAR",
      "vat": "KDV TUTARI",
      "vatRate": "KDV",
      "vatTaxBase": "MATRAH",
      "vatWithholding": "KDV 2",
      "vatWithholdingRate": "TEVK. ORANI"
    },
    "basicAccountingTypePurchaseBillListRowDetail": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccountCode": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "communicationsTax": "ÖİV TUTARI",
      "communicationsTaxAccountCode": "ÖİV HESAP KODU",
      "communicationsTaxRate": "ÖİV ORANI",
      "detailVatWithholdingRate": "{detailVatWithholdingRate}/10",
      "exciseDuty": "ÖTV TUTARI",
      "exciseDutyRate": "ÖTV ORANI",
      "exciseDutyTaxAccountCode": "ÖTV HESAP KODU"
    },
    "basicAccountingTypePurchaseBillTable": {
      "emptyText": "Aktarıma hazır faturanız bulunmamaktadır.<br>Bekleyen faturalarınızı onaylayarak Luca'ya aktarıma hazır hale getirebilirsiniz.",
      "header": {
        "contactAccount": "CARİ HESAP KODU",
        "description": "AÇIKLAMA (CARİ ÜNVANI)",
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO",
        "netTotal": "ÖDENECEK TUTAR",
        "netTotalWithoutVat": "KDV HARİÇ TOPLAM",
        "purchaseAccount": "GİDER HESAP KODU",
        "totalTax": "VERGİLER TOPLAMI",
        "totalVat": "TOPLAM KDV",
        "vat1": "KDV1",
        "vat18": "KDV18",
        "vat8": "KDV8"
      }
    },
    "basicPurchaseBillRow": {
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO",
        "documentPurchaseBillImage": "Fiş / Fatura görseli",
        "documentShowInAsist": "Asist'te göster",
        "documentShowInBizmu": "Bizmu'da göster",
        "documentShowInParasut": "Paraşüt'te göster",
        "documentShowInPortal": "Portal'da göster"
      },
      "discountVatAccount": "İND. KDV HESAP KODU",
      "flash": {
        "notSaved": "Hesap kodlarınız kaydedilemedi"
      },
      "invoiceAccount": "GİDER HESAP KODU",
      "invoiceHasEdgeBasicPurchaseBillNotAllowedVatRate": "Faturada geçersiz KDV oranı bulunduğu için aktarılamayacaktır",
      "netTotal": "TOPLAM",
      "netTotalWithoutVat": "KDV'SİZ TUTAR",
      "product": "ÜRÜN/HİZMET",
      "quantity": "MİKTAR",
      "vat": "KDV TUTARI",
      "vatAccount": "KDV HESAP KODU",
      "vatRate": "KDV",
      "vatTaxBase": "MATRAH"
    },
    "breadcrumb": {
      "companyMatching": {
        "edit": "Hesap Kodları",
        "index": "Müşteriler"
      },
      "container": "Mükellefler",
      "invoiceType": "Fatura türü",
      "login": "Giriş",
      "luca": {
        "dataWrite": "Veri Aktarma",
        "index": "Luca"
      },
      "purchaseBills": "&nbsp;- Giderler",
      "salesInvoices": "&nbsp;- Satışlar"
    },
    "companyMatchingModal": {
      "flash": {
        "error": "Ayarlarınız kaydedilirken bir hata oluştu.",
        "success": "Ayarlarınız başarıyla kaydedildi.",
        "successfullyDestroy": "Şirket eşleştirmesi kaldırıldı.",
        "timeoutError": "İşleminiz zaman aşımına uğradı",
        "unknownError": "Bilinmeyen bir hata oluştu"
      },
      "totalStep": " / 8"
    },
    "companyMatchingModalAccommodationTaxStep": {
      "bodyTitle": "Konaklama vergisi hesap kodlarını seçin",
      "buttons": {
        "back": "GERİ",
        "close": "KAPAT",
        "saveAndClose": "KAYDET VE KAPAT",
        "saveAndContinue": "KAYDET VE DEVAM ET"
      },
      "errors": {
        "saveFailed": "Kaydetme işlemi başarısız oldu"
      },
      "fields": {
        "accommodation0": "%0",
        "accommodation2": "%2",
        "purchase": "ALIŞ",
        "purchaseRefund": "ALINAN İADE",
        "sales": "SATIŞ",
        "salesRefund": "KESİLEN İADE"
      },
      "flash": {
        "success": "Ayarlarınız başarıyla kaydedildi."
      },
      "info": "Faturaların hesap kodlarını otomatik doldurabilmemiz için müşterinizin ilgili konaklama vergisi hesap kodlarını seçin.",
      "pageTitle": "Hesap Kodları",
      "step": "6",
      "title": "Hesap Eşleştirme Sihirbazı",
      "warningMessage": "Gider faturaları için hesap kodu tanımlanmadığında konaklama vergisi brüt toplama eklenerek muhasebeleştirilecektir."
    },
    "companyMatchingModalBankAccountsStep": {
      "bodyTitle": "Banka Hesaplarını Seçin",
      "buttons": {
        "back": "GERİ",
        "close": "KAPAT",
        "saveAndClose": "KAYDET VE KAPAT",
        "saveAndContinue": "KAYDET VE DEVAM ET"
      },
      "errors": {
        "saveFailed": "Kaydetme işlemi başarısız oldu"
      },
      "fields": {
        "accountName": "HESAP KODU",
        "bankName": "BANKA",
        "button": {
          "addNew": "BANKA EKLE"
        },
        "placeHolder": "102..."
      },
      "flash": {
        "success": "Ayarlarınız başarıyla kaydedildi."
      },
      "info": "Banka hareketlerinizi aktarıma hazır hale getirebilmemiz için müşterinizin ilgili Banka hesaplarını seçin.",
      "pageTitle": "Banka Hesapları",
      "step": "4 ",
      "title": "Hesap Eşleştirme Sihirbazı"
    },
    "companyMatchingModalCommunicationsTaxAndExciseDutyStep": {
      "bodyTitle": "ÖTV ve ÖİV hesap kodlarını seçin",
      "buttons": {
        "back": "GERİ",
        "close": "KAPAT",
        "saveAndClose": "KAYDET VE KAPAT",
        "saveAndContinue": "KAYDET VE DEVAM ET"
      },
      "errors": {
        "saveFailed": "Kaydetme işlemi başarısız oldu"
      },
      "fields": {
        "communicationsTaxAccount": "ÖİV",
        "exciseDutyAccount": "ÖTV",
        "purchase": "HESAPLANAN KDV",
        "purchaseRefund": "ALINAN İADE",
        "sales": "SATIŞ",
        "salesRefund": "KESİLEN İADE"
      },
      "flash": {
        "success": "Ayarlarınız başarıyla kaydedildi."
      },
      "info": "Faturaların hesap kodlarını otomatik doldurabilmemiz için müşterinizin ilgili   ÖTV ve ÖİV hesap kodlarını seçin.",
      "pageTitle": "Hesap Kodları",
      "step": "5",
      "title": "Hesap Eşleştirme Sihirbazı"
    },
    "companyMatchingModalCompletionStep": {
      "bodyInfo": "Ayarlarınızın uygulanabilmesi için sayfanın yenilenmesi gerekmektedir.",
      "bodyTitle": "Hesap Kodu Ayarlarınız Tamamlandı!",
      "bodyWarning": "Bu işlem henüz kaydedilmemiş verilerinizin silinmesine sebep olabilir.",
      "buttons": {
        "close": "KAPAT",
        "refreshPage": "SAYFAYI YENİLE"
      },
      "title": "Hesap Eşleştirme Sihirbazı"
    },
    "companyMatchingModalContactAccountStep": {
      "bodyTitle": "Cari (kasa) hesap kodu kullanıyor musunuz?",
      "buttons": {
        "back": "GERİ",
        "close": "KAPAT",
        "saveAndClose": "KAYDET VE KAPAT",
        "saveAndContinue": "KAYDET VE DEVAM ET"
      },
      "contactAccountLabel": "CARİ (KASA) HESABI",
      "errors": {
        "saveFailed": "Kaydetme işlemi başarısız oldu"
      },
      "info": "{companyName} müşterinizin satış yaptığı firmaları tek bir cari hesap kodu (kasa hesabı)  altında topluyorsanız bu kodu aşağıda belirtin.",
      "step": "8",
      "title": "Hesap Eşleştirme Sihirbazı"
    },
    "companyMatchingModalCreateCompanyMatchingStep": {
      "accountingType": {
        "basic": "İşletme Defteri",
        "standard": "Bilanço Defteri"
      },
      "accountingTypeTitle": "DEFTER TÜRÜ",
      "bodyTitle": {
        "companion": "Müşterinizin Atlas Asist ve Luca hesaplarını eşleştirin",
        "parasut": "Müşterinizin Paraşüt ve Luca hesaplarını eşleştirin",
        "phoenix": "Müşterinizin Bizmu ve Luca hesaplarını eşleştirin",
        "portal": "Müşterinizin Portal ve Luca hesaplarını eşleştirin"
      },
      "buttons": {
        "back": "GERİ",
        "close": "KAPAT",
        "continue": "DEVAM ET",
        "destroy": "KALDIR",
        "saveAndComplete": "EŞLEŞTİR VE TAMAMLA",
        "saveAndContinue": "EŞLEŞTİR VE DEVAM ET"
      },
      "editInfo": "{companyName} müşterinizin ilgili Luca hesabı eşleştirmesi gerçekleştirilmiştir.",
      "errors": {
        "emptyAccountingType": "Bir defter türü seçin.",
        "emptyLucaAccount": "Eşleştirmek istediğiniz <b>'Luca Hesabını'</b> seçin."
      },
      "info": "Faturalarınızı Luca'ya aktarabilmek için, {companyName} müşterinizin ilgili  Luca hesabını seçin.",
      "lucaCompanyName": "LUCA HESABI",
      "parasutCompanyName": "{usedApp} HESABI",
      "step": "1",
      "titleForEditMode": "Hesap Ayarları",
      "titleForWizardMode": "Hesap Eşleştirme Sihirbazı"
    },
    "companyMatchingModalDestroyCompanyMatchingStep": {
      "buttons": {
        "close": "KAPAT",
        "saveAndClose": "KAYDET VE KAPAT",
        "saveAndContinue": "KAYDET VE DEVAM ET"
      },
      "destroyCompanyMatchingConfirmationInfo": "Şirket eşleştirmesini kaldırmak istiyorsanız aşağıya 'Onaylıyorum' yazarak kaldır butonuna basınız.",
      "destroyCompanyMatchingInfo": {
        "description1": "Tüm aktarım gruplarınız geri alınacaktır,",
        "description2": "Bilanço defteri için faturalarınıza tanımlı hesap kodları silinecektir,",
        "description3": "Şirket eşleştirmeniz kaldırılacaktır.",
        "title": "Bu işlemi yapmanız durumunda;"
      },
      "destroyCompanyMatchingTitle": {
        "companion": "Müşterinizin Atlas Asist ve Luca hesap eşleştirmesini kaldırın.",
        "parasut": "Müşterinizin Paraşüt ve Luca hesap eşleştirmesini kaldırın.",
        "phoenix": "Müşterinizin MikroX ve Luca hesap eşleştirmesini kaldırın.",
        "portal": "Müşterinizin Portal ve Luca hesap eşleştirmesini kaldırın."
      },
      "flash": {
        "success": "Şirket eşleştirmeniz başarıyla kaldırılmıştır.",
        "wrongInput": "'Onaylıyorum' kelimesini doğru yazdığınızdan emin olunuz."
      },
      "progressModal": {
        "info": "Şirket eşleştirmenizin kaldırılması biraz zaman alabilir. Lütfen bekleyin.",
        "percentComplete": "% {percent} tamamlandı",
        "title": "Şirket Eşleştirmesi Kaldırılıyor"
      }
    },
    "companyMatchingModalNavigationBar": {
      "accommodationTax": "Konaklama Hesapları",
      "bankAccount": "Banka Hesapları",
      "communicationsTaxAndExciseDuty": "ÖTV ve ÖİV Hesapları",
      "companyMatching": "Hesap Eşleştirme",
      "companyMatchingDestroy": "Eşleştirmeyi Kaldır",
      "contactAccount": "Cari Hesap Kodu",
      "salesAccounts": "Satış Hesap Kodları",
      "vatPurchaseAccounts": "Alış KDV Hesapları",
      "vatSalesAccounts": "Satış KDV Hesapları"
    },
    "companyMatchingModalSalesAccountsStep": {
      "accommodationTaxSales": "KONAKLAMA İSTİSNA",
      "bodyTitle": "Varsayılan satış hesap kodlarını belirtin",
      "bodyTitleProductAccountCodes": "Ürün hesap kodlarını belirtin",
      "buttons": {
        "back": "GERİ",
        "close": "KAPAT",
        "saveAndClose": "KAYDET VE KAPAT",
        "saveAndContinue": "KAYDET VE DEVAM ET"
      },
      "domesticSales": "YURTİÇİ SATIŞLAR",
      "domesticSalesVat0": "%0",
      "domesticSalesVat1": "%1",
      "domesticSalesVat10": "%10",
      "domesticSalesVat18": "%18",
      "domesticSalesVat20": "%20",
      "domesticSalesVat8": "%8",
      "errors": {
        "saveFailed": "Kaydetme işlemi başarısız oldu"
      },
      "flash": {
        "success": "Ayarlarınız başarıyla kaydedildi."
      },
      "info": "Yurtiçi satışları vergi dilimlerine göre farklı hesap kodları altında tutuyorsanız,     veya satılan ürünlere özel hesap kodları tutuyorsanız aşağıda belirtin.",
      "productAccounts": "ÜRÜN HESAP KODALARI",
      "productAccountsCode": "HESAP ADI YADA KODU",
      "productAccountsName": "ÜRÜN ADI YADA KODU",
      "step": "7",
      "title": "Hesap Eşleştirme Sihirbazı",
      "vatWithholdingSales": "TEVKİFATLI SATIŞLAR",
      "warningMessage": "Ürün hesap kodları ilgili ürünün yurtiçi satış hesap kodlarını   etkisiz kılacaktır."
    },
    "companyMatchingModalSalesAccountsStepProductAccountList": {
      "button": {
        "addNew": "ÜRÜN EKLE"
      }
    },
    "companyMatchingModalVatPurchaseAccountsStep": {
      "bodyTitle": "Alış KDV hesap kodlarını seçin",
      "buttons": {
        "back": "GERİ",
        "close": "KAPAT",
        "saveAndClose": "KAYDET VE KAPAT",
        "saveAndContinue": "KAYDET VE DEVAM ET"
      },
      "errors": {
        "saveFailed": "Kaydetme işlemi başarısız oldu"
      },
      "fields": {
        "purchase": "İNDİRİLECEK KDV",
        "purchaseOtherVatWithholding": "KDV 2",
        "purchaseRefund": "ALINAN İADE",
        "purchaseVatWithholding": "TEVKİFATLI İNDİRİLECEK KDV",
        "vat1": "%1",
        "vat10": "%10",
        "vat18": "%18",
        "vat20": "%20",
        "vat8": "%8"
      },
      "flash": {
        "success": "Ayarlarınız başarıyla kaydedildi."
      },
      "info": "Faturalarınızı aktarıma hazır hale getirebilmemiz için müşterinizin ilgili KDV hesap kodlarını seçin.",
      "pageTitle": "Hesap Kodları",
      "step": "3 ",
      "title": "Hesap Eşleştirme Sihirbazı"
    },
    "companyMatchingModalVatSalesAccountsStep": {
      "bodyTitle": "Satış KDV hesap kodlarını seçin",
      "buttons": {
        "back": "GERİ",
        "close": "KAPAT",
        "saveAndClose": "KAYDET VE KAPAT",
        "saveAndContinue": "KAYDET VE DEVAM ET"
      },
      "errors": {
        "saveFailed": "Kaydetme işlemi başarısız oldu"
      },
      "fields": {
        "sales": "HESAPLANAN KDV",
        "salesRefund": "KESİLEN İADE",
        "salesVatWithholding": "TEVKİFATLI KDV",
        "vat1": "%1",
        "vat10": "%10",
        "vat18": "%18",
        "vat20": "%20",
        "vat8": "%8"
      },
      "flash": {
        "success": "Ayarlarınız başarıyla kaydedildi."
      },
      "info": "Faturalarınızı aktarıma hazır hale getirebilmemiz için müşterinizin   ilgili KDV hesap kodlarını seçin.",
      "pageTitle": "Hesap Kodları",
      "step": "2 ",
      "title": "Hesap Eşleştirme Sihirbazı"
    },
    "companyMatchingProductAccountList": {
      "button": {
        "addNew": "YENİ SATIR EKLE"
      },
      "column": {
        "accountCode": "Hesap Kodu",
        "productCode": "Ürün Kodu"
      }
    },
    "compatibilityModal": {
      "buttons": {
        "browser": "CHROME'u İNDİR",
        "close": "DAHA SONRA",
        "extension": "CHROME'a EKLE",
        "goBack": "ANASAYFAYA DÖN",
        "retry": "EKLEDİM, KONTROL ET"
      },
      "description": {
        "both": "Atlas'ı Luca hesabınız ile entegre edebilmek ve faturalarınızı aktarabilmek için <strong>Google Chrome</strong> kullanmanız ve <strong>Paraşüt Mali Müşavir</strong> eklentisini Google Chrome'a eklemeniz gerekmektedir.",
        "browser": "Atlas'ı Luca hesabınız ile entegre edebilmek ve faturalarınızı aktarabilmek için <strong>Google Chrome</strong> kullanmanız gerekmektedir.",
        "extension": "Atlas'ı Luca hesabınız ile entegre edebilmek ve faturalarınızı aktarabilmek için <strong>Paraşüt Mali Müşavir</strong> eklentisini Google Chrome'a eklemeniz gerekmektedir."
      },
      "numbers": {
        "one": "1",
        "two": "2"
      },
      "titles": {
        "browser": "Google Chrome",
        "extension": "Paraşüt Mali Müşavir Eklentisi",
        "installExtension": "Paraşüt Mali Müşavir Eklentisi'ni aktif edin",
        "pageTitle": "Atlas'a Hoş Geldiniz",
        "title": "Atlas'a Hoş Geldiniz",
        "useChrome": "Google Chrome Kullanın"
      }
    },
    "contactAutocomplete": {
      "placeholder": "Hesap adını ya da kodunu giriniz"
    },
    "containerIndexHeader": {
      "allCustomersTab": "Tüm Mükellefler ({count})",
      "headerBody": "Bu ekranda e-fatura mükelleflerinizi görüyorsunuz. Faturalarını muhasebeleştirmek istediğiniz mükellefinizi seçerek başlayabilirsiniz.",
      "headerTitle": "Mükellefleriniz"
    },
    "converterErrorsModal": {
      "close": "SAYFAYI KAPAT",
      "description": "Aşağıdaki hatalardan dolayı faturalarınızı aktaramadık. Hatalı faturaları <b>ONAY BEKLİYOR</b> durumuna geri alarak diğer faturalarınızı aktarmaya devam edebilirsiniz.",
      "errorMessage": "<b>{companyName}</b> şirketine ait <b>{documentDate}</b> tarihli <b>{documentNo}</b> evrak numaralı fatura aktarımı başarısız oldu.",
      "errors": {
        "invalidAccountingType": "Geçersiz defter türü",
        "invoiceDetailBlank": "Fatura detayı boş.",
        "invoiceHasMoreThanOneVat": "Fatura üzerinde birden fazla kdv bulunuyor.",
        "unknown": "Faturalarınızın aktarımı sırasında bir hata oluştu, lütfen bu sayfayı kapatarak panelinizdeki <b>DESTEK</b> bağlantısı ile bizimle iletişime geçin."
      },
      "pleaseWait": "Lütfen Bekleyiniz...",
      "title": "Faturalarınız aktarılamadı"
    },
    "createCompanyMatchingModal": {
      "accountingType": "DEFTER TÜRÜ",
      "button": {
        "cancel": "VAZGEÇ",
        "match": "EŞLEŞTİR"
      },
      "lucaCompanyName": "LUCA HESABI",
      "parasutCompanyName": "PARAŞÜT HESABI",
      "title": "Müşterinin hesaplarını eşleştirin"
    },
    "createNewCompanyModal": {
      "accountCreatedStep": {
        "bodyMessage": "<b>{companyName}</b> şirketi için <b>Atlas Asist</b> deneme hesabı oluşturuldu. Mükellefinizden erişim izni isteyerek finansal verilerinize kolayca ulaşabilirsiniz.",
        "title": "Deneme hesabı oluşturuldu"
      },
      "accountStep": {
        "formTitle": {
          "companyName": "FİRMA İSMİ",
          "contactName": "AD SOYAD",
          "phoneNumber": {
            "label": "TELEFON NUMARASI",
            "placeholder": "örn. 5551234567"
          }
        },
        "placeholder": {
          "companyName": "Mükellefinizin firma adı",
          "contactName": "Mükellefinizin adı soyadı",
          "phoneNumber": "Mükellefinizin telefon numarası"
        },
        "title": "Mükellefinizin Bilgileri"
      },
      "buttons": {
        "cancel": "VAZGEÇ",
        "continue": "DEVAM ET",
        "done": "TAMAM",
        "submit": "HESABI OLUŞTUR"
      },
      "emailFailedStep": {
        "bodyMessage": "Atlas ofis hesabınıza eklemek istediğiniz {email} email adresi ile bir hesap mevcut. Bu adres mükellefinize ait ise 'Erişim izni' isteyerek fatura ve banka hareketlerine ulaşabilir, kolayca muhasebeleştirebilirsiniz.",
        "title": "Bu e-posta adresi ile hesap mevcut"
      },
      "emailStep": {
        "emailInput": {
          "label": "E-POSTA ADRESİ"
        },
        "title": "E-Posta adresi"
      },
      "smallTitle": "Yeni mükellef ekleyin"
    },
    "createOfficeModal": {
      "body": {
        "infoMessage": "Paraşüt Atlas içerisinde personellerinizi ekleyeceğiniz çalışma ortamınızın adını belirleyin.",
        "officeName": {
          "label": "MÜŞAVİRLİK OFİSİNİZİN ADI",
          "placeholder": "örn. Acme Mali Müşavirlik"
        }
      },
      "buttons": {
        "close": "OFİS SAHİBİ DEĞİLİM",
        "submit": "DEVAM ET"
      },
      "infoSmallTitle": "Dijital ofisinizi oluşturun",
      "infoTitle": "Müşavirlik ofisinizin adını girin",
      "pageTitle": "Ofis oluştur"
    },
    "createOfficeWarning": {
      "title": "Dijital ofisinizi oluşturarak Paraşüt kullanan tüm mükelleflerinizi tek ekrandan yönetin, ekibiniz ile uyum içinde çalışın, veri aktarımını saniyelere indirin!"
    },
    "currencyName": {
      "eur": "Euro",
      "gbp": "İngiliz Sterlini",
      "trl": "Türk Lirası",
      "usd": "Amerikan Doları"
    },
    "dateUtil": {
      "thisMonth": "BU AY"
    },
    "documentDateFilter": {
      "buttons": {
        "apply": "UYGULA",
        "cancel": "VAZGEÇ"
      },
      "endDate": "BİTİŞ TARİHİ",
      "selectEndDate": "Bitiş tarihi seçin",
      "selectStartDate": "Başlangıç tarihi seçin",
      "startDate": "BAŞLANGIÇ TARİHİ",
      "title": "EVRAK TARİHİ"
    },
    "documentTypeFilter": {
      "all": "E-FATURA veya MATBU FATURA",
      "buttons": {
        "apply": "UYGULA",
        "cancel": "VAZGEÇ"
      },
      "e_document": "E-FATURA",
      "printed": "MATBU FATURA",
      "title": "EVRAK TÜRÜ"
    },
    "downloadEDocumentsModal": {
      "download": "FATURA PDF'LERİNİ İNDİR",
      "downloadInvoice": "FATURALARI İNDİR",
      "downloadMergePdf": "FATURALARI TEK PDF OLARAK İNDİR",
      "downloadMergePdfInProgress": "Toplu pdf indirme işleminiz devam etmektedir, bir işlem bitmeden yenisi başlatılamaz.",
      "invoiceExport": "FATURALARI DIŞARI AKTAR",
      "xmlDownload": "XML İLE DIŞARI AKTAR"
    },
    "downloadEDocumentsModalError": {
      "close": "KAPAT",
      "errors": {
        "max_number_exceeded": "Tek seferde en fazla 30000 e-fatura indirilebilir.",
        "no_data": "İndirilecek e-Fatura bulunamadı.",
        "unknown": "Bilinmeyen bir hata oluştu."
      },
      "title": "e-Faturaları İndir"
    },
    "downloadEDocumentsModalInProgress": {
      "close": "KAPAT",
      "fileName": "<b>Dosya adı:</b> {fileName}",
      "info": "e-Faturalarınız indirilmek üzere hazırlanıyor. Lütfen bekleyin...",
      "pdfMergeCountInfo1": "{pdfCount} adet faturanız <b>{pdfMergeCount} dosya halinde</b> indirilecektir.",
      "pdfMergeCountInfo2": "Her dosyada en fazla 200 adet fatura olacaktır.",
      "pdfMergeInfo": "İndirme işlemi sürerken hesabınızı kullanabilrsiniz, ancak sürecin başarıyla tamamlanması için tarayıcıyı kapatmayın, yenilemeyin.",
      "pdfMergeOnePdfInfo": "{pdfCount} adet faturanız tek dosya olarak indiriliyor",
      "progressStatusInfo": "PDF indiriliyor.",
      "title": "e-Faturaları İndir"
    },
    "downloadEDocumentsModalSuccess": {
      "close": "KAPAT",
      "download": "İNDİR",
      "fileName": "<b>Dosya adı:</b> {fileName}",
      "info": "e-Faturalarınız indirilmeye hazır.",
      "not_found_pdf": " numaralı faturalarınızın pdfi bulunmamaktadır. İndirdiğiniz dosyada bu PDF'ler yer almayacaktır.",
      "pdfMergeInfo": "Tüm PDF'leriniz {pdfMergeCount} dosya olarak indirildi.",
      "pdfMergeOnePdfInfo": "PDF Dosyanız İndirildi",
      "title": "e-Faturaları İndir"
    },
    "downloadEDocumentsModalTimeout": {
      "close": "KAPAT",
      "info": "e-Fatura indirme işlemi zaman aşımına uğradı. Lütfen tekrar deneyin.",
      "title": "e-Faturaları İndir"
    },
    "downloadXmlModalInProgresss": {
      "close": "KAPAT",
      "info": "e-Faturalarınız indirilmek üzere hazırlanıyor. Lütfen bekleyin...",
      "not_found_xml": "İndirdiğiniz dosyada {xmlCount} adet fatura yer alacaktır. {missingDocumentNo} numaralı {notFoundCount} adet faturanın XML’i hatalıdır. Bu sebeple indireceğiniz dosyada bu faturanın/faturaların XML’i yer almayacaktır..",
      "progressStatusInfo": "XML indiriliyor.",
      "title": "e-Faturaları İndir",
      "xmlMergeInfo": "İndirme işlemi sürerken hesabınızı kullanabilirsiniz, ancak sürecin başarıyla tamamlanması için tarayıcıyı kapatmayın veya yenilemeyin"
    },
    "downloadXmlModalSuccess": {
      "close": "KAPAT",
      "not_found_xml": "İndirdiğiniz dosyada {xmlCount} adet fatura yer alacaktır. {missingDocumentNo} numaralı {notFoundCount} adet faturanın XML’i hatalıdır. Bu sebeple indireceğiniz dosyada bu faturanın/faturaların XML’i yer almayacaktır..",
      "title": "e-Faturaları İndir",
      "xmlMergeInfo": "XML Dosyanız İndirildi"
    },
    "eInvoiceFormBox": {
      "description": "Hemen aşağıdaki formu doldurun, mükelleflerinize ulaşalım, e-faturaya geçiş süreçlerini başlatalım.",
      "title": "Mükelleflerinizi e-Fatura ile tanıştırın!"
    },
    "eInvoiceInfoBox": {
      "benefits": {
        "discount": {
          "description": "Sizin aracılığınız ile gelen mükellefleriniz özel indirimlerden yararlansın, <span>660 TL + KDV</span> yerine <b>540 TL + KDV</b> ile Paraşüt abonesi olsun.",
          "title": "Paraşüt Aboneliğinde %20 İndirim"
        },
        "service": {
          "description": "Sizin aracılığınızla gelen mükellefleriniz 100 TL değerindeki Anahtar Teslim e-Fatura ek hizmetimizden ücretsiz faydalansın, mükelleflerinizin e-fatura başvuru ve geçiş süreçleri e-fatura uzmanlarımız tarafından tamamlansın.",
          "title": "Ücretsiz Anahtar Teslim e-Fatura Hizmeti"
        },
        "support": {
          "description": "e-Faturaya geçiş süreci ve sonrasında mükelleflerinizin tüm soruları uzman destek ekibimiz tarafından yanıtlansın.",
          "title": "Kullanım ve Eğitim Desteği Bizden"
        },
        "units": {
          "description": "e-Faturaya geçişi tamamlanan mükellefleriniz 120 TL değerindeki 600 e-fatura kontöründen ücretsiz olarak yararlansın, mükellefleriniz Paraşüt’ten dilediği gibi e-fatura ve e-arşiv gönderip almaya başlasın.",
          "title": "600 e-Fatura Kontörü Hediye"
        }
      },
      "description": "Mükelleflerinizi e-fatura ile tanıştırın, hem mükellefleriniz ile uyum içinde çalışın, hem de Paraşüt Atlas ile iş yükünüzü azaltın.  Üstelik, sizin aracılığınız ile e-faturaya geçişte mükellefinize özel avantajlar sunuyoruz.",
      "title": "Mükelleflerinize sunabileceğimiz e-Fatura avantajları!",
      "warning": "Mükelleflerinizin Paraşüt Atlas’a özel avantajlardan yararlanabilmesi için Paraşüt abonesi olması gerekmektedir. 1 günlük e-faturaya geçiş sürecine mali mühür bekleme süresi dahil değildir."
    },
    "emailLogin": {
      "error": "Geçersiz e-posta veya parola.",
      "form": {
        "description": "e-Posta adresiniz ve parolanız ile hesabınıza <br>güvenli giriş yapabilirsiniz.",
        "emailInput": {
          "placeholder": "e-Posta adresiniz"
        },
        "loginButton": "GİRİŞ YAP",
        "or": "— veya —",
        "passwordInput": {
          "placeholder": "Parolanız"
        },
        "rememberMe": "Oturum açık kalsın",
        "resetPasswordWarning": {
          "description": "Yeni parolanız ile Atlas hesabınıza giriş yapabilirsiniz.",
          "title": "Parola sıfırlama işleminiz tamamlandı"
        },
        "title": "Paraşüt Atlas’a hoş geldiniz!"
      },
      "loginViaParasut": "PARAŞÜT HESABIYLA GİRİŞ YAPIN",
      "newAccountLink": "Yeni bir hesap mı açmak istiyorsunuz?",
      "pageTitle": "Giriş Yap",
      "success": "Başarıyla giriş yapıldı."
    },
    "errorList": {
      "description": "İşlem gerçekleştirilemedi. Lütfen aşağıdaki maddeleri gözden geçiriniz.",
      "title": "Dikkat!"
    },
    "exportPlatformSelectionModal": {
      "close": "DAHA SONRA",
      "lucaArea": {
        "description": "Cari ve Satış Hesap Kodlarını ayarlayarak faturalarınızı otomatik olarak Luca’ya aktarabilirsiniz.",
        "title": "Otomatik olarak Luca'ya aktar"
      },
      "otherArea": {
        "description": "Faturalarınızı <b>Zirve</b> uygulamasına uyumlu <b>Excel</b> formatı ile veya <b>Xml</b> olarak dışarı aktarabilirsiniz.",
        "lowerCaseTitle": "Dışarı Aktar",
        "upperCaseTitle": "EXCEL İLE DIŞARI AKTAR"
      },
      "title": "Faturalarınızı aktarmak istediğiniz platformu seçin"
    },
    "generalErrors": {
      "flash": {
        "invitationAccepted": "Bu davet linki kullanılarak daha önce bir hesap oluşturulmuştur",
        "invitationCancelled": "Bu davet mükellefiniz tarafından iptal edilmiştir. Hesap oluşturmanız durumunda bağlantınız kurulmayacaktır",
        "invitationExpired": "Davetin geçerlilik süresi dolmuştur, mükellefinizden tekrar davet göndermesini isteyiniz. Aksi halde bağlantınız kurulmayacaktır",
        "invitationNotFound": "Böyle bir davet bulunamadı"
      }
    },
    "importInvoices": {
      "approveInvoices": {
        "checkDatas": "Verileri kontrol edin",
        "invoicesPrepared": "Faturalarınız hazırlandı",
        "nothingToApprove": "Onaylanacak faturanız yok"
      },
      "cancelButton": "Vazgeç",
      "confirmAndCreateButton": "Onayla ve Faturaları oluştur",
      "createInvoices": {
        "errorWhileCreating": "Faturalarınız oluşturulurken bir hata oluştu",
        "invoicesCreated": "Faturalarınız oluşturuldu"
      },
      "fixAndReuploadButton": "Hatalıları düzelt ve tekrar yükle",
      "flash": {
        "createdSuccessfully": "Faturalarınız oluşturuldu",
        "createdWithErrors": "Faturalarınız oluşturuldu, hatalar var",
        "creationError": "Faturalarınız oluşturulurken bir hata oluştu",
        "timeoutError": "İşleminiz zaman aşımına uğradı",
        "unknownError": "Bilinmeyen bir hata oluştu",
        "validationError": "Faturalarınız doğrulanırken bir hata oluştu"
      },
      "invalidInvoicesModal": {
        "confirm": "Anladım, Faturaları onayla",
        "description": "Devam ettiğiniz takdirde {invalidInvoiceCount} adet hatalı faturalarınız hariç tutularak {validInvoiceCount} adet onaylanmış faturalarınız oluşturulacaktır. İşleminize devam edip daha sonra hatalı satırları düzelterek dosyanızı tekrar yükleyebilirsiniz",
        "header": "Hatalı faturalarınız var",
        "reupload": "Dosyayı düzelt ve tekrar yükle"
      },
      "invoiceProgress": {
        "creatingInvoices": "Faturalarınız oluşturuluyor",
        "fileParsed": "Dosyanız okundu",
        "fileUploaded": "Dosyanız yüklendi",
        "parsingFile": "Dosyanız okunuyor",
        "preparedForCreate": "Onaylanan faturalarınız hazırlandı",
        "preparingForCreate": "Onaylanan faturalarınız hazırlanıyor",
        "preparingInvoices": "Faturalarınız hazırlanıyor",
        "uploadingFile": "Dosyanız yükleniyor..."
      },
      "pageTitle": "Toplu Fatura Oluşturun",
      "reuploadButton": "Düzelt ve tekrar yükle",
      "tabSelector": {
        "approveInvoices": "Faturaları onaylayın",
        "approvedInvoices": "Faturalar onaylandı",
        "createInvoices": "Faturaları oluşturun",
        "createdInvoices": "Faturalar oluşturuldu",
        "uploadTemplate": "Şablonu yükleyin",
        "uploadedTemplate": "Şablon yüklendi"
      },
      "uploadNewButton": "Yeni bir dosya yükle",
      "uploadTemplate": {
        "downloadExcelTemplateDesc": "1. Sizin için hazırladığımız Excel şablonunu indirin.",
        "downloadTemplateButtonText": "Excel şablonunu indir",
        "importInvoiceDetailsDesc": "2. Fatura bilgilerinizi Şablon içerisindeki alanlara aktarın.",
        "saveTemplateAndUploadDesc": "3. Şablonu kaydedip buraya tekrar yükleyin.",
        "uploadTemplateButtonText": "Şablonu geri yükle"
      }
    },
    "importedInvoicesTable": {
      "companyTaxNumber": "Mükellef VKN/TCKN",
      "contactAddress": "Adres",
      "contactCity": "İl",
      "contactColumns": "Fatura Kesilecek Cari Bilgileri",
      "contactDistrict": "İlçe",
      "contactName": "İsim",
      "contactTaxNumber": "VKN",
      "contactTaxOffice": "Vergi Dairesi",
      "currency": "Döviz Cinsi",
      "detailProductDescription": "Hizmet Açıklaması",
      "detailProductName": "Hizmet Adı",
      "detailUnitPrice": "KDV Hariç Toplam Fiyat",
      "detailVatRate": "KDV Oranı",
      "detailVatWithholdingCode": "Vergi Kodu",
      "detailVatWithholdingRate": "Tevkifat Oranı",
      "emptyInfo": "Seçtiğiniz kategoriye ait gösterilecek fatura bulunmamaktadır.",
      "errorMessage": "Hata Mesajı",
      "excelColumns": "Excel Bilgileri",
      "excelRowNumber": "Excel Satır No",
      "exchangeColumns": "Döviz Bilgileri",
      "exchangeRate": "Döviz Kuru",
      "invoiceDetailsColumns": "Fatura Bilgileri",
      "issueDate": "Fatura Tarihi",
      "paidAmount": "Tahsilat TL Karşılığı",
      "scenario": "E-Fatura Senaryo",
      "taxPayerColumns": "Mükellef Bilgileri"
    },
    "inviteUsersModal": {
      "formStep": {
        "body": {
          "explanation": "Ekibinizi Paraşüt Atlas'a davet ederek mükelleflerinizi personellerinize atayabilir, tüm ekibinizin aynı hesap üzerinden çalışmasını sağlayabilirsiniz.",
          "placeholder": "e-Posta adresi"
        },
        "buttons": {
          "addNew": "Personel ekle",
          "close": "DAHA SONRA",
          "saveAndContinue": "DAVET ET"
        },
        "header": {
          "title": "Personelinizi davet edin"
        }
      },
      "infoSmallTitle": "Dijital ofisinizi oluşturun",
      "infoTitle": "Personelleriniz var mı? Onları da ofisinize davet edin!",
      "successInfoStep": {
        "body": {
          "explanation": "Aşağıdaki e-posta adreslerine Paraşüt Atlas'taki <b>{accountantOfficeName}</b> ofisinize personel olarak katılmaları için davet gönderdiniz. Bu davet personeliniz tarafından kabul edildiğinde ofisinize eklenen personeller <b>\"Tüm Personeller\"</b> sekmesinin altında görünecektir.",
          "explanationDetail": "Davetinizi tekrar göndermek veya silmek için <b>\"Onay Bekleyenler\"</b> sekmesini kontrol edebilirsiniz."
        },
        "buttons": {
          "close": "TAMAM"
        },
        "header": {
          "title": "Personel davetiniz gönderildi"
        }
      }
    },
    "invoiceAccountHandler": {
      "hasDifferentInvoiceAccounts": "{count} farklı satış hesap kodu bulunuyor"
    },
    "invoiceDetail": {
      "listRowForm": {
        "accommodationTax": "KV TUTARI",
        "accommodationTaxAccountCode": "KV HESAP KODU",
        "accommodationTaxRate": "KV ORANI",
        "communicationsTax": "ÖİV TUTARI",
        "communicationsTaxAccountCode": "ÖİV HESAP KODU",
        "communicationsTaxRate": "ÖİV ORANI",
        "exciseDuty": "ÖTV TUTARI",
        "exciseDutyRate": "ÖTV ORANI",
        "exciseDutyTaxAccountCode": "ÖTV HESAP KODU",
        "other": "DİĞER V. H. K."
      }
    },
    "invoiceExcelExportModal": {
      "error": {
        "close": "KAPAT",
        "errors": {
          "max_number_exceeded": "Tek seferde en fazla 30000 e-fatura dışarı aktarılabilir.",
          "no_data": "Dışarı aktarılacak e-Fatura bulunamadı.",
          "unknown": "Bilinmeyen bir hata oluştu."
        },
        "title": "e-Faturaları Excel ile Dışarı Aktar"
      },
      "inProgress": {
        "close": "KAPAT",
        "info": "e-Faturalarınız Excel ile dışarı aktarılmak üzere hazırlanıyor. Lütfen bekleyin...",
        "title": "e-Faturaları Excel ile Dışarı Aktar"
      },
      "success": {
        "close": "KAPAT",
        "download": "İNDİR",
        "info": "e-Faturalarınız Excel ile dışarı aktarılmaya hazır. 30 dakika içerisinde indirebilirsiniz.",
        "title": "e-Faturaları Excel ile Dışarı Aktar"
      },
      "timeout": {
        "close": "KAPAT",
        "info": "e-Fatura dışarı aktarma işlemi zaman aşımına uğradı. Lütfen tekrar deneyin.",
        "title": "e-Faturaları Dışarı Aktar"
      }
    },
    "invoiceListRow": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccount": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO"
      },
      "filler": {
        "newContactAccount": "— Yeni Cari Hesap Kaydı —"
      },
      "netTotal": "TOPLAM",
      "netTotalWithoutVat": "KDV'SİZ TUTAR",
      "product": "ÜRÜN/HİZMET",
      "quantity": "MİKTAR",
      "salesAccount": "SATIŞ HESAP KODU",
      "vat": "HESAPLANAN KDV",
      "vatAccount": "KDV HESAP KODU",
      "vatRate": "KDV",
      "vatTaxBase": "MATRAH",
      "vatWithholding": "HESAPLANAN KDV",
      "vatWithholdingRate": "TEVK."
    },
    "invoiceListRowDetail": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccountCode": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "communicationsTax": "ÖİV TUTARI",
      "communicationsTaxAccountCode": "ÖİV HESAP KODU",
      "communicationsTaxRate": "ÖİV ORANI",
      "detailVatWithholdingRate": "{detailVatWithholdingRate}/10",
      "exciseDuty": "ÖTV TUTARI",
      "exciseDutyRate": "ÖTV ORANI",
      "exciseDutyTaxAccountCode": "ÖTV HESAP KODU"
    },
    "invoiceRow": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccount": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO",
        "documentShowInvoice": "Faturayı göster"
      },
      "flash": {
        "notSaved": "Hesap kodlarınız kaydedilemedi"
      },
      "netTotal": "TOPLAM",
      "netTotalWithoutVat": "KDV'SİZ TUTAR",
      "product": "ÜRÜN/HİZMET",
      "quantity": "MİKTAR",
      "salesAccount": "SATIŞ HESAP KODU",
      "vat": "HESAPLANAN KDV",
      "vatAccount": "KDV HESAP KODU",
      "vatRate": "KDV",
      "vatTaxBase": "MATRAH"
    },
    "invoiceStatusCounts": {
      "createdTitle": "Toplam Oluşturulan Fatura",
      "existingTitle": "Zaten Mevcut",
      "incorrectTitle": "Hatalı",
      "readTitle": "Okundu",
      "readyTitle": "Toplam Onaylanacak Fatura"
    },
    "invoiceTabSelector": {
      "columns": {
        "contactAccount": "CARİ HESAP KODU",
        "description": "AÇIKLAMA (CARİ ÜNVANI)",
        "netTotal": "ÖDENECEK TUTAR",
        "netTotalWithoutVat": "KDV HARİÇ TOPLAM",
        "salesAccount": "SATIŞ HESAP KODU",
        "totalTax": "VERGİLER TOPLAMI",
        "totalVat": "TOPLAM KDV",
        "vat1": "KDV1",
        "vat18": "KDV18",
        "vat8": "KDV8"
      },
      "tabs": {
        "done": "Aktarılan <span class=badge>{count}</span>",
        "ready": "Aktarıma Hazır <span class=badge>{count}</span>",
        "waiting": "Onay Bekliyor <span class=badge>{count}</span>"
      }
    },
    "invoiceTable": {
      "columns": {
        "contactAccount": "CARİ HESAP KODU",
        "description": "AÇIKLAMA (CARİ ÜNVANI)",
        "netTotal": "ÖDENECEK TUTAR",
        "netTotalWithoutVat": "KDV HARİÇ TOPLAM",
        "salesAccount": "SATIŞ HESAP KODU",
        "totalTax": "VERGİLER TOPLAMI",
        "totalVat": "TOPLAM KDV",
        "vat1": "KDV1",
        "vat18": "KDV18",
        "vat8": "KDV8"
      },
      "companionEmptyText": "Aktarılacak faturanız bulunmamaktadır.<br>Atlas Asist'te oluşturulan yeni faturalar burada yerini alacak.",
      "parasutEmptyText": "Aktarılacak faturanız bulunmamaktadır.<br>Paraşüt'te oluşturulan yeni faturalar burada yerini alacak."
    },
    "invoiceTagList": {
      "hasVatWithholdingTag": "<span>TEVKİFATLI</span>",
      "isCancelledTag": "<span>İPTAL</span>",
      "isEInvoiceWaitingApprovalTag": "<span>ONAYLANMAMIŞ</span>",
      "isExemptAccommodationTaxTag": "<span>KV İSTİSNA</span>",
      "isForeignCurrencyTag": "<span>DÖVİZLİ</span>",
      "isNotAllowedType": "<span>DESTEKLENMEYEN VERGİ TÜRÜ</span>",
      "isRefundTag": "<span>İADE</span>"
    },
    "invoiceTotalTax": {
      "accommodationTax": "%2 KV: ",
      "communicationsTax": "ÖİV: ",
      "exciseDuty": "ÖTV: ",
      "noTaxDataInfo": "Bu faturada vergi bulunmamaktadır.",
      "sso": "KDV 2",
      "vat1": "%1 KDV: ",
      "vat10": "%10 KDV: ",
      "vat10Withholding": "%10 KDV2: ",
      "vat18": "%18 KDV: ",
      "vat18Withholding": "%18 KDV2: ",
      "vat1Withholding": "%1 KDV2: ",
      "vat20": "%20 KDV: ",
      "vat20Withholding": "%20 KDV2: ",
      "vat8": "%8 KDV: ",
      "vat8Withholding": "%8 KDV2: ",
      "withholding": "Stopaj: "
    },
    "invoiceWithVatWithholdingRow": {
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO",
        "documentShowInvoice": "Faturayı göster"
      },
      "expenseAccountCode": "SATIŞ HESAP KODU",
      "flash": {
        "notSaved": "Hesap kodlarınız kaydedilemedi"
      },
      "netTotal": "TOPLAM",
      "netTotalWithoutVat": "KDV'SİZ TUTAR",
      "product": "ÜRÜN/HİZMET",
      "quantity": "MİKTAR",
      "salesVatWithholdingAccount": "SATIŞ TEVK. HESAP KODU",
      "vatRate": "KDV",
      "vatTaxBase": "MATRAH",
      "vatWithholding": "HESAPLANAN KDV",
      "vatWithholdingAccount": "KDV HESAP KODU",
      "vatWithholdingRate": "TEVK."
    },
    "invoiceWithVatWithholdingRowDetail": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccountCode": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "communicationsTax": "ÖİV TUTARI",
      "communicationsTaxAccountCode": "ÖİV HESAP KODU",
      "communicationsTaxRate": "ÖİV ORANI",
      "detailVatWithholdingRate": "{detailVatWithholdingRate}/10",
      "exciseDuty": "ÖTV TUTARI",
      "exciseDutyAccountCode": "ÖTV HESAP KODU",
      "exciseDutyRate": "ÖTV ORANI",
      "other": "DİĞER V. H. K."
    },
    "invoiceXmlExportModal": {
      "error": {
        "close": "KAPAT",
        "errors": {
          "no_data": "Dışarı aktarılacak e-Fatura bulunamadı.",
          "unknown": "XML indirirken bilinmeyen bir hata ile karşılaşıldı."
        },
        "title": "e-Faturaları XML ile Dışarı Aktar"
      },
      "inProgress": {
        "close": "KAPAT",
        "info": "e-Faturalarınız XML ile dışarı aktarılmak üzere hazırlanıyor. Lütfen bekleyin...",
        "title": "e-Faturaları XML ile Dışarı Aktar"
      },
      "success": {
        "close": "KAPAT",
        "download": "İNDİR",
        "info": "e-Faturalarınız Excel ile dışarı aktarılmaya hazır. 30 dakika içerisinde indirebilirsiniz.",
        "title": "e-Faturaları XML ile Dışarı Aktar"
      },
      "timeout": {
        "close": "KAPAT",
        "info": "XML indirme işleminiz zaman aşımına uğradı.",
        "title": "e-Faturaları Dışarı Aktar"
      }
    },
    "invoicesBulkApproveModal": {
      "buttons": {
        "bulkApproveAll": "TÜMÜNÜ ONAYLA",
        "bulkApproveFiltered": "SEÇİMİ ONAYLA({invoiceCount})"
      }
    },
    "invoicesBulkApproveModalInfo": {
      "button": {
        "cancel": "VAZGEÇ",
        "next": "DEVAM ET"
      },
      "info": "<strong>{invoiceCount}</strong> adet faturanız onaylanarak aktarıma hazır hale getirilecek.",
      "title": "Toplu Onaylama",
      "warning": "<strong>BİLGİ:</strong> Faturalardaki gerekli alanları doldurduğunuzdan emin olun. Eksik bilgisi bulunan faturalar aktarılamaz."
    },
    "invoicesBulkApproveModalSummary": {
      "button": {
        "ok": "TAMAM"
      },
      "reasons": {
        "accommodationTaxErrors": "{count} adet faturanız KV hesap kodunun eksik olması",
        "accountCodeErrors": "{count} adet faturanız satış/gider hesap kodunun eksik olması",
        "commercialInvoiceErrors": "{count} adet faturanız henüz onaylanmamış olması",
        "communicationsOrExciseDutyErrors": "{count} adet faturanız ÖTV/ÖİV hesap kodunun eksik olması",
        "edgeReasonErrors": "{count} adet faturanız teknik istisnalar",
        "finish": "nedeniyle onaylanamadı.",
        "hasNetworkError": "Faturalarınız aktarılamadı. Lütfen tekrar deneyiniz.",
        "unknownError": "{count} adet faturanız teknik bir hata",
        "vatCodeErrors": "{count} adet faturanız KDV hesap kodunun eksik olması",
        "vatWithholdingCodeErrors": "{count} adet faturanız TEVKİFATLI KDV hesap kodunun eksik olması",
        "withholdingErrors": "{count} adet faturanız tevkifat"
      },
      "successCount": "{count} adet faturanız onaylanarak aktarıma hazır hale getirildi.",
      "title": "İşlem tamamlandı!"
    },
    "invoicesBulkApproveModalTransfer": {
      "description": "Lütfen faturalarınız onaylanırken bekleyin...",
      "title": "Faturalarınız onaylanıyor..."
    },
    "leadAccountant": {
      "accountantCity": "<strong>İl</strong>",
      "accountantDistrict": "<strong>İlçe</strong>",
      "accountantName": "<strong>Mali Müşavir Adı Soyadı</strong>",
      "accountantPhone": "<strong>Mali Müşavir Cep Telefonu</strong>",
      "accountantTaxNumber": "<strong>Mali Müşavir VKN</strong>",
      "body1": "<strong> 1-30 Eylül 2024</strong> tarihleri arasında yönlendireceğiniz mükelleflerin <strong> 7 Ekim 2024 </strong> tarihine kadar ödemelerini yaparak hizmetlerini başlatmaları gerekmektedir. Yönlendirdiğiniz mükellefler için ekibimiz sizinle iletişime geçecek ve mükellefleriniz ihtiyaçlarına en uygun çözümleri sunacaklardır.",
      "body2": "Mali Müşavir bilgileriniz sistemde eksik. Tamamlayarak mükellef bilgileri ekranına devam edebilirsiniz.",
      "body3": "*Paraşüt, Bizmu ve e-Portal programlarını önerebilirsiniz.",
      "body4": "Yönlendirdiğiniz mükellefler için ekibimiz sizinle iletişime geçecek ve mükelleflerinizin ihtiyaçlarına en uygun çözümleri sunacaklardır.",
      "callMe": {
        "body": "Satış ekibimizin tarafınıza ulaşarak mükelleflerinizin ihtiyaçlarını anlayıp, size yardımcı olması için isim ve telefon bilginizi girerek gerekli izinleri vermeniz yeterli.",
        "cancel": "Vazgeç",
        "closeAndSave": "Kaydet ve Kapat",
        "description": "Mükellef önermeniz için sizi arayalım.",
        "infoConsent": "Mükellef Öner uygulamasına ilişkin <strong> <u>Bilgi Metni</u></strong>'ni okudum, belirtilen hususları taahhüt ediyor ve onaylıyorum.*",
        "myPhone": "Cep Telefonum",
        "myname": "Adım Soyadım",
        "parasutConsent1": "Paraşüt tarafından  <strong> <u>Aydınlatma Metni</u></strong> uyarınca tarafıma ticari elektronik ileti gönderilmesine",
        "parasutConsent2": "<strong> <u>Açık Rıza Metni</u></strong> kapsamında onay veriyorum.*",
        "success": "Talebinizi aldık. Satış ekibimiz en kısa sürede sizinle iletişime geçecek."
      },
      "consent": "Kişisel verilerimin tarafıma daha etkin bir hizmet verilebilmesi amacıyla yurt dışında yer alan tedarikçiye aktarılmasına <strong> <u>Açık Rıza Metni</u></strong> kapsamında onay veriyorum.*",
      "continueLaterButton": "Vazgeç",
      "continueWithLeadTaxpayer": "Kaydet ",
      "header": "Mükellef Önerin, e-Faturaya Gelen Her Mükellefte Hediye Çeki Kazanın!",
      "header2": "Mükelleflerinizi Mikrogrup programlarımıza getirin, getirdiğiniz her mükellefte <strong>e-Portal ve Bizmu</strong> için <strong>500₺</strong>; <strong>Paraşüt</strong> için <strong>1.000₺</strong> tutarında hediye çeki kazanın!",
      "kvkk": "<strong> <u>Kişisel Veriler Aydınlatma Metni </u></strong>'ni okudum, anladım.*",
      "sendLead": "Mükellef Öner",
      "sendLeadwithForm": "Mükellefi form üzerinden kendim önermek istiyorum.",
      "usedProgram": "<strong>Mali Müşavir Uygulaması</strong>"
    },
    "login": {
      "announcements": "DUYURULAR",
      "email": "E-POSTA",
      "emailAddress": "iletisim@parasutatlas.com",
      "error": "Giriş yaparken bir hata oluştu.",
      "greeting": "Hoş geldiniz. Atlas'a Paraşüt hesabınız ile <br>güvenli giriş yapabilirsiniz.",
      "info": "Atlas hakkında soru ve önerileriniz için:",
      "or": "veya",
      "pageTitle": "Paraşüt Atlas'a giriş yapın",
      "password": "PAROLA",
      "phoneNumber": "+90 (212) 963 00 30",
      "submit": "GİRİŞ YAP",
      "success": "Başarıyla giriş yapıldı."
    },
    "luca": {
      "pageTitles": {
        "dataWrite": "Veri Aktarma",
        "index": "Luca"
      },
      "pleaseWait": "Lütfen Bekleyiniz..."
    },
    "lucaAccountingTypeSelect": {
      "placeholder": "Luca hesabının defter türünü seçiniz"
    },
    "lucaCompany": {
      "flash": {
        "timeoutError": "İşleminiz zaman aşımına uğradı",
        "unknownError": "Bilinmeyen bir hata oluştu"
      }
    },
    "lucaCompanySelect": {
      "noResult": "Eşleştirilmemiş Luca şirketi bulunamadı.",
      "placeholder": "Eşleştirilecek Luca hesabını seçiniz"
    },
    "lucaConnectionErrorModal": {
      "cancel": "BAĞLANMADAN DEVAM ET",
      "retry": "YAPTIM, DEVAM ET",
      "signInToLuca": "LUCA'YA GİRİŞ YAP",
      "suggestion": "Veri alış-verişi yapabilmek için<br>Luca hesabınıza giriş yapmanız gerekiyor.",
      "title": "Luca hesabınıza bağlanın"
    },
    "lucaCreateAccountModal": {
      "cancel": "VAZGEÇ",
      "contactCreateResultModal": {
        "error": {
          "cancel": "TAMAM",
          "header": "Hesap oluşturulamadı",
          "message": "Sistemsel bir hata oluştu."
        },
        "success": {
          "cancel": "TAMAM",
          "header": "Yeni cari hesap kaydı tamamlandı",
          "message": "Luca hesabınızda cari hesap kaydı oluşturulup, faturanıza tanımlanmıştır."
        }
      },
      "contactStateInfoModal": {
        "error": {
          "cancel": "TAMAM",
          "header": "Yeni cari hesap kaydı oluşturulamıyor",
          "message": "Sistemsel bir hata oluştu."
        },
        "success": {
          "accountCode": "YENİ CARİ HESAP KODU",
          "approve": "HESABI LUCA'DA OLUŞTUR",
          "cancel": "VAZGEÇ",
          "city": "İL",
          "district": "İLÇE",
          "header": "Bilgileri Onaylayın",
          "taxNumber": "VERGİ NUMARASI",
          "taxOffice": "VERGİ DAİRESİ",
          "textAddress": "AÇIK ADRES",
          "title": "ÜNVAN"
        }
      }
    },
    "lucaDataWrite": {
      "loading": "Yükleniyor...",
      "pleaseWait": "Lütfen Bekleyiniz..."
    },
    "lucaExportModal": {
      "archiveOrRetry": {
        "archive": "AKTARILDI OLARAK İŞARETLE",
        "archiveInfo": {
          "bankTx": {
            "infoText": "<strong>{period}</strong> {dateString} ait <strong>{bankTransactionsCount}</strong> adet banka hareketiniz, aşağıdaki değerler ile aktarılmıştır. Herhangi bir sorun varsa tekrar deneyebilir, yoksa aktarıldı olarak işaretleyebilirsiniz.",
            "totalCredit": "BORÇ",
            "totalDebit": "ALACAK"
          },
          "date": "tarihine",
          "dates": "tarihlerine",
          "invoice": {
            "PurchaseBill": "alış",
            "SalesInvoice": "satış",
            "infoText": "<strong>{period}</strong> {dateString} ait <strong>{invoicesCount}</strong> adet <strong>{invoiceType}</strong> faturanız, aşağıdaki değerler ile aktarılmıştır. Herhangi bir sorun varsa tekrar deneyebilir, yoksa aktarıldı olarak işaretleyebilirsiniz.",
            "netTotal": "KDV'Lİ TUTAR",
            "netTotalWithoutVat": "KDV HARİÇ TUTAR",
            "totalVat": "KDV TUTARI"
          }
        },
        "bankTxDescription": "Aktarılan banka hareketleriniz, ismi ile bir grup altında toplanacaktır.\nBu ismi aşağıdaki alandan değiştirebilirsiniz.",
        "close": "VAZGEÇ",
        "errorOccuredOnNewReceiptExportGroup": "Bir hata oluştu, lütfen tekrar deneyin.",
        "flash": {
          "error": "Bilinmeyen bir hata oluştu."
        },
        "invoiceDescription": "Aktarılan faturalarınız, ismi ile bir grup altında toplanacaktır.\nBu ismi aşağıdaki alandan değiştirebilirsiniz.",
        "nameInputLabel": "AKTARIM GRUBU ADI",
        "retry": "TEKRAR DENE",
        "title": "Aktarım'ı Onayla"
      },
      "export": "FATURALARI AKTAR",
      "exportAll": "MUHASEBELEŞTİR",
      "exportFiltered": "SEÇİMİ MUHASEBELEŞTİR({invoiceCount})",
      "exportOrCancel": {
        "BankTransaction": "banka hareketi",
        "PurchaseBill": "alış faturası",
        "SalesInvoice": "satış faturası",
        "cancel": "VAZGEÇ",
        "deletedInvoiceInfo": "Bu aktarım grubu silinmiş fatura içermektedir. Silinmiş faturalar LUCA'ya aktarılamayacaktır. Bu faturaları 'Onay Bekliyor' ekranına geri taşımanız durumunda fatura silinecektir.",
        "export": "AKTAR ({invoiceCount})",
        "exportText": "<strong>{parasutCompanyName}</strong> adlı şirketin <strong>{taxPeriodName}</strong> dönemine ait <strong>{invoiceCount}</strong> adet <strong>{invoiceType}</strong> LUCA'ya aktarılacaktır. Bu sayfaya geri gelerek faturaları aktarıldı olarak işaretleyebilirsiniz.",
        "transferWithVatWithholding": "Tevkifatlı faturaları aktar",
        "transferWithoutVatWithholding": "Satış faturalarını aktar",
        "vatWithholdingInfo": "Bu aktarım grubu tevkifatlı fatura içermektedir. Tevkifatlı ve normal satış faturalarınızın ayrı gruplar halinde aktarılması gerekmektedir. Lütfen aktarmak istediginiz fatura türünü seçiniz:"
      },
      "title": "Luca'ya Aktar"
    },
    "missingAccountCodeErrorModal": {
      "buttons": {
        "close": "KAPAT",
        "closeLater": "DAHA SONRA",
        "openCompanyMatchingModal": "HESAP AYARLARINI TAMAMLA"
      },
      "errors": {
        "bank_account_code_not_found": "<b>Banka hesap kodu</b> bulunamadı, Lütfen banka hareketinizdeki <b>'Banka Hesap Kodu'</b> alanını doldurun.",
        "bank_transaction_mark_as_ready_error": "Banka hareketi aktarıma hazır durumuna getirilemedi.",
        "cancelled_invoice": "İptal faturalarda işlem yapılamaz.",
        "cannot_update_if_vat_withholding_exists": "Tevkifatı bulunan faturalarda işlem yapılamaz.",
        "contact_account_not_found": "<b>Cari hesap kodu</b> bulunamadı, Lütfen banka hareketinizdeki <b>'Hesap Kodu'</b> alanını doldurun.",
        "detail_has_invalid_quantity_edge_reason": "Fatura detayında geçersiz miktar bulunduğu için aktarılamıyor.",
        "detail_has_invalid_vat_rate_edge_reason": "Fatura detayında geçersiz KDV oranı bulunduğu için aktarılamıyor.",
        "has_basic_purchase_bill_not_allowed_vat_rate_edge_reason": "KDV oranı geçersiz olduğundan aktarılamıyor.",
        "has_invoice_vat_empty_edge_reason": "KDV oranı bilinmediğinden aktarılamıyor.",
        "invoice_blank": "Fatura bulunamadı!",
        "invoice_should_be_waiting": "Bu işlemi yalnızca onay bekleyen faturalarda yapabilirsiniz.",
        "net_total_without_vat_less_than_zero": "KDV hariç tutar 0'dan küçük olduğu için aktarılamıyor.",
        "purchase_invoice_account_not_found": "<b>Gider hesap kodu</b> bulunamadı, Lütfen faturanızdaki <b>'Gider Hesap Kodu'</b> alanını doldurun.",
        "purchase_invoice_communications_tax_account_not_found": "<b>Alış ÖİV</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_invoice_excise_duty_account_not_found": "<b>Alış ÖTV</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_invoice_vat_account_10_not_found": "<b>HESAPLANAN KDV %10</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_invoice_vat_account_18_not_found": "<b>HESAPLANAN KDV %18</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_invoice_vat_account_1_not_found": "<b>HESAPLANAN KDV %1</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_invoice_vat_account_20_not_found": "<b>HESAPLANAN KDV %20</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_invoice_vat_account_8_not_found": "<b>HESAPLANAN KDV %8</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_invoice_vat_account_not_found": "<b>HESAPLANAN KDV</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_other_vat_withholding_10_account_not_found": "<b>Sorumlu adına ödenen tevkifat %10</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_other_vat_withholding_18_account_not_found": "<b>Sorumlu adına ödenen tevkifat %18</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_other_vat_withholding_1_account_not_found": "<b>Sorumlu adına ödenen tevkifat %1</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_other_vat_withholding_20_account_not_found": "<b>Sorumlu adına ödenen tevkifat %20</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_other_vat_withholding_8_account_not_found": "<b>Sorumlu adına ödenen tevkifat %8</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_refund_account_not_found": "<b>Gider hesap kodu</b> bulunamadı, Lütfen faturanızdaki <b>'Gider Hesap Kodu'</b> alanını doldurun.",
        "purchase_refund_communications_tax_account_not_found": "<b>Alış ÖİV</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_refund_excise_duty_account_not_found": "<b>Alış iade ÖTV</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_refund_vat_account_10_not_found": "<b>Alınan iade KDV %10</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_refund_vat_account_18_not_found": "<b>Alınan iade KDV %18</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_refund_vat_account_1_not_found": "<b>Alınan iade KDV %1</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_refund_vat_account_20_not_found": "<b>Alınan iade KDV %20</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_refund_vat_account_8_not_found": "<b>Alınan iade KDV %8</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_vat_withholding_10_account_not_found": "<b>Alış Tevkifat %10</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_vat_withholding_18_account_not_found": "<b>Alış Tevkifat %18</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_vat_withholding_1_account_not_found": "<b>Alış Tevkifat %1</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_vat_withholding_20_account_not_found": "<b>Alış Tevkifat %20</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "purchase_vat_withholding_8_account_not_found": "<b>Alış Tevkifat %8</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_accommodation_tax_account_0_not_found": "<b>Satış KV %0 hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_accommodation_tax_account_2_not_found": "<b>Satış KV %2 hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_account_not_found": "<b>Satış hesap kodu</b> bulunamadı, Lütfen faturanızdaki <b>'Satış Hesap Kodu'</b> alanını doldurun.",
        "sales_invoice_communications_tax_account_not_found": "<b>Satış ÖİV</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_excise_duty_account_not_found": "<b>Satış ÖTV</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_account_10_not_found": "<b>Satış KDV %10</b> için hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_account_18_not_found": "<b>Satış KDV %18</b> için hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_account_1_not_found": "<b>Satış KDV %1</b> için hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_account_20_not_found": "<b>Satış KDV %20</b> için hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_account_8_not_found": "<b>Satış KDV %8</b> için hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_withholding_account_10_not_found": "<b>Satış Tevkifat %10</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_withholding_account_18_not_found": "<b>Satış Tevkifat %18</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_withholding_account_1_not_found": "<b>Satış Tevkifat %1</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_withholding_account_20_not_found": "<b>Satış Tevkifat %20</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_invoice_vat_withholding_account_8_not_found": "<b>Satış Tevkifat %8</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_accommodation_tax_account_0_not_found": "<b>İade KV %0 hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_accommodation_tax_account_2_not_found": "<b>İade KV %2 hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_account_not_found": "<b>Satış hesap kodu</b> bulunamadı, Lütfen faturanızdaki <b>'Satış Hesap Kodu'</b> alanını doldurun.",
        "sales_refund_communications_tax_account_not_found": "<b>İade ÖİV</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_excise_duty_account_not_found": "<b>İade ÖTV</b> hesap kodu kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_account_10_not_found": "<b>İade KDV %10</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_account_18_not_found": "<b>İade KDV %18</b> hesap kodu kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_account_1_not_found": "<b>İade KDV %1</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_account_20_not_found": "<b>İade KDV %20</b> hesap kodu kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_account_8_not_found": "<b>İade KDV %8</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_withholding_account_10_not_found": "<b>Satış Tevkifat %10</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_withholding_account_18_not_found": "<b>Satış Tevkifat %18</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_withholding_account_1_not_found": "<b>Satış Tevkifat %1</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_withholding_account_20_not_found": "<b>Satış Tevkifat %20</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "sales_refund_vat_withholding_account_8_not_found": "<b>Satış Tevkifat %8</b> hesap kodu bulunamadı. Lütfen hesap ayarlarınızı tamamlayın.",
        "telecom_invoice": "Fatura detaylarında ÖİV bulunamadığı için aktarılamıyor. Bu faturayı onay bekliyor durumunda bırakarak diğer faturalarınızı aktarmaya devam edebilirsiniz."
      },
      "title": "Hesap kodu ayarlarınızı tamamlayın",
      "warning": "Faturanızın <b>'Aktarıma Hazır'</b> hale gelebilmesi için aşağıda listelenen eksik bilgileri doldurunuz."
    },
    "navBar": {
      "customers": "MÜKELLEFLER",
      "developments": "GELİŞTİRMELER",
      "eInvoice": "e-FATURA",
      "importInvoice": "TOPLU FATURA",
      "logOut": "ÇIKIŞ",
      "staffMembers": "PERSONELLER",
      "support": "DESTEK"
    },
    "newContractsModal": {
      "agreement": "Kullanıcı Sözleşmesi",
      "agreementText": "’ni okudum, onaylıyorum. *",
      "approve": "ONAYLIYORUM",
      "clarification": "Aydınlatma Metni",
      "clarification2": "Aydınlatma Metni",
      "clarification2Text": "Paraşüt tarafından",
      "clarification2Text2": "uyarınca tarafıma ticari elektronik ve ileti gönderilmesine",
      "clarificationText1": "uyarınca kişisel verilerimin işlenmesine ve aktarılmasına",
      "infoSmallTitle": "Paraşüt Atlas olarak sizlere daha iyi bir hizmet sunabilmek adına yakın zamanda kullanıcı sözleşmelerimizi güncelledik.",
      "infoTitle": "Hizmetlerimizi kesintisiz kullanmaya devam etmek için lütfen aşağıdaki sözleşmeleri onaylayın.",
      "kvkk": "Açık Rıza Metni",
      "kvkk2": "Açık Rıza Metni",
      "kvkk2Text": "kapsamında onay veriyorum.",
      "kvkkText": "kapsamında açık rıza veriyorum. *",
      "pageTitle": "Sözleşmelerimiz Güncellendi",
      "signOut": "UYGULAMADAN ÇIK"
    },
    "pDatePickerWithPresets": {
      "days": "{days} GÜN",
      "sameDay": "AYNI GÜN"
    },
    "pDateRangePicker": {
      "apply": "UYGULA",
      "cancel": "VAZGEÇ",
      "previousMonth": "GEÇEN AY",
      "previousQuarter": "GEÇEN ÇEYREK",
      "previousYear": "GEÇEN YIL",
      "quickChoose": "HIZLI SEÇİM",
      "rangeEnd": "BİTİŞ TARİHİ",
      "rangeStart": "BAŞLANGIÇ TARİHİ",
      "thisMonth": "BU AY",
      "thisQuarter": "BU ÇEYREK",
      "thisYear": "BU YIL"
    },
    "pFilterCollectionWithCheckboxes": {
      "apply": "UYGULA",
      "cancel": "VAZGEÇ"
    },
    "pFilterCollectionWithDate": {
      "customDate": "TARİH ARALIĞI..."
    },
    "pFilterContainer": {
      "doFilter": "FİLTRELE",
      "removeAll": "TÜMÜNÜ KALDIR"
    },
    "pListAggregates": {
      "recordCount": "{count} Kayıt"
    },
    "pListBody": {
      "noResults": "ARADIĞINIZ KRİTERLERE <br>UYGUN KAYIT BULUNMUYOR",
      "removeFilters": "KRİTERLERİ KALDIR"
    },
    "pListToggle": {
      "allRecords": "TÜM KAYITLAR",
      "archivedRecords": "ARŞİVLENMİŞ KAYITLAR",
      "cancelledRecords": "İPTAL EDİLMİŞ KAYITLAR",
      "recurranceTemplates": "TEKRARLAMA ŞABLONLARI"
    },
    "pMonthPicker": {
      "loadNext": "DAHA SONRA...",
      "loadPrevious": "DAHA ÖNCE..."
    },
    "pOneWayAutoComplete": {
      "existing": "KAYITLI",
      "new": "YENİ"
    },
    "pPaginationInfo": {
      "text": "{totalCount} kayıttan {rangeStart}-{rangeEnd} arası gösteriliyor."
    },
    "pSearchBox": {
      "activePlaceholder": "İçerisinde ara...",
      "blankPlaceholder": "Ara...",
      "parasutCompanyPlaceholder": "Mükellef adı ile arayın"
    },
    "parasutCompanyList": {
      "accountingType": {
        "basic": "İşletme Defteri",
        "none": "Vergi dönemi oluştur",
        "standard": "Bilanço Defteri"
      },
      "clients": "MÜKELLEF",
      "createCompanyButton": "Atlas Asist Hesabı Oluştur",
      "currentMonth": "GÜNCEL DÖNEM ({monthName})",
      "emptyState": {
        "afterSearchTitle": "\"{searchQuery}\" böyle bir mükellefiniz yok",
        "explanation": "Eğer mükellefiniz Paraşüt uygulamalarından birini kullanıyorsa; bu uygulamaların Ayarlar bölümünde yer alan <b>Firma Bilgileri > Müşavir Ofis Bilgileriniz > Davet Et </b> aksiyonu ile sizi davet etmesini isteyebilirsiniz.",
        "secondExplanation": "Mükellefiniz <b>{currentUserEmail}</b> adresine kullanıcı daveti gönderdiğinde bir e-posta ile bilgilendirileceksiniz.</br>Daveti kabul ettiğinizde mükellefiniz bu ekranda görüntülenecektir.",
        "title": "Kayıtlı bir mükellefiniz yok"
      },
      "new": "YENİ",
      "nextMonth": "GELECEK DÖNEM ({monthName})",
      "settings": "AYARLAR",
      "staffMember": "ATANMIŞ PERSONEL",
      "title": "İşlem yapacağınız mükellefi seçin"
    },
    "parasutCompanyMeta": {
      "purchases": {
        "count": "{count}",
        "title": "Gider"
      },
      "sales": {
        "count": "{count}",
        "title": "Satış"
      }
    },
    "pdfMerge": {
      "flash": {
        "allPdfSuccessfullyDownloaded": "Seçtiğiniz tüm faturalar PDF olarak indirildi.",
        "partiallyPdfSuccessfullyDownloaded": "{currentBatch}. PDF indirildi."
      }
    },
    "portalIntegrationRegister": {
      "acceptButon": "Onayla",
      "alreadyExistError": "Bu şirket ile bağlantınız mevcut. Mükellef listesinden şirkete erişebilirsiniz.",
      "back": "Geri",
      "branchCode": "Şube Kodu",
      "branchCodePlaceHolder": "Şube Kodu",
      "cancel": "Vazgeç",
      "confirmationModalDesc": "Aşağıda yer alan bilgilerin doğruluğundan emin olduktan sonra bağlantının oluşmasını için Onayla butonuna veya bağlantının oluşmasını istemiyorsanız Reddet butonlarına basarak ilerleyebilirsiniz.",
      "connectionExistWithAnotherOffice": "Bu şirket Atlas'ta başka bir mali müşavirlik ofisinde kayıtlıdır. Lütfen bu bağlantıyı kaldırıp tekrar deneyiniz.",
      "connectionExists": "Bu şirket ile bağlantınız mevcut.",
      "continue": "Devam Et",
      "declineButon": "Reddet",
      "later": "Daha sonra",
      "leadAccountant": "Mükellefinizi Mikrogrup'a mı geçirmek istiyorsunuz?<strong> <u>Davet Edin.</u></strong>",
      "modalHeading": "e-Portal hesabı bağlayın",
      "modalLabel": "Atlas - Mikrogrup bağlantısı kurun",
      "notFoundError": "Bağlantı kurulamadı. Girdiğiniz bilgileri kontrol edip tekrar deneyin.",
      "parolaPlaceholder": "e-Portal Parolası",
      "password": "e-Portal Parolası",
      "registerButton": "Mükellef Ekle",
      "registerDescription": "Mükellefinizin e-Portal hesap bilgilerini girerek Atlas üzerindeki şirket kaydını oluşturabilirsiniz.",
      "saveAndContinue": "Kaydet ve devam et",
      "submit": "Kaydet",
      "succesfullRegister": "Mükellefinizle bağlantınız kuruldu.",
      "turnBack": "Geri Dön",
      "unknownError": "Bilinmeyen bir hata oluştu. Lütfen daha  sonra tekrar deneyiniz.",
      "unvan": "Şirket Ünvanı",
      "vkn": "VKN/TCKN",
      "vknPlaceholder": "VKN/TCKN",
      "wrongInputError": "Girdiğiniz bilgilerin formatı hatalı. Lütfen kontrol edip tekrar deneyiniz"
    },
    "processStatusTabSelector": {
      "tabs": {
        "done": "Aktarılan <span class=badge>{count}</span>",
        "ready": "Aktarıma Hazır <span class=badge>{count}</span>",
        "waiting": "Onay Bekliyor <span class=badge>{count}</span>"
      }
    },
    "productAutocomplete": {
      "placeholder": "Ürün adını ya da kodunu giriniz"
    },
    "purchaseBillBasicListRow": {
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO",
        "documentPurchaseBillImage": "Fiş / Fatura görseli",
        "documentShowInAsist": "Asist'te göster",
        "documentShowInBizmu": "Bizmu'da göster",
        "documentShowInParasut": "Paraşüt'te göster",
        "documentShowInPortal": "Portal'da göster"
      },
      "filler": {
        "newContactAccount": "— Yeni Cari Hesap Kaydı —"
      },
      "netTotal": "KDV'Lİ TUTAR",
      "supplier": "TEDARİKÇİ",
      "vatAccount": "KDV HESAP KODU"
    },
    "purchaseBillDetailListRowForm": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccountCode": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "communicationsTax": "ÖİV TUTARI",
      "communicationsTaxAccountCode": "ÖİV HESAP KODU",
      "communicationsTaxRate": "ÖİV ORANI",
      "exciseDuty": "ÖTV TUTARI",
      "exciseDutyRate": "ÖTV ORANI",
      "exciseDutyTaxAccountCode": "ÖTV HESAP KODU",
      "other": "DİĞER V. H. K."
    },
    "purchaseBillListRow": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccount": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentLinkNotReady": "PDF hazır değil",
        "documentNo": "EVRAK NO",
        "documentPurchaseBillImage": "Fiş / Fatura görseli",
        "documentShowInAsist": "Asist'te göster",
        "documentShowInBizmu": "Bizmu'da göster",
        "documentShowInParasut": "Paraşüt'te göster",
        "documentShowInPortal": "Portal'da göster",
        "documentShowInvoice": "Faturayı göster"
      },
      "filler": {
        "newContactAccount": "— Yeni Cari Hesap Kaydı —"
      },
      "netTotal": "TOPLAM",
      "netTotalWithoutVat": "KDV'SİZ TUTAR",
      "product": "ÜRÜN/HİZMET",
      "purchaseAccount": "GİDER HESAP KODU",
      "quantity": "MİKTAR",
      "vat": "KDV TUTARI",
      "vatAccount": "KDV HESAP KODU",
      "vatRate": "KDV",
      "vatTaxBase": "MATRAH"
    },
    "purchaseBillListRowDetail": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccountCode": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "communicationsTax": "ÖİV TUTARI",
      "communicationsTaxAccountCode": "ÖİV HESAP KODU",
      "communicationsTaxRate": "ÖİV ORANI",
      "exciseDuty": "ÖTV TUTARI",
      "exciseDutyRate": "ÖTV ORANI",
      "exciseDutyTaxAccountCode": "ÖTV HESAP KODU",
      "ssoAccountCode": "KDV 2 HESAP KODU"
    },
    "purchaseBillReadyOrDoneTable": {
      "emptyText": "Aktarıma hazır faturanız bulunmamaktadır.<br>Bekleyen faturalarınızı onaylayarak Luca'ya aktarıma hazır hale getirebilirsiniz.",
      "header": {
        "contactAccount": "CARİ HESAP KODU",
        "description": "AÇIKLAMA (CARİ ÜNVANI)",
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO",
        "netTotal": "ÖDENECEK TUTAR",
        "netTotalWithoutVat": "KDV HARİÇ TOPLAM",
        "purchaseAccount": "GİDER HESAP KODU",
        "totalTax": "VERGİLER TOPLAMI",
        "totalVat": "TOPLAM KDV",
        "vat1": "KDV1",
        "vat18": "KDV18",
        "vat8": "KDV8"
      }
    },
    "purchaseBillRow": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccount": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentLinkNotReady": "PDF hazır değil",
        "documentNo": "EVRAK NO",
        "documentPurchaseBillImage": "Fiş / Fatura görseli",
        "documentShowInAsist": "Asist'te göster",
        "documentShowInBizmu": "Bizmu'da göster",
        "documentShowInParasut": "Paraşüt'te göster",
        "documentShowInPortal": "Portal'da göster",
        "documentShowInvoice": "Faturayı göster"
      },
      "discountVatAccount": "İND. KDV HESAP KODU",
      "flash": {
        "notSaved": "Hesap kodlarınız kaydedilemedi"
      },
      "invoiceAccount": "GİDER HESAP KODU",
      "invoiceHasEdgeDetailVatEmpty": "Fatura detaylarının KDV oranları bilinmediğinden fatura tek kalem olarak aktarılacaktır",
      "netTotal": "TOPLAM",
      "netTotalWithoutVat": "KDV'SİZ TUTAR",
      "product": "ÜRÜN/HİZMET",
      "quantity": "MİKTAR",
      "vat": "KDV TUTARI",
      "vatAccount": "KDV HESAP KODU",
      "vatRate": "KDV",
      "vatTaxBase": "MATRAH"
    },
    "purchaseBillTable": {
      "columns": {
        "contactAccount": "CARİ HESAP KODU",
        "description": "AÇIKLAMA (CARİ ÜNVANI)",
        "netTotal": "ÖDENECEK TUTAR",
        "netTotalWithoutVat": "KDV HARİÇ TOPLAM",
        "purchaseAccount": "GİDER HESAP KODU",
        "totalTax": "VERGİLER TOPLAMI",
        "totalVat": "TOPLAM KDV",
        "vat1": "KDV1",
        "vat18": "KDV18",
        "vat8": "KDV8"
      },
      "companionEmptyText": "Aktarılacak faturanız bulunmamaktadır.<br>Atlas Asist'te oluşturulan yeni faturalar burada yerini alacak.",
      "parasutEmptyText": "Aktarılacak faturanız bulunmamaktadır.<br>Paraşüt'te oluşturulan yeni faturalar burada yerini alacak."
    },
    "purchaseBillTagList": {
      "hasVatWithholdingTag": "<span>TEVKİFATLI</span>",
      "isCancelledTag": "<span>İPTAL</span>",
      "isDeletedTag": " <span>SİLİNMİŞ</span>",
      "isEInvoiceWaitingApprovalTag": "<span>ONAYLANMAMIŞ</span>",
      "isExemptAccommodationTaxTag": "<span>KV İSTİSNA</span>",
      "isForeignCurrencyTag": "<span>DÖVİZLİ</span>",
      "isNotAllowedType": "<span>DESTEKLENMEYEN VERGİ TÜRÜ</span>",
      "isRefundTag": "<span>İADE</span>"
    },
    "purchaseBillWithVatWithholdingRow": {
      "columns": {
        "documentDate": "EVRAK TARİHİ",
        "documentLinkNotReady": "PDF hazır değil",
        "documentNo": "EVRAK NO",
        "documentShowInAsist": "Asist'te göster",
        "documentShowInBizmu": "Bizmu'da göster",
        "documentShowInParasut": "Paraşüt'te göster",
        "documentShowInPortal": "Portal'da göster",
        "documentShowInvoice": "Faturayı göster"
      },
      "deductibleVatWithholding": "İNDİRİLECEK KDV",
      "expenseAccountCode": "GİDER HESAP KODU",
      "flash": {
        "notSaved": "Hesap kodlarınız kaydedilemedi"
      },
      "netTotal": "TOPLAM",
      "netTotalWithoutVat": "KDV'SİZ TUTAR",
      "product": "ÜRÜN/HİZMET",
      "quantity": "MİKTAR",
      "salesVatWithholdingAccount": "SATIŞ TEVK. HESAP KODU",
      "vatRate": "KDV",
      "vatTaxBase": "MATRAH",
      "vatWithholding": "KDV 2",
      "vatWithholdingAccount": "KDV HESAP KODU",
      "vatWithholdingRate": "TEVK."
    },
    "purchaseBillWithVatWithholdingRowDetail": {
      "accommodationTax": "KV TUTARI",
      "accommodationTaxAccountCode": "KV HESAP KODU",
      "accommodationTaxRate": "KV ORANI",
      "communicationsTax": "ÖİV TUTARI",
      "communicationsTaxAccountCode": "ÖİV HESAP KODU",
      "communicationsTaxRate": "ÖİV ORANI",
      "detailVatWithholdingRate": "{detailVatWithholdingRate}/10",
      "exciseDuty": "ÖTV TUTARI",
      "exciseDutyAccountCode": "ÖTV HESAP KODU",
      "exciseDutyRate": "ÖTV ORANI",
      "other": "DİĞER V. H. K.",
      "ssoAccountCode": "KDV 2 HESAP KODU",
      "ssoTax": "KDV 2",
      "vatWithholdingAccountCode": "Tevk. KDV Hesap Kodu"
    },
    "readyOrDoneTable": {
      "emptyText": "Aktarıma hazır faturanız bulunmamaktadır.<br>Bekleyen faturalarınızı onaylayarak Luca'ya aktarıma hazır hale getirebilirsiniz.",
      "header": {
        "contactAccount": "CARİ HESAP KODU",
        "description": "AÇIKLAMA (CARİ ÜNVANI)",
        "documentDate": "EVRAK TARİHİ",
        "documentNo": "EVRAK NO",
        "netTotal": "ÖDENECEK TUTAR",
        "netTotalWithoutVat": "KDV HARİÇ TOPLAM",
        "salesAccount": "SATIŞ HESAP KODU",
        "totalTax": "VERGİLER TOPLAMI",
        "totalVat": "TOPLAM KDV",
        "vat1": "KDV1",
        "vat18": "KDV18",
        "vat8": "KDV8"
      }
    },
    "receiptExportGroupDeleteModal": {
      "buttons": {
        "cancel": "VAZGEÇ",
        "delete": "GERİ AL",
        "indexDelete": "AKTARIM GRUBUNU GERİ AL"
      },
      "explanation": {
        "text1": "Grup içindeki {count} adet fatura Aktarıma Hazır durumuna geri alınacaktır.",
        "text1BankTx": "Grup içindeki {count} adet banka hareketi Aktarıma Hazır durumuna geri alınacaktır.",
        "text2": "Grup, Aktarılan listenizden çıkarılacaktır.",
        "title": "Geri alma işleminin sonucunda:"
      },
      "question": "<b>{name}</b> isimli aktarım grubunu geri almak istediğinizden emin misiniz?",
      "result": {
        "success": "Aktarım grubu başarıyla geri alındı."
      },
      "title": "Geri alma işlemini onaylayın"
    },
    "receiptExportGroupTable": {
      "columns": {
        "bankTransactionCount": "BANKA HAREKETİ ADEDİ",
        "exportDate": "AKTARIM TARİHİ",
        "exportGroup": "AKTARIM GRUBU",
        "invoicesCount": "FATURA ADEDİ",
        "netTotal": "KDV'Lİ TUTAR",
        "netTotalWithoutVat": "KDV'SİZ TUTAR",
        "totalVat": "KDV TUTARI"
      },
      "emptyText": "Herhangi bir aktarım grubunuz bulunmamaktadır.<br>Atlas'ta oluşturulan yeni aktarım grupları burada yerini alacak."
    },
    "resetPassword": {
      "description": "Hesabınız için yeni parolanızı belirleyerek internet bağlantısı bulunan tüm bilgisayarlardan Paraşüt Atlas hesabınıza güvenli bir şekilde giriş yapabilirsiniz.",
      "flash": {
        "notSaved": "Parolanız yenilenemedi.",
        "unmatchedPasswords": "Parolalar uyuşmuyor."
      },
      "loginFooter": {
        "login": "Kullanıcı Girişi",
        "signUp": "Yeni Kayıt"
      },
      "pageTitle": "Parolanızı sıfırlayın",
      "password": "PAROLANIZI GİRİN",
      "passwordConfirmation": "PAROLANIZI DOĞRULAYIN",
      "submit": "KAYDET",
      "title": "Parolanızı sıfırlayın"
    },
    "signUp": {
      "confirmationStep": {
        "form": {
          "counter": "{counter}",
          "description": "E-posta adresinize gelen doğrulama kodunu girerek hesabınıza devam edebilirsiniz.",
          "isCodeDelivered": "Kod gelmedi mi?",
          "resend": "Tekrar gönder",
          "second": "saniye",
          "title": "Doğrulama kodunu girin"
        },
        "pageTitle": "Emailinizi onaylayın",
        "result": {
          "error": "Bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
          "expired_code": "Girilen Doğrulama Kodunun Süresi Dolmuştur.",
          "invalidCode": "Girdiğiniz kod hatalı. Lütfen tekrar deneyin."
        }
      },
      "emailStep": {
        "buttons": {
          "login": "Hesabınız var mı? Giriş yapın.",
          "submit": "Devam et"
        },
        "form": {
          "atlasLogo": "Atlas Logo",
          "description": "Paraşüt Atlas hesabınızın bağlı olacağı e-posta adresini belirleyin.",
          "emailInput": {
            "label": "e-Posta adresiniz"
          },
          "invalidEmail": "Lütfen e-postanızı kontrol edin.",
          "smallTitle": "Paraşüt Atlas hesabınızı oluşturun",
          "title": "E-Posta adresinizi girin"
        },
        "pageTitle": "Hesap oluştur",
        "result": {
          "alreadyRegistered": "Bu e-posta ile kayıtlı bir hesap var. <b>Lütfen giriş yapın.</b>",
          "error": "Bilinmeyen bir hata oluştu."
        }
      },
      "errors": {
        "passwordMatchFailed": "Girmiş olduğunuz parolalar uyuşmuşuyor",
        "saveFailed": "Kullanıcı kaydedilirken bir hata oluştu."
      },
      "flash": {
        "loginFailed": "Kullanıcı kaydı başarılı fakat giriş yaparken hata oluştu",
        "loginSucceed": "Kullanıcı kaydedildi ve giriş yapıldı",
        "notSaved": "Kullanıcı kaydedilemedi"
      },
      "passwordStep": {
        "buttons": {
          "submit": "KAYDET VE DEVAM ET"
        },
        "form": {
          "basicHelper": "Minimum 6 karakter",
          "description": "Parolanızı belirleyerek internet bağlantısı bulunan tüm bilgisayarlardan Paraşüt Atlas hesabınıza güvenli bir şekilde giriş yapabilirsiniz.",
          "passwordConfirmationInput": {
            "label": "PAROLANIZI DOĞRULAYIN"
          },
          "passwordInput": {
            "label": "PAROLANIZI GİRİN"
          },
          "smallTitle": "Paraşüt Atlas hesabınızı oluşturun",
          "title": "Hesabınız için bir parola belirleyin"
        },
        "pageTitle": "Hesabınız için bir parola belirleyin"
      },
      "profileInfoStep": {
        "buttons": {
          "submit": "DEVAM ET"
        },
        "form": {
          "description": "İhtiyaçlarınızı anlamak ve size zaman kazandıracak özelliklerimizi aktarmak adına Dijital Çözümler Uzmanımız sizinle iletişime geçmek istiyor. Lütfen bilgilerinizi tamamlayın.",
          "nameInput": "ADINIZ SOYADINIZ",
          "phoneNumberInput": {
            "label": "TELEFON NUMARANIZ",
            "placeholder": "örn. 5551234567"
          },
          "smallTitle": "Paraşüt Atlas hesabınızı oluşturun",
          "title": "Hesabınızı kişiselleştirin"
        },
        "kvkkCheckboxes": {
          "clarificationText": "<b><a href='https://parasutatlas.com/kisisel-verilerin-korunmasi-ve-islenmesine-iliskin-aydinlatma-metni' target='_blank'>Aydınlatma Metni</a></b> uyarınca kişisel verilerimin işlenmesine ve aktarılmasına <b><a href='https://parasutatlas.com/tr/acik-riza-metni' target='_blank'>Açık Rıza Metni</a></b> kapsamında açık rıza veriyorum.",
          "parasutContract": "<b><a href='https://parasutatlas.com/kullanici-sozlesmesi' target='_blank'>Kullanıcı Sözleşmesi</a></b>’ni okudum, onaylıyorum.",
          "regularity": "Paraşüt tarafından <b><a href='https://parasutatlas.com/kisisel-verilerin-islenmesi-hakkinda-aydinlatma-metni' target='_blank'>Aydınlatma Metni</a></b> uyarınca tarafıma ticari elektronik ileti gönderilmesine <b><a href='https://parasutatlas.com/kisisel-verilerin-islenmesine-iliskin-acik-riza-formu' target='_blank'>Açık Rıza Metni</a></b> kapsamında onay veriyorum."
        },
        "pageTitle": "Hesabınızı kişiselleştirin"
      }
    },
    "staffInfoEditModal": {
      "body": {
        "emailInput": {
          "label": "E-POSTA ADRESİNİZ"
        },
        "message": "Paraşüt atlas üzerindeki {officeName}'na kaydınızı tamamlamak için iletişim bilgilerinizi doldurun",
        "nameInput": {
          "label": "ADINIZ SOYADINIZ"
        },
        "phoneInput": {
          "label": "TELEFON NUMARANIZ"
        }
      },
      "flash": {
        "notSaved": "İletişim bilgileriniz kaydedilemedi."
      },
      "footer": {
        "cancelButton": "VAZGEÇ",
        "submitButton": "KAYDET"
      },
      "header": {
        "smallTitle": "Paraşüt Atlas hesabınızı oluşturun",
        "title": "Hesap bilgileriniz"
      }
    },
    "staffInfoShowModal": {
      "header": {
        "smallTitle": "Paraşüt Atlas hesabınızı oluşturun",
        "title": "Hesap bilgileriniz"
      }
    },
    "staffMembers": {
      "button": {
        "closeStaffInfoModal": "KAPAT",
        "editStaffInfoModal": "DÜZENLE",
        "showStaffInfoModal": "Kullanıcı Bilgileri"
      },
      "listRow": {
        "cannotFind": "Bilinmiyor",
        "employee": "Personel",
        "owner": "Yönetici"
      },
      "profileInfo": {
        "email": "E-POSTA ADRESİ",
        "fullname": "ADI SOYADI",
        "modaltitle": "Hesap bilgileriniz",
        "phone": "TELEFON NUMARASI"
      },
      "staffTable": {
        "assignedTaxpayers": "ATANMIŞ MÜKELLEFLER",
        "username": "PERSONEL ADI"
      }
    },
    "staffShowHeader": {
      "allTaxPayersTab": "Atanmış Mükellefler ({count})"
    },
    "taxPeriodIndex": {
      "invoiceType": {
        "purchaseBill": "Alışlar",
        "salesInvoice": "Satışlar"
      },
      "pageDescription": "İşlem yapmak istediğiniz vergi dönemini seçin.",
      "titles": {
        "pageTitle": "Dönemler"
      }
    },
    "taxPeriodNavigationBar": {
      "accountingType": {
        "basic": "İşletme Defteri",
        "standard": "Bilanço Defteri"
      },
      "pastPeriods": "Geçmiş Dönemler",
      "recentPeriods": "Vergi Dönemleri"
    },
    "taxPeriodPastListItem": {
      "invoiceType": {
        "PurchaseBill": "Giderler",
        "SalesInvoice": "Satışlar"
      },
      "loading": "Geçmiş dönemler yükleniyor, lütfen bekleyiniz."
    },
    "taxPeriodPeriodInvoiceSummary": {
      "bodyTitles": {
        "done": "AKTARILAN",
        "ready": "AKTARIMA HAZIR",
        "total": "TOPLAM FATURA",
        "waiting": "ONAY BEKLEYEN"
      },
      "buttons": {
        "continue": "AKTARMAYA DEVAM ET",
        "show": "GÖRÜNTÜLE",
        "start": "AKTARMAYA BAŞLA"
      },
      "invoiceType": {
        "purchaseBill": "Giderler",
        "salesInvoice": "Satışlar"
      },
      "messages": {
        "hasNoInvoice": {
          "purchaseBill": "Henüz {month} ayına ait gider faturası bulunmuyor.",
          "salesInvoice": "Henüz {month} ayına ait satış faturası bulunmuyor."
        }
      },
      "polling": {
        "error": "Sunucu ile bağlantı sırasında hata oluştu.",
        "running": "Faturalarınız işleniyor...",
        "timeout": "Faturalarınızın işlenilmesine devam ediliyor. Bu aşamada işlediğimiz faturalar ile aktarmaya başlayabilirsiniz."
      }
    },
    "taxPeriodShow": {
      "bankTransactionReady": {
        "flash": {
          "error": "İşlem yapılırken bir hata oluştu."
        },
        "markAsWaitingAll": "TÜMÜNÜ ONAY BEKLİYOR'a TAŞI",
        "markAsWaitingFiltered": "SEÇİMİ ONAY BEKLİYOR'a TAŞI({bankTransactionsCount})"
      },
      "basicAccountPurchaseReadyDescription": "Aktarıma hazır e-faturalarınızı tek seferde veya parçalı olarak muhasebeleştirin.",
      "basicAccountPurchaseWaitingDescription": "Onay bekleyen alış e-faturalarınızın kontrol ederek aktarıma hazır hale getirin.",
      "basicAccountSalesReadyDescription": "Aktarıma hazır e-faturalarınızı tek seferde veya parçalı olarak muhasebeleştirin.",
      "basicAccountSalesWaitingDescription": "Onay bekleyen satış e-faturalarınızı kontrol ederek aktarıma hazır hale getirin.",
      "pageTitles": {
        "done": "Aktarıldı",
        "invoiceType": "Fatura Türü",
        "purchaseBill": "Giderler",
        "readonly": "Faturalar",
        "ready": "Aktarıma Hazır",
        "receiptExportGroup": {
          "index": "Aktarım Grupları"
        },
        "salesInvoice": "Satışlar",
        "show": "{taxPeriodSlug}",
        "waiting": "Onay Bekliyor"
      },
      "purchaseBill": "Giderler",
      "purchaseBillReadOnly": {
        "exportButton": "MUHASEBELEŞTİR",
        "metaText": "Toplam <strong>{totalInvoiceCount}</strong> adet gider faturanız bulunmaktadır."
      },
      "purchaseBillReady": {
        "flash": {
          "error": "İşlem yapılırken bir hata oluştu."
        },
        "markAsWaitingAll": "TÜMÜNÜ ONAY BEKLİYOR'a TAŞI",
        "markAsWaitingFiltered": "SEÇİMİ ONAY BEKLİYOR'a TAŞI({invoiceCount})"
      },
      "purchaseReadyDescription": "Aktarıma hazır e-faturalarınızı tek seferde veya parçalı olarak muhasebeleştirin.",
      "purchaseWaitingDescription": "Onay bekleyen alış faturalarınızın cari ve gider hesap kodlarını doldurarak onaylayın.",
      "readOnlyDescription": "Aktarıma hazır e-faturalarınızı, Luca Akıllı Entegrasyon veya <br> Excel dışa aktarım ile muhasebeleştirin.",
      "salesInvoice": "Satışlar",
      "salesInvoiceReadOnly": {
        "exportButton": "MUHASEBELEŞTİR",
        "metaText": "Toplam <strong>{totalInvoiceCount}</strong> adet satış faturanız bulunmaktadır."
      },
      "salesInvoiceReady": {
        "flash": {
          "error": "İşlem yapılırken bir hata oluştu."
        },
        "markAsWaitingAll": "TÜMÜNÜ ONAY BEKLİYOR'a TAŞI",
        "markAsWaitingFiltered": "SEÇİMİ ONAY BEKLİYOR'a TAŞI({invoiceCount})"
      },
      "salesReadyDescription": "Aktarıma hazır e-faturalarınızı tek seferde veya parçalı olarak muhasebeleştirin.",
      "salesWaitingDescription": "Onay bekleyen satış faturalarınızın cari ve satış hesap kodlarını doldurarak onaylayın.",
      "selectType": "Aktarılacak fatura türü seçin"
    },
    "taxpayerIntegrationModal": {
      "bizmu": "Bizmu",
      "bizmuCardDesc": "İhtiyacı e-fatura düzenlemek ve temel ön muhasebe çözümlerinden yararlanmak olan mükellefler için.",
      "ePortal": "e-Portal",
      "newTaxpayer": "Yeni Mükellef",
      "parasut": "Paraşüt",
      "parasutCardDesc": "İhtiyacı e-fatura düzenlemenin yanı sıra çoklu depo takibi, akıllı banka entegrasyonları ve e-ticaret hizmeti vb. kapsamlı ön muhasebe yönetimi olan mükellefler için.",
      "portalCardDesc": "İhtiyacı sadece e-fatura ve e-arşiv fatura düzenlemek olan mükellefler için.",
      "registerDescription": "Mükellefinizle bağlantı kurarak faturalarını Atlas'ta kolaylıkla takip edebilir ve muhasebe programınıza aktarabilirsiniz.",
      "soon": "Yakında",
      "taxpayerHeading": "Mükellefinizin kullandığı uygulamayı seçin"
    },
    "unauthenticatedError": {
      "emailAddress": "iletisim@parasutatlas.com",
      "info": "Atlas hakkında soru ve önerileriniz için:",
      "login": "Giriş Yap",
      "or": "veya",
      "phoneNumber": "+90 (212) 963 00 30",
      "signUp": "Kayıt Ol"
    },
    "userInfoModal": {
      "edit": {
        "body": {
          "fieldLabels": {
            "email": "e-Posta",
            "name": "Ad Soyad",
            "phone": "Telefon numarası"
          }
        },
        "flash": {
          "notSaved": "Bilgileriniz güncellenemedi.",
          "success": "Bilgileriniz başarıyla güncellendi."
        },
        "footer": {
          "closeButton": "GERİ DÖN",
          "submitButton": "KAYDET"
        },
        "header": {
          "title": "Hesap ayarları"
        }
      },
      "show": {
        "body": {
          "accountantInfo": {
            "editButtonAriaLabel": "Bilgilerinizi düzenleyin",
            "editButtonLabel": "DÜZENLE",
            "fieldLabels": {
              "email": "e-Posta",
              "name": "Ad Soyad",
              "phone": "Telefon numarası"
            },
            "loadingText": "Yükleniyor...",
            "title": "Hesap bilgileri"
          },
          "inspection": {
            "buttonName": "ERİŞİM İZNİ VER",
            "description": "Karşılaştığınız problemleri gidermek için Paraşüt ATLAS destek ekibinin verilerinize erişmesi gerekebilir. Erişim izni vererek destek ekibinin firma verilerinize erişmesine izin verebilirsiniz."
          },
          "marketingConsent": {
            "callsCheckboxLabel": "Tarafıma <strong>telefon ile ulaşılmasına</strong> onay veriyorum.",
            "clarificationText": "<b><a href='https://parasutatlas.com/kisisel-verilerin-korunmasi-ve-islenmesine-iliskin-aydinlatma-metni' target='_blank'>Aydınlatma Metni'ni</a></b> okudum ve <b><a href='https://parasutatlas.com/tr/acik-riza-metni' target='_blank'>Açık Rıza Metni</a></b> çerçevesinde tarafıma yukarıda işaretlediğim kaynaklarla ulaşılmasına izin veriyorum.",
            "description": "Atlas’a gelen yeni özellikler, mevzuattaki değişiklikler, dönemsel kampanyalar oldukça sizinle aşağıdaki yollarla iletişime geçmek istiyoruz.",
            "emailsCheckboxLabel": "<strong>Yeni özellik</strong> ve <strong>duyuru e-postalarını</strong> almak istiyorum.",
            "smsesCheckboxLabel": "<strong>Yeni özellik</strong> ve <strong>duyuru SMS</strong>'lerini almak istiyorum.",
            "title": "İletişim kanalları ve tercihleri"
          }
        },
        "flash": {
          "connectionError": "Profil bilgilerine erişilemiyor lütfen daha sonra tekrar deneyiniz.",
          "notSaved": "İletişim tercihleriniz kaydedilemedi."
        },
        "footer": {
          "closeButton": "Kaydet ve Kapat"
        },
        "header": {
          "title": "Hesap ayarları"
        }
      }
    },
    "userInvitation": {
      "acceptByKey": {
        "errorPage": {
          "notFoundError": "İlgili personel daveti bulunamadı.",
          "pageTitle": "Beklenmedik bir hata oluştu",
          "unknownError": "Beklenmedik bir hata oluştu."
        },
        "pageTitle": "Kabul Et"
      },
      "pageTitle": "Personel Davetleri"
    },
    "userInvitations": {
      "accept": {
        "alreadyEmployeeModal": {
          "buttons": {
            "saveAndProceedToLogin": "DAVETİ KABUL ET ve PROFİLİMİ TAŞI"
          },
          "header": "{invitedByName} sizi Paraşüt Atlas içerisindeki {officeName}'e personel olarak davet etti.",
          "implications": {
            "introText": "Daveti kabul etmeniz durumunda:",
            "warning1": "Hesabınıza bağlı mükellefleriniz ile bağlantınız güvenlik sebepleri ile askıya alınacak.",
            "warning2": "Kullanıcı profiliniz <b>Paraşüt Atlas</b> içerisindeki <b>{officeName}</b> ofisine taşınacaktır."
          }
        },
        "alreadyOwnerModal": {
          "body": {
            "info": "Mevcut durumda Paraşüt Atlas üzerinde başka bir ofise kayıtlısınız."
          },
          "footer": {
            "buttons": {
              "close": "KAPAT"
            },
            "contactInfoText": "Destek ekibimize hafta içi her gün saat <b>09.00 - 18.00</b> saatleri arasında <b>0 212 963 00 30</b> numaralı telefondan veya <a href='mail:to:iletisim@parasut.com'><b>iletisim@parasut.com</b></a> adresinden ulaşabilirsiniz."
          },
          "header": "{invitedByName} sizi Paraşüt Atlas içerisindeki {officeName}'e personel olarak davet etti.",
          "implications": {
            "introText": "Daveti kabul etmeniz için:",
            "warning1": "Sizi davet eden <b>{officeName}</b> ofisinden farklı bir e-posta adresine tekrar davet göndermesini talep edebilir veya",
            "warning2": "Destek ekibimiz ile iletişime geçebilirsiniz."
          }
        }
      },
      "buttons": {
        "inviteUsers": "PERSONEL DAVET ET"
      },
      "deleteInviteConfirmationModal": {
        "buttons": {
          "cancel": "VAZGEÇ",
          "confirm": "DAVETİ SİL"
        },
        "implications": {
          "message": "Silme işleminin sonucunda bu kişiye yollanan kullanıcı daveti geçersiz sayılacak ve bu kişi firma hesabınıza erişim sağlayamayacaktır",
          "title": "Daveti silmeniz durumunda:"
        },
        "question": "<strong>{email}</strong> e-posta adresine gönderdiğiniz daveti silmek istediğinize emin misiniz?",
        "title": "Personel davetiniz silinecek"
      },
      "pageTitle": "Personel Davetleri",
      "table": {
        "emptyText": "Personel davetiniz bulunmamaktadır.",
        "header": {
          "email": "PERSONEL E-POSTA"
        },
        "rows": {
          "deleteButton": "Daveti sil",
          "deletedSuccesfully": "Davet başarıyla silindi",
          "errors": {
            "unknownError": "Beklenmedik bir hata oluştu."
          },
          "resendButton": "Tekrar gönder",
          "resentSuccesfully": "Davet başarıyla tekrar gönderildi"
        }
      }
    },
    "vatWithholdingFilter": {
      "0": "TEVKİFATSIZ",
      "100": "TEVKİFAT ORANI: 10/10",
      "20": "TEVKİFAT ORANI: 2/10",
      "30": "TEVKİFAT ORANI: 3/10",
      "40": "TEVKİFAT ORANI: 4/10",
      "50": "TEVKİFAT ORANI: 5/10",
      "70": "TEVKİFAT ORANI: 7/10",
      "90": "TEVKİFAT ORANI: 9/10",
      "all": "TÜMÜ",
      "buttons": {
        "apply": "UYGULA",
        "cancel": "VAZGEÇ"
      },
      "fifty": "5/10",
      "forty": "4/10",
      "hundred": "10/10",
      "multiple": "{filterCount} TEVKİFAT ORANI",
      "ninety": "9/10",
      "none": "TEVKİFATSIZ",
      "seventy": "7/10",
      "thirty": "3/10",
      "title": "TEVKİFAT DURUMU",
      "twenty": "2/10"
    }
  };
  _exports.default = _default;
});