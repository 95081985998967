define("da-vinci/components/container-index-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="header-container" style="margin-bottom: 1%;">
    <div class="header-row-bc">
      <div class="bread-crumb" data-test-container-title>
        <h1 class="breadcrumb">{{t "containerIndexHeader.headerTitle"}}</h1>
      </div>
    </div>
    <div class="header-row-dc" data-test-container-body-text>
      <p>{{t "containerIndexHeader.headerBody"}}</p>
    </div>
    <div class="header-row-tb2" >
      <div class="nav-tab">
        <ul>
          <li class="active">
            <a href="#" data-test-all-customers-tab>
              {{t "containerIndexHeader.allCustomersTab" count=companyCount}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "IjlZvMg5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header-container\"],[10,\"style\",\"margin-bottom: 1%;\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-row-bc\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"bread-crumb\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"breadcrumb\"],[8],[1,[28,\"t\",[\"containerIndexHeader.headerTitle\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-row-dc\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"containerIndexHeader.headerBody\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-row-tb2\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"nav-tab\"],[8],[0,\"\\n      \"],[7,\"ul\",true],[8],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"active\"],[8],[0,\"\\n          \"],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"containerIndexHeader.allCustomersTab\"],[[\"count\"],[[24,[\"companyCount\"]]]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/container-index-header/template.hbs"
    }
  });

  _exports.default = _default;
});