define("da-vinci/staff-member/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{title (t "accountantOffice.pageTitle")}}
  
  <AccountantOfficeHeader @breadcrumb={{component "p-breadcrumb"}}>
    <AccountantOfficeTabSelector @tab="staffMember" />
  </AccountantOfficeHeader>
  
  <PIndexHeader>
    <PButton
      @onClick={{action (mut showInviteUsersModal) true}}
      @data-test-invite-users-button={{true}}
    >
      {{t "userInvitations.buttons.inviteUsers"}}
    </PButton>
  </PIndexHeader>
  
  <StaffMembers::StaffTable
    @staffMembers={{staffMembers}}
    @data-test-staff-members={{true}}
  />
  
  {{#if showInviteUsersModal}}
    <InviteUsersModal
      @accountantOffice={{me.user.accountantOffice}}
      @fromSignUpPage={{false}}
      @onSuccess={{action refreshControllerRoute}}
      @onClose={{action closeModalAndRedirectStaffMember}}
    />
  {{/if}}
  
  */
  {
    "id": "BicWZ7n3",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"accountantOffice.pageTitle\"],null]],[[\"_deprecate\"],[null]]],false],[0,\"\\n\\n\"],[5,\"accountant-office-header\",[],[[\"@breadcrumb\"],[[28,\"component\",[\"p-breadcrumb\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"accountant-office-tab-selector\",[],[[\"@tab\"],[\"staffMember\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"p-index-header\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"p-button\",[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showInviteUsersModal\"]]],null],true],null]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"userInvitations.buttons.inviteUsers\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"staff-members/staff-table\",[],[[\"@staffMembers\"],[[22,\"staffMembers\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showInviteUsersModal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"invite-users-modal\",[],[[\"@accountantOffice\",\"@fromSignUpPage\",\"@onSuccess\",\"@onClose\"],[[24,[\"me\",\"user\",\"accountantOffice\"]],false,[28,\"action\",[[23,0,[]],[24,[\"refreshControllerRoute\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"closeModalAndRedirectStaffMember\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/staff-member/index/template.hbs"
    }
  });

  _exports.default = _default;
});