define("da-vinci/components/user-invitations/user-invitations-table-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <td data-test-user-invitations-table-row-email-column>{{userInvitation.email}}</td>
  <td data-test-user-invitations-table-row-action-buttons-column>
    <PButton
      @type="outline"
      @size="small"
      @shape="square"
      @onClick={{action (mut showDeleteInviteConfirmationModal) true}}
      @data-test-delete-invitation-button={{true}}
    >
      <i
        class="fa fa-trash"
        aria-label={{t "userInvitations.table.rows.deleteButton"}}
      >
      </i>
    </PButton>
  
    <PButton
      @type="outline"
      @size="small"
      @shape="square"
      @busy={{resendInvitationTask.isRunning}}
      @onClick={{perform resendInvitationTask}}
      @data-test-resend-invitation-button={{true}}
    >
      <i
        class="fa fa-undo"
        aria-label={{t "userInvitations.table.rows.resendButton"}}
      >
      </i>
    </PButton>
  </td>
  
  {{#if showDeleteInviteConfirmationModal}}
    <UserInvitations::DeleteInviteConfirmationModal
      @userInvitation={{userInvitation}}
      @closeModal={{action (mut showDeleteInviteConfirmationModal) false}}
    />
  {{/if}}
  
  */
  {
    "id": "KhwXCjFJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[1,[24,[\"userInvitation\",\"email\"]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[5,\"p-button\",[],[[\"@type\",\"@size\",\"@shape\",\"@onClick\"],[\"outline\",\"small\",\"square\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showDeleteInviteConfirmationModal\"]]],null],true],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-trash\"],[11,\"aria-label\",[28,\"t\",[\"userInvitations.table.rows.deleteButton\"],null]],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"p-button\",[],[[\"@type\",\"@size\",\"@shape\",\"@busy\",\"@onClick\"],[\"outline\",\"small\",\"square\",[24,[\"resendInvitationTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"resendInvitationTask\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-undo\"],[11,\"aria-label\",[28,\"t\",[\"userInvitations.table.rows.resendButton\"],null]],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showDeleteInviteConfirmationModal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"user-invitations/delete-invite-confirmation-modal\",[],[[\"@userInvitation\",\"@closeModal\"],[[22,\"userInvitation\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showDeleteInviteConfirmationModal\"]]],null],false],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/user-invitations/user-invitations-table-row/template.hbs"
    }
  });

  _exports.default = _default;
});