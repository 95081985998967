define("da-vinci/components/bank-transactions/bulk-approve-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showInfo}}
    <BankTransactions::BulkApproveModal::Info
      @bankTransactionCount={{bankTransactionCount}}
      @onContinue={{action "toTransfer"}}
      @onCancel={{onCancel}}
    />
  {{else if showTransfer}}
    <BankTransactions::BulkApproveModal::Transfer
      @queryParamsFilters={{queryParamsFilters}}
      @onContinue={{action "toSummary"}}
    />
  {{else if showSummary}}
    <BankTransactions::BulkApproveModal::Summary
      @successCount={{successCount}}
      @onContinue={{onCancel}}
      @errorList={{errorList}}
    />
  {{/if}}
  
  */
  {
    "id": "2wkTTcbO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showInfo\"]]],null,{\"statements\":[[0,\"  \"],[5,\"bank-transactions/bulk-approve-modal/info\",[],[[\"@bankTransactionCount\",\"@onContinue\",\"@onCancel\"],[[22,\"bankTransactionCount\"],[28,\"action\",[[23,0,[]],\"toTransfer\"],null],[22,\"onCancel\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showTransfer\"]]],null,{\"statements\":[[0,\"  \"],[5,\"bank-transactions/bulk-approve-modal/transfer\",[],[[\"@queryParamsFilters\",\"@onContinue\"],[[22,\"queryParamsFilters\"],[28,\"action\",[[23,0,[]],\"toSummary\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showSummary\"]]],null,{\"statements\":[[0,\"  \"],[5,\"bank-transactions/bulk-approve-modal/summary\",[],[[\"@successCount\",\"@onContinue\",\"@errorList\"],[[22,\"successCount\"],[22,\"onCancel\"],[22,\"errorList\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/bank-transactions/bulk-approve-modal/template.hbs"
    }
  });

  _exports.default = _default;
});