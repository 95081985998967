define("da-vinci/mixins/salesforce/chat-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    salesforce: Ember.inject.service(),
    isChatWindowOpen: Ember.computed('salesforce.isOpenChat', function () {
      if (this.salesforce.isOpenChat) {
        return 'footer opened-chat';
      } else {
        return 'footer';
      }
    })
  });

  _exports.default = _default;
});