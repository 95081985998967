define("da-vinci/locales/tr/p-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    placeholder: {
      unknown: 'Bilinmiyor'
    },
    unknown: 'BİLİNMİYOR',
    beginningOfNextMonth: 'AY BAŞINDA',
    oneWeekLater: '1 HAFTA SONRA',
    twoWeeksLater: '2 HAFTA SONRA',
    oneMonthLater: '1 AY SONRA',
    twoMonthsLater: '2 AY SONRA'
  };
  _exports.default = _default;
});