define("da-vinci/components/process-status-tab-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if (eq documentType "BankTransaction")}}
    <ProcessStatusTabSelector::BankTransaction
      @tabClasses={{tabClasses}}
      @waitingCount={{meta.waiting}}
      @readyCount={{meta.ready}}
      @doneCount={{meta.done}}
    >
      {{yield}}
    </ProcessStatusTabSelector::BankTransaction>
  {{/if}}
  
  */
  {
    "id": "VneAacGz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"documentType\"]],\"BankTransaction\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"process-status-tab-selector/bank-transaction\",[],[[\"@tabClasses\",\"@waitingCount\",\"@readyCount\",\"@doneCount\"],[[22,\"tabClasses\"],[24,[\"meta\",\"waiting\"]],[24,[\"meta\",\"ready\"]],[24,[\"meta\",\"done\"]]]],{\"statements\":[[0,\"\\n    \"],[14,1],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/process-status-tab-selector/template.hbs"
    }
  });

  _exports.default = _default;
});