define("da-vinci/helpers/bulk-approve-error-list-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isAccommodationTaxError = isAccommodationTaxError;
  _exports.isAccountCodeError = isAccountCodeError;
  _exports.isBankAccountCodeKeyError = isBankAccountCodeKeyError;
  _exports.isCommercialInvoiceError = isCommercialInvoiceError;
  _exports.isCommunicationsOrExciseDutyError = isCommunicationsOrExciseDutyError;
  _exports.isContactAccountKeyError = isContactAccountKeyError;
  _exports.isEdgeReasonError = isEdgeReasonError;
  _exports.isVatError = isVatError;
  _exports.isVatWithholdingAccountError = isVatWithholdingAccountError;
  _exports.isWithholdingError = isWithholdingError;
  var missingWithholdingErrorKeys = ['invoice_blank', 'invoice_should_be_waiting', 'cannot_update_if_vat_withholding_exists'];
  var missingAccountCodeErrorKeys = ['sales_invoice_account_not_found', 'purchase_invoice_account_not_found', 'sales_refund_account_not_found', 'purchase_refund_account_not_found'];
  var missingVatErrorKeys = ['sales_invoice_vat_account_20_not_found', 'sales_invoice_vat_account_18_not_found', 'sales_invoice_vat_account_10_not_found', 'sales_invoice_vat_account_8_not_found', 'sales_invoice_vat_account_1_not_found', 'sales_refund_vat_account_20_not_found', 'sales_refund_vat_account_18_not_found', 'sales_refund_vat_account_10_not_found', 'sales_refund_vat_account_8_not_found', 'sales_refund_vat_account_1_not_found', 'purchase_invoice_vat_account_20_not_found', 'purchase_invoice_vat_account_18_not_found', 'purchase_invoice_vat_account_10_not_found', 'purchase_invoice_vat_account_8_not_found', 'purchase_invoice_vat_account_1_not_found', 'purchase_refund_vat_account_20_not_found', 'purchase_refund_vat_account_18_not_found', 'purchase_refund_vat_account_10_not_found', 'purchase_refund_vat_account_8_not_found', 'purchase_refund_vat_account_1_not_found'];
  var missingVatWithholdingErrorKey = ['sales_invoice_vat_withholding_account_20_not_found', 'sales_invoice_vat_withholding_account_18_not_found', 'sales_invoice_vat_withholding_account_10_not_found', 'sales_invoice_vat_withholding_account_8_not_found', 'sales_invoice_vat_withholding_account_1_not_found', 'sales_refund_vat_withholding_account_20_not_found', 'sales_refund_vat_withholding_account_18_not_found', 'sales_refund_vat_withholding_account_10_not_found', 'sales_refund_vat_withholding_account_8_not_found', 'sales_refund_vat_withholding_account_1_not_found'];
  var missingCommunicationsOrExciseDutyErrorKeys = ['sales_invoice_communications_tax_account_not_found', 'sales_invoice_excise_duty_account_not_found', 'sales_refund_communications_tax_account_not_found', 'sales_refund_excise_duty_account_not_found', 'purchase_invoice_communications_tax_account_not_found', 'purchase_invoice_excise_duty_account_not_found', 'purchase_refund_communications_tax_account_not_found', 'purchase_refund_excise_duty_account_not_found'];
  var edgeReasonKeys = ['has_invoice_vat_empty_edge_reason', 'has_basic_purchase_bill_not_allowed_vat_rate_edge_reason', 'telecom_invoice', 'cancelled_invoice', 'detail_has_invalid_quantity_edge_reason'];
  var commercialInvoiceKeys = ['commercial_invoice_has_not_approved'];
  var missingAccommodationTaxErrorKeys = ['sales_invoice_accommodation_tax_account_0_not_found', 'sales_refund_accommodation_tax_account_0_not_found', 'sales_invoice_accommodation_tax_account_2_not_found', 'sales_refund_accommodation_tax_account_2_not_found', 'fill_invoice_or_accommodation_tax_account']; // bank transaction bulk approve errors

  var missingBankAccountCodeKeys = ['bank_account_code_not_found'];
  var missingContactAccountKeys = ['contact_account_not_found'];

  function isWithholdingError(key) {
    return missingWithholdingErrorKeys.some(function (missingWithholdingErrorKey) {
      return key.includes(missingWithholdingErrorKey);
    });
  }

  function isAccountCodeError(key) {
    return missingAccountCodeErrorKeys.some(function (missingAccountCodeErrorKey) {
      return key.includes(missingAccountCodeErrorKey);
    });
  }

  function isVatError(key) {
    return missingVatErrorKeys.some(function (missingVatErrorKey) {
      return key.includes(missingVatErrorKey);
    });
  }

  function isVatWithholdingAccountError(key) {
    return missingVatWithholdingErrorKey.some(function (missingVatWithholdingErrorKey) {
      return key.includes(missingVatWithholdingErrorKey);
    });
  }

  function isCommunicationsOrExciseDutyError(key) {
    return missingCommunicationsOrExciseDutyErrorKeys.some(function (missingCommunicationsOrExciseDutyErrorKey) {
      return key.includes(missingCommunicationsOrExciseDutyErrorKey);
    });
  }

  function isEdgeReasonError(key) {
    return edgeReasonKeys.some(function (edgeReasonKey) {
      return key.includes(edgeReasonKey);
    });
  }

  function isCommercialInvoiceError(key) {
    return commercialInvoiceKeys.some(function (commercialInvoiceKey) {
      return key.includes(commercialInvoiceKey);
    });
  } // bank transaction bulk approve methods


  function isBankAccountCodeKeyError(key) {
    return missingBankAccountCodeKeys.some(function (missingBankAccountCodeKey) {
      return key.includes(missingBankAccountCodeKey);
    });
  }

  function isContactAccountKeyError(key) {
    return missingContactAccountKeys.some(function (missingContactAccountKey) {
      return key.includes(missingContactAccountKey);
    });
  }

  function isAccommodationTaxError(key) {
    return missingAccommodationTaxErrorKeys.some(function (missingAccommodationTaxErrorKey) {
      return key.includes(missingAccommodationTaxErrorKey);
    });
  }
});