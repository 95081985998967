define("da-vinci/utils/camelize-keys", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var camelizeKeys = function camelizeKeys(input) {
    if (Ember.isArray(input)) {
      return camelizeArray(input);
    } else if ((0, _typeof2.default)(input) === 'object') {
      return camelizeProperties(input);
    }

    return input;
  };

  function camelizeArray(array) {
    return array.map(function (item) {
      return camelizeKeys(item);
    });
  }

  function camelizeProperties(input) {
    var camelized = {}; // below disabled added because of an eslint bug https://github.com/eslint/eslint/issues/12117
    // eslint-disable-next-line no-unused-vars

    for (var key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        var camelizedName = key.camelize();
        var value = input[key];

        if (value === null) {
          camelized[camelizedName] = null;
        } else if ((0, _typeof2.default)(value) === 'object') {
          camelized[camelizedName] = camelizeKeys(value);
        } else {
          camelized[camelizedName] = value;
        }
      }
    }

    return camelized;
  }

  var _default = camelizeKeys;
  _exports.default = _default;
});