define("da-vinci/components/luca-company-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if pollerUnit.isRunning}}
    <div class="loading" data-test-loading>
      <i class="fa fa-spinner fa-pulse"></i>
    </div>
  {{else}}
    <POneWaySelect
      @selected={{selectedOption}}
      @placeholder={{t "lucaCompanySelect.placeholder"}}
      @options={{options}}
      @noMatchesMessage={{t "lucaCompanySelect.noResult"}}
      @onchange={{action "select"}}
      as |option|
    >
      {{option.label}}
    </POneWaySelect>
  {{/if}}
  
  */
  {
    "id": "rtyWI/2x",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[24,[\"pollerUnit\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"loading\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-pulse\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"p-one-way-select\",[],[[\"@selected\",\"@placeholder\",\"@options\",\"@noMatchesMessage\",\"@onchange\"],[[22,\"selectedOption\"],[28,\"t\",[\"lucaCompanySelect.placeholder\"],null],[22,\"options\"],[28,\"t\",[\"lucaCompanySelect.noResult\"],null],[28,\"action\",[[23,0,[]],\"select\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[\"label\"]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/luca-company-select/template.hbs"
    }
  });

  _exports.default = _default;
});