define("da-vinci/components/luca-create-account-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if (eq currentState viewStates.showLucaErrorModal)}}
    <LucaConnectionErrorModal
      @onRetry={{action onLucaErrorRetry}}
      @retryTaskIsRunning={{fetchContactStateInfoTask.isRunning}}
      @onCancel={{onCancel}}
      @cancelText={{t "lucaCreateAccountModal.cancel"}}
      @dataTestModalName="data-luca-connection-error"
    />
  {{else if (eq currentState viewStates.showContactStateInfoResults)}}
    <LucaCreateAccountModal::ContactStateInfoModal
      @contactStateInfo={{contactStateInfo}}
      @contactStateInfoErrors={{contactStateInfoErrors}}
      @onCancel={{onCancel}}
      @accountCode={{accountCode}}
      @onApprove={{action onApprove}}
      @postContactCreateTaskRunning={{postContactCreateTask.isRunning}}
    />
  {{else if (eq currentState viewStates.showCreateContactResults)}}
    <LucaCreateAccountModal::ContactCreateResultModal
      @postContactResponse={{postContactResponse}}
      @postContactErrors={{postContactErrors}}
      @onCancel={{onCancel}}
      @dataTestModalName="data-create-contact-results"
    />
  {{/if}}
  
  */
  {
    "id": "Dr9P7M9w",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"currentState\"]],[24,[\"viewStates\",\"showLucaErrorModal\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"luca-connection-error-modal\",[],[[\"@onRetry\",\"@retryTaskIsRunning\",\"@onCancel\",\"@cancelText\",\"@dataTestModalName\"],[[28,\"action\",[[23,0,[]],[24,[\"onLucaErrorRetry\"]]],null],[24,[\"fetchContactStateInfoTask\",\"isRunning\"]],[22,\"onCancel\"],[28,\"t\",[\"lucaCreateAccountModal.cancel\"],null],\"data-luca-connection-error\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"currentState\"]],[24,[\"viewStates\",\"showContactStateInfoResults\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"luca-create-account-modal/contact-state-info-modal\",[],[[\"@contactStateInfo\",\"@contactStateInfoErrors\",\"@onCancel\",\"@accountCode\",\"@onApprove\",\"@postContactCreateTaskRunning\"],[[22,\"contactStateInfo\"],[22,\"contactStateInfoErrors\"],[22,\"onCancel\"],[22,\"accountCode\"],[28,\"action\",[[23,0,[]],[24,[\"onApprove\"]]],null],[24,[\"postContactCreateTask\",\"isRunning\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"currentState\"]],[24,[\"viewStates\",\"showCreateContactResults\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"luca-create-account-modal/contact-create-result-modal\",[],[[\"@postContactResponse\",\"@postContactErrors\",\"@onCancel\",\"@dataTestModalName\"],[[22,\"postContactResponse\"],[22,\"postContactErrors\"],[22,\"onCancel\"],\"data-create-contact-results\"]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/luca-create-account-modal/template.hbs"
    }
  });

  _exports.default = _default;
});