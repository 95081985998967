define("da-vinci/components/invite-users-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if showSuccessInfo}}
    <InviteUsersModal::SuccessInfo
      @invitedEmails={{invitedEmails}}
      @accountantOfficeName={{accountantOffice.name}}
      @onClose={{onClose}}
    />
  {{else}}
    <InviteUsersModal::Form
      @accountantOffice={{accountantOffice}}
      @fromSignUpPage={{fromSignUpPage}}
      @onSuccess={{onSuccess}}
      @onClose={{onClose}}
      @proceedToSuccessStep={{action proceedToSuccessStep}}
      @stepIndicators={{stepIndicators}}
    />
  {{/if}}
  
  */
  {
    "id": "I7pyRw1/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showSuccessInfo\"]]],null,{\"statements\":[[0,\"  \"],[5,\"invite-users-modal/success-info\",[],[[\"@invitedEmails\",\"@accountantOfficeName\",\"@onClose\"],[[22,\"invitedEmails\"],[24,[\"accountantOffice\",\"name\"]],[22,\"onClose\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"invite-users-modal/form\",[],[[\"@accountantOffice\",\"@fromSignUpPage\",\"@onSuccess\",\"@onClose\",\"@proceedToSuccessStep\",\"@stepIndicators\"],[[22,\"accountantOffice\"],[22,\"fromSignUpPage\"],[22,\"onSuccess\"],[22,\"onClose\"],[28,\"action\",[[23,0,[]],[24,[\"proceedToSuccessStep\"]]],null],[22,\"stepIndicators\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invite-users-modal/template.hbs"
    }
  });

  _exports.default = _default;
});