define("da-vinci/helpers/has-company-matching-setup-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasMissingAccommodationTaxCode = hasMissingAccommodationTaxCode;
  _exports.hasMissingBankAccount = hasMissingBankAccount;
  _exports.hasMissingCommunicationsTaxAndExciseDutyCode = hasMissingCommunicationsTaxAndExciseDutyCode;
  _exports.hasMissingPurchaseVatCode = hasMissingPurchaseVatCode;
  _exports.hasMissingSalesVatCode = hasMissingSalesVatCode;
  var missingSalesVatTaxCodeKeys = ['sales_invoice_vat_account_20_not_found', 'sales_invoice_vat_account_18_not_found', 'sales_invoice_vat_account_10_not_found', 'sales_invoice_vat_account_8_not_found', 'sales_invoice_vat_account_1_not_found', 'sales_refund_vat_account_20_not_found', 'sales_refund_vat_account_18_not_found', 'sales_refund_vat_account_10_not_found', 'sales_refund_vat_account_8_not_found', 'sales_refund_vat_account_1_not_found', 'sales_invoice_vat_withholding_account_20_not_found', 'sales_invoice_vat_withholding_account_18_not_found', 'sales_invoice_vat_withholding_account_10_not_found', 'sales_invoice_vat_withholding_account_8_not_found', 'sales_invoice_vat_withholding_account_1_not_found', 'sales_refund_vat_withholding_account_20_not_found', 'sales_refund_vat_withholding_account_18_not_found', 'sales_refund_vat_withholding_account_10_not_found', 'sales_refund_vat_withholding_account_8_not_found', 'sales_refund_vat_withholding_account_1_not_found'];
  var missingPurchaseVatTaxCodeKeys = ['purchase_invoice_vat_account_20_not_found', 'purchase_invoice_vat_account_18_not_found', 'purchase_invoice_vat_account_10_not_found', 'purchase_invoice_vat_account_8_not_found', 'purchase_invoice_vat_account_1_not_found', 'purchase_refund_vat_account_20_not_found', 'purchase_refund_vat_account_18_not_found', 'purchase_refund_vat_account_10_not_found', 'purchase_refund_vat_account_8_not_found', 'purchase_refund_vat_account_1_not_found', 'purchase_vat_withholding_20_account_not_found', 'purchase_vat_withholding_18_account_not_found', 'purchase_vat_withholding_10_account_not_found', 'purchase_vat_withholding_8_account_not_found', 'purchase_vat_withholding_1_account_not_found', 'purchase_other_vat_withholding_20_account_not_found', 'purchase_other_vat_withholding_18_account_not_found', 'purchase_other_vat_withholding_10_account_not_found', 'purchase_other_vat_withholding_8_account_not_found', 'purchase_other_vat_withholding_1_account_not_found'];
  var missingCommunicationsTaxAndExciseDutyCodeKeys = ['sales_invoice_communications_tax_account_not_found', 'sales_invoice_excise_duty_account_not_found', 'sales_refund_communications_tax_account_not_found', 'sales_refund_excise_duty_account_not_found', 'purchase_invoice_communications_tax_account_not_found', 'purchase_invoice_excise_duty_account_not_found', 'purchase_refund_communications_tax_account_not_found', 'purchase_refund_excise_duty_account_not_found'];
  var missingAccommodationTaxCodeKeys = ['sales_invoice_accommodation_tax_account_0_not_found', 'sales_invoice_accommodation_tax_account_2_not_found', 'sales_refund_accommodation_tax_account_0_not_found', 'sales_refund_accommodation_tax_account_2_not_found'];
  var missingBankAccountCodeKeys = ['bank_account_code_not_found'];

  function hasMissingSalesVatCode(keyList) {
    return missingSalesVatTaxCodeKeys.some(function (missingTaxKey) {
      return keyList.includes(missingTaxKey);
    });
  }

  function hasMissingPurchaseVatCode(keyList) {
    return missingPurchaseVatTaxCodeKeys.some(function (missingTaxKey) {
      return keyList.includes(missingTaxKey);
    });
  }

  function hasMissingCommunicationsTaxAndExciseDutyCode(keyList) {
    return missingCommunicationsTaxAndExciseDutyCodeKeys.some(function (missingTaxKey) {
      return keyList.includes(missingTaxKey);
    });
  }

  function hasMissingAccommodationTaxCode(keyList) {
    return missingAccommodationTaxCodeKeys.some(function (missingTaxKey) {
      return keyList.includes(missingTaxKey);
    });
  }

  function hasMissingBankAccount(keyList) {
    return missingBankAccountCodeKeys.some(function (missingBankAccount) {
      return keyList.includes(missingBankAccount);
    });
  }
});