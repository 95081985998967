define("da-vinci/helpers/round-vat-rate-int", ["exports", "ui-library/utils/number-util"], function (_exports, _numberUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.roundVatRateInt = roundVatRateInt;

  function roundVatRateInt(rate) {
    return rate ? Math.round(rate / _numberUtil.default.Decimal2Multiplier) : 0;
  }

  var _default = Ember.Helper.helper(roundVatRateInt);

  _exports.default = _default;
});