define("da-vinci/components/invite-users-modal/success-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PModal
    @isOpen={{true}}
    @dataTestModalName="data-test-invite-users-success-modal"
    @containerClassNames={{styleNamespace}}
    as |modal|
  >
    <modal.header>
      <div class="modal-header">
        <h2>{{t "inviteUsersModal.successInfoStep.header.title"}}</h2>
      </div>
    </modal.header>
  
    <modal.body>
      <p data-test-invitation-explanation>{{t "inviteUsersModal.successInfoStep.body.explanation" accountantOfficeName=accountantOfficeName htmlSafe=true}}</p>
      <p data-test-invitation-explanation-detail>{{t "inviteUsersModal.successInfoStep.body.explanationDetail" htmlSafe=true}}</p>
  
      <div class="invited-email-container" data-test-invited-emails>
        {{#each invitedEmails as |invitedEmail|}}
          <span class="invited-email" data-test-invited-email>{{invitedEmail}}</span>
        {{/each}}
      </div>
    </modal.body>
  
    <modal.footer>
      <PButton
        @type="outline"
        @onClick={{onClose}}
        @data-test-close-button={{true}}
      >
        {{t "inviteUsersModal.successInfoStep.buttons.close"}}
      </PButton>
    </modal.footer>
  </PModal>
  
  */
  {
    "id": "GBFKNdbN",
    "block": "{\"symbols\":[\"modal\",\"invitedEmail\"],\"statements\":[[5,\"p-modal\",[],[[\"@isOpen\",\"@dataTestModalName\",\"@containerClassNames\"],[true,\"data-test-invite-users-success-modal\",[22,\"styleNamespace\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"inviteUsersModal.successInfoStep.header.title\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"inviteUsersModal.successInfoStep.body.explanation\"],[[\"accountantOfficeName\",\"htmlSafe\"],[[24,[\"accountantOfficeName\"]],true]]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"inviteUsersModal.successInfoStep.body.explanationDetail\"],[[\"htmlSafe\"],[true]]],false],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"invited-email-container\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"invitedEmails\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"invited-email\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"p-button\",[],[[\"@type\",\"@onClick\"],[\"outline\",[22,\"onClose\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"inviteUsersModal.successInfoStep.buttons.close\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/invite-users-modal/success-info/template.hbs"
    }
  });

  _exports.default = _default;
});