define("da-vinci/luca/data-write/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{page-title (t "luca.pageTitles.dataWrite")}}
  <form method="post" class="lucaForm">
    <input type="hidden" name="s" aria-hidden="true">
    <input type="hidden" name="t" aria-hidden="true">
  </form>
  {{#if showErrorModal}}
    <ConverterErrorsModal
      @errors={{errors}}
      @onClose={{action "onClose"}}
      @data-test-converter-errors-modal={{true}}
    />
  
  {{else}}
    <div
      id="loading-animation"
      style="position:fixed; top:0; left:0; width:100%; height:100%;background-color:#dedddc;"
    >
      <img
        src="/assets/atlas-logo-animation-ala01.gif"
        alt={{t "lucaDataWrite.loading"}}
        style="margin:auto; display:block; width:80px; height:80px; position:relative; top:42.5%;"
      >
      <h1
        style="margin:auto;display:block;width: 100%;height:80px;position:relative;top:42.5%;text-align: center;"
      >
        {{t "lucaDataWrite.pleaseWait"}}
      </h1>
    </div>
  {{/if}}
  
  */
  {
    "id": "d3WHY0uh",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"luca.pageTitles.dataWrite\"],null]],null],false],[0,\"\\n\"],[7,\"form\",true],[10,\"method\",\"post\"],[10,\"class\",\"lucaForm\"],[8],[0,\"\\n  \"],[7,\"input\",true],[10,\"name\",\"s\"],[10,\"aria-hidden\",\"true\"],[10,\"type\",\"hidden\"],[8],[9],[0,\"\\n  \"],[7,\"input\",true],[10,\"name\",\"t\"],[10,\"aria-hidden\",\"true\"],[10,\"type\",\"hidden\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showErrorModal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"converter-errors-modal\",[],[[\"@errors\",\"@onClose\"],[[22,\"errors\"],[28,\"action\",[[23,0,[]],\"onClose\"],null]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"id\",\"loading-animation\"],[10,\"style\",\"position:fixed; top:0; left:0; width:100%; height:100%;background-color:#dedddc;\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/assets/atlas-logo-animation-ala01.gif\"],[11,\"alt\",[28,\"t\",[\"lucaDataWrite.loading\"],null]],[10,\"style\",\"margin:auto; display:block; width:80px; height:80px; position:relative; top:42.5%;\"],[8],[9],[0,\"\\n    \"],[7,\"h1\",true],[10,\"style\",\"margin:auto;display:block;width: 100%;height:80px;position:relative;top:42.5%;text-align: center;\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"lucaDataWrite.pleaseWait\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/luca/data-write/template.hbs"
    }
  });

  _exports.default = _default;
});