define("da-vinci/components/bank-account/transaction/tag-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if bankTransaction.isInitialAccountBalance }}
    <span class="initial-account-balance" data-test-is-initial-account-balance-tag>
      {{t "bankTransactionTagList.isInitialAccountBalance" htmlSafe=true}}
    </span>
  {{/if}}
  
  */
  {
    "id": "xCsEohCM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"bankTransaction\",\"isInitialAccountBalance\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"initial-account-balance\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"bankTransactionTagList.isInitialAccountBalance\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/bank-account/transaction/tag-list/template.hbs"
    }
  });

  _exports.default = _default;
});