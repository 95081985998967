define("da-vinci/components/process-status-tab-selector/bank-transaction/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="nav-tab">
    <ul>
      <li class={{tabClasses.waiting}} data-test-waiting-tab>
        <LinkTo
          @route="tax-period.show.bank-account.transaction.waiting"
          data-test-waiting-button={{true}}
        >
          {{t
            "processStatusTabSelector.tabs.waiting"
            count=waitingCount
            htmlSafe=true
          }}
        </LinkTo>
      </li>
      <li class={{tabClasses.ready}} data-test-ready-tab>
        <LinkTo
          @route="tax-period.show.bank-account.transaction.ready"
          data-test-ready-button={{true}}
        >
          {{t
            "processStatusTabSelector.tabs.ready"
            count=readyCount
            htmlSafe=true
          }}
        </LinkTo>
      </li>
      <li class={{tabClasses.done}} data-test-done-tab>
        <LinkTo
          @route="tax-period.show.bank-account.transaction.receipt-export-group.index"
          data-test-done-button={{true}}
        >
          {{t "processStatusTabSelector.tabs.done" count=doneCount htmlSafe=true}}
        </LinkTo>
      </li>
  
      {{#if tabClasses.yield}}
        <li class="yielded {{tabClasses.yield}}" data-test-yielded-tab>
          <i class="fa fa-angle-right fa-15x"></i>
          {{yield}}
        </li>
      {{/if}}
    </ul>
  </div>
  
  */
  {
    "id": "apf/4pMC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"nav-tab\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[11,\"class\",[24,[\"tabClasses\",\"waiting\"]]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"tax-period.show.bank-account.transaction.waiting\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"processStatusTabSelector.tabs.waiting\"],[[\"count\",\"htmlSafe\"],[[24,[\"waitingCount\"]],true]]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[11,\"class\",[24,[\"tabClasses\",\"ready\"]]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"tax-period.show.bank-account.transaction.ready\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"processStatusTabSelector.tabs.ready\"],[[\"count\",\"htmlSafe\"],[[24,[\"readyCount\"]],true]]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[11,\"class\",[24,[\"tabClasses\",\"done\"]]],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"tax-period.show.bank-account.transaction.receipt-export-group.index\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"processStatusTabSelector.tabs.done\"],[[\"count\",\"htmlSafe\"],[[24,[\"doneCount\"]],true]]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"tabClasses\",\"yield\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[11,\"class\",[29,[\"yielded \",[24,[\"tabClasses\",\"yield\"]]]]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-angle-right fa-15x\"],[8],[9],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/process-status-tab-selector/bank-transaction/template.hbs"
    }
  });

  _exports.default = _default;
});