define("da-vinci/components/tax-period/bank-accounts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <PCard @class="tax-period" as |card|>
    <PCardHeader @class="card-header" @data-test-bank-accounts-header={{true}}>
      <a href="#" {{action "toggleCollapse"}}>
        <h2>{{t "bankAccounts.bankTransactions"}}</h2>
        <i class={{collapsibleIconClass}}></i>
      </a>
    </PCardHeader>
  
    {{#unless isCollapsed}}
      <card.body @class="card-body-wrapper">
        <PTable @items={{bankAccounts}} @columnWidths={{columnWidths}} as |table|>
          {{#if bankAccounts.length}}
            <table.body as |item|>
              <TaxPeriod::BankAccounts::Row
                @taxPeriod={{taxPeriod}}
                @bankAccount={{item}}
              />
            </table.body>
          {{else}}
            <div class="no-invoice" data-test-empty-info>
              <p>{{t "bankAccounts.emptyInfo"}}</p>
            </div>
          {{/if}}
        </PTable>
      </card.body>
    {{/unless}}
  </PCard>
  
  */
  {
    "id": "dEIv5tT6",
    "block": "{\"symbols\":[\"card\",\"table\",\"item\"],\"statements\":[[5,\"p-card\",[],[[\"@class\"],[\"tax-period\"]],{\"statements\":[[0,\"\\n  \"],[5,\"p-card-header\",[],[[\"@class\"],[\"card-header\"]],{\"statements\":[[0,\"\\n    \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"toggleCollapse\"]],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"bankAccounts.bankTransactions\"],null],false],[9],[0,\"\\n      \"],[7,\"i\",true],[11,\"class\",[22,\"collapsibleIconClass\"]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"isCollapsed\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[\"body\"]],[],[[\"@class\"],[\"card-body-wrapper\"]],{\"statements\":[[0,\"\\n      \"],[5,\"p-table\",[],[[\"@items\",\"@columnWidths\"],[[22,\"bankAccounts\"],[22,\"columnWidths\"]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"bankAccounts\",\"length\"]]],null,{\"statements\":[[0,\"          \"],[6,[23,2,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n            \"],[5,\"tax-period/bank-accounts/row\",[],[[\"@taxPeriod\",\"@bankAccount\"],[[22,\"taxPeriod\"],[23,3,[]]]]],[0,\"\\n          \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"no-invoice\"],[8],[0,\"\\n            \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"bankAccounts.emptyInfo\"],null],false],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/tax-period/bank-accounts/template.hbs"
    }
  });

  _exports.default = _default;
});