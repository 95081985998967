define("da-vinci/is-asist/helper", ["exports", "ui-library/helpers/is-asist"], function (_exports, _isAsist) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isAsist.default;
    }
  });
  Object.defineProperty(_exports, "isAsist", {
    enumerable: true,
    get: function get() {
      return _isAsist.isAsist;
    }
  });
});