define("da-vinci/user-invitations/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{title (t "userInvitations.pageTitle")}}
  
  <AccountantOfficeHeader @breadcrumb={{component "p-breadcrumb"}}>
    <AccountantOfficeTabSelector @tab="userInvitation" />
  </AccountantOfficeHeader>
  
  <UserInvitations::UserInvitationsTable @userInvitations={{userInvitations}} />
  
  */
  {
    "id": "+t9ZVXCJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"userInvitations.pageTitle\"],null]],[[\"_deprecate\"],[null]]],false],[0,\"\\n\\n\"],[5,\"accountant-office-header\",[],[[\"@breadcrumb\"],[[28,\"component\",[\"p-breadcrumb\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"accountant-office-tab-selector\",[],[[\"@tab\"],[\"userInvitation\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"user-invitations/user-invitations-table\",[],[[\"@userInvitations\"],[[22,\"userInvitations\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/user-invitations/index/template.hbs"
    }
  });

  _exports.default = _default;
});