define("da-vinci/utils/term-id-not-found-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = TermIdNotFoundError;

  function TermIdNotFoundError(lucaCompanyId, currentTaxPeriodId) {
    var message = "Term ID not found for lucaCompanyId ".concat(lucaCompanyId, " - taxPeriodId ").concat(currentTaxPeriodId);
    return new Error(message);
  }
});