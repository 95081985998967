define("da-vinci/locales/tr/translations", ["exports", "da-vinci/locales/tr/ui-library-translations"], function (_exports, _uiLibraryTranslations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //
  //
  //
  // DO NOT ADD ANYTHING TO THIS FILE
  // INSTEAD USE ui-library-translations.js WHICH CAN BE IMPORTED INTO THE CONTAINING APP
  //
  //
  //
  //
  var _default = _uiLibraryTranslations.default;
  _exports.default = _default;
});