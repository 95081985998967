define("da-vinci/components/table-empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="mid-position" data-test-table-empty-state>
    <div class="empty-state-message">
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "q/GdNdHm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"mid-position\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"empty-state-message\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/table-empty-state/template.hbs"
    }
  });

  _exports.default = _default;
});