define("da-vinci/sign-up/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <SignUpModal @email={{email}} @key={{key}} />
  
  
  */
  {
    "id": "8WoCumWi",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sign-up-modal\",[],[[\"@email\",\"@key\"],[[22,\"email\"],[22,\"key\"]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/sign-up/template.hbs"
    }
  });

  _exports.default = _default;
});