define("da-vinci/components/error-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#unless isInline}}
    <i class="fa fa-3x fa-exclamation-circle" data-test-left-icon></i>
  {{/unless}}
  
  {{#unless isInline}}
    <h1 data-test-title>
      {{t "errorList.title"}}
    </h1>
    <p data-test-description>
      <small>
        {{t "errorList.description"}}
      </small>
    </p>
  {{/unless}}
  
  <ul>
    {{#each errorMessages as |error|}}
      <li data-test-message>
        {{#if isInline}}
          <i class="fa fa-exclamation-circle"></i>
        {{else}}
          <i class="fa fa-check-circle-o"></i>
        {{/if}}
  
        {{error}}
      </li>
    {{/each}}
  </ul>
  
  {{#unless isInline}}
    <PButton
      @onClick={{action "resetErrors"}}
      @shape="square"
      @type="outline"
      @data-test-reset-button={{true}}
    >
      <i class="fa fa-times"></i>
    </PButton>
  {{/unless}}
  
  */
  {
    "id": "CMSSAA+D",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"unless\",[[24,[\"isInline\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"fa fa-3x fa-exclamation-circle\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[24,[\"isInline\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h1\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"errorList.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[7,\"small\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"errorList.description\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"errorMessages\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isInline\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"fa fa-exclamation-circle\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"fa fa-check-circle-o\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"isInline\"]]],null,{\"statements\":[[0,\"  \"],[5,\"p-button\",[],[[\"@onClick\",\"@shape\",\"@type\"],[[28,\"action\",[[23,0,[]],\"resetErrors\"],null],\"square\",\"outline\"]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da-vinci/components/error-list/template.hbs"
    }
  });

  _exports.default = _default;
});