define("da-vinci/components/staff-members/list-row/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-prop-types", "ember-decorators/object", "ember-keyboard", "ember-decorators/object/evented"], function (_exports, _applyDecoratedDescriptor2, _emberPropTypes, _object, _emberKeyboard, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  var NUMBER_OF_NONTRUNCATED_NAMES = 2;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, (_dec = (0, _object.computed)('staffMember.parasutCompanies'), _dec2 = (0, _object.computed)('staffMember'), _dec3 = (0, _evented.on)((0, _emberKeyboard.keyDown)('Enter')), (_obj = {
    router: Ember.inject.service(),
    propTypes: {
      staffMember: _emberPropTypes.PropTypes.any.isRequired
    },
    keyboardActivated: true,
    classNames: ['staff-members-list-row'],
    tagName: 'tr',
    attributeBindings: ['role', 'tabindex'],
    role: 'button',
    tabindex: 0,
    showStaffInfoModal: false,
    showStaffInfoEditModal: false,
    truncatedCompanyNames: function truncatedCompanyNames(parasutCompanies) {
      var companyNames = parasutCompanies.slice(0, NUMBER_OF_NONTRUNCATED_NAMES).map(function (company) {
        return company.name;
      });

      if (parasutCompanies.length > NUMBER_OF_NONTRUNCATED_NAMES) {
        companyNames.push("".concat(parasutCompanies.length - NUMBER_OF_NONTRUNCATED_NAMES, " di\u011Fer"));
      }

      return companyNames.join(', ');
    },
    userTypeTranslationKey: function userTypeTranslationKey(staffMember) {
      var isOwner = staffMember.get('accountantOffice.owner.id') === staffMember.id;
      var userTypeKey = staffMember.userType || 'cannotFind';

      if (isOwner) {
        userTypeKey = 'owner';
      }

      return "staffMembers.listRow.".concat(userTypeKey);
    },
    click: function click() {
      this.router.transitionTo('staff-member.show', this.staffMember);
    },
    simulateClick: function simulateClick() {
      // TODO: Add space to keys, after ember-keyboard upgrade
      var isComponentFocused = document.activeElement === this.element;

      if (isComponentFocused) {
        this.set('showStaffInfoModal', true);
      }
    },
    closeModal: function closeModal() {
      this.staffMember.rollbackAttributes();
      this.set('showStaffInfoEditModal', false);
    },
    openStaffInfoEditModal: function openStaffInfoEditModal() {
      this.set('showStaffInfoModal', false);
      this.set('showStaffInfoEditModal', true);
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_obj, "truncatedCompanyNames", [_dec], Object.getOwnPropertyDescriptor(_obj, "truncatedCompanyNames"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "userTypeTranslationKey", [_dec2], Object.getOwnPropertyDescriptor(_obj, "userTypeTranslationKey"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "simulateClick", [_dec3], Object.getOwnPropertyDescriptor(_obj, "simulateClick"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "closeModal"), _obj), (0, _applyDecoratedDescriptor2.default)(_obj, "openStaffInfoEditModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openStaffInfoEditModal"), _obj)), _obj)));

  _exports.default = _default;
});